import { HBox, VBox } from "../../styles/Flexbox.styled";
import { IoAdd, IoBook, IoShareSocial } from "react-icons/io5";
import {
  removeFather,
  removeMother,
  setFather,
  setMother,
} from "../../services/PigeonService";
import { useNavigate, useSearchParams } from "react-router-dom";

import AddPigeonAchievement from "../../components/modals/AddPigeonAchievement/AddPigeonAchievement";
import { ButtonOpacity } from "../../styles/ButtonOpacity.styled";
import ChooseChild from "../../components/modals/ChoosePigeon/ChooseChild";
import ChoosePigeon from "../../components/modals/ChoosePigeon/ChoosePigeon";
import Confirm from "../../components/modals/Confirm/Confirm";
import ConfirmModalTypeEnum from "../../models/enums/ConfirmModalTypeEnum";
import ConfirmRemoveDependencyParent from "../../components/ConfirmRemoveDependencyParent";
import ListItem from "../../components/ListItem/ListItem";
import { ListItemAction } from "../../components/ListItem/IListItem";
import Pigeon from "../../models/Pigeon";
import PigeonAchievement from "../../models/PigeonAchievement";
import { PigeonSexEnum } from "../../models/enums/PigeonSexEnum";
import Text from "../../components/Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { getText } from "../../locales/initI18n";
import { setError } from "../../redux/modules/error";
import { useAppDispatch } from "../../redux/hooks";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTheme } from "styled-components";

interface ChildrenProps {
  pigeon: Pigeon;
}

const Children = ({ pigeon }: ChildrenProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [isAddForm, setIsAddForm] = useState(false);
  const [isRemoveConfirm, setIsRemoveConfirm] = useState(false);
  const [child, setChild] = useState<Pigeon | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const getPigeonData = (data?: string | number) => {
    return data?.toString() || "-";
  };

  const children = [...pigeon.fatherOf, ...pigeon.motherOf];

  const addParentToChild = async (child: Pigeon) => {
    if (pigeon.sex === PigeonSexEnum.Male) {
      await setFather(child, pigeon);
      pigeon.fatherOf.push(child);
    } else {
      await setMother(child, pigeon);
      pigeon.motherOf.push(child);
    }
    setIsAddForm(false);
  };

  const navigateToPigeon = (parent?: Pigeon) => {
    if (!parent) return;
    searchParams.set("tab", "1");
    const breadcrumbs = searchParams.get("breadcrumbs");
    if (breadcrumbs) {
      const parsedBreadcrumbs = JSON.parse(breadcrumbs) as [number, string][];
      parsedBreadcrumbs.push([pigeon.id, pigeon.ring]);
      searchParams.set("breadcrumbs", JSON.stringify(parsedBreadcrumbs));
    } else {
      searchParams.set("breadcrumbs", JSON.stringify([pigeon.id, pigeon.ring]));
    }
    navigate(`/dashboard/pigeons/${parent.id}?${searchParams.toString()}`);
  };

  const removeParent = async () => {
    if (!child) return;
    if (pigeon.sex === PigeonSexEnum.Male) {
      await removeFather(child);
      pigeon.fatherOf = pigeon.fatherOf.filter((f) => f.id !== child.id);
    } else {
      await removeMother(child);
      pigeon.motherOf = pigeon.motherOf.filter((m) => m.id !== child.id);
    }

    setIsRemoveConfirm(false);
  };

  return (
    <VBox>
      <ChooseChild
        pigeon={pigeon}
        isVisible={isAddForm}
        onClose={() => setIsAddForm(false)}
        onConfirm={addParentToChild}
      />
      {child && (
        <ConfirmRemoveDependencyParent
          pigeon={child}
          parent={pigeon}
          onClose={() => setIsRemoveConfirm(false)}
          onConfirm={removeParent}
          isVisible={isRemoveConfirm}
        />
      )}
      <ListItem
        overflowX="hidden"
        overflowY="hidden"
        borderRadius="10px"
        title={getText("Children.view.action.add.title")}
        description={getText("Children.view.action.add.description")}
        rIcon={<IoAdd color={theme.palette.White} />}
        action={ListItemAction.Success}
        onClick={() => {
          setIsAddForm(true);
        }}
        margin="0 0 10px 0"
      />
      {children.map((child) => (
        <VBox
          key={child.id}
          overflowX="hidden"
          overflowY="hidden"
          backgroundColor={theme.palette.White}
          BorderRadius="10px"
          alignItems="center"
          Padding="0 20px 20px 20px"
          Margin="0 0 10px 0"
        >
          <ListItem
            padding="10px 0"
            title={getPigeonData(child?.ring)}
            description={`${getPigeonData(child?.name)}`}
            action={ListItemAction.Transparent}
            onClick={() => {}}
          />
          <VBox
            width="100%"
            height="2px"
            backgroundColor={theme.palette.Grey10}
            Margin="0 0 10px 0"
          />
          <VBox alignItems="flex-start" width="100%">
            <Text
              type={TextTypeEnum.body}
              color={theme.palette.Grey50}
              weight={TextWeightEnum.regular}
              size={TextSizeEnum.small}
            >
              {getText("General.Color")}: {child.ReadableColor}
            </Text>
            <Text
              type={TextTypeEnum.body}
              color={theme.palette.Grey50}
              weight={TextWeightEnum.regular}
              size={TextSizeEnum.small}
            >
              {getText("General.Breed")}: {child.breed || "-"}
            </Text>
            <Text
              type={TextTypeEnum.body}
              color={theme.palette.Grey50}
              weight={TextWeightEnum.regular}
              size={TextSizeEnum.small}
            >
              {getText("General.State")}: {child.ReadableState}
            </Text>
          </VBox>
          <VBox
            width="100%"
            height="2px"
            backgroundColor={theme.palette.Grey10}
            Margin="10px 0"
          />
          <HBox gap="10px" width="100%">
            <ButtonOpacity
              onClick={() => {
                navigateToPigeon(child);
              }}
            >
              <HBox
                height="100%"
                backgroundColor={theme.palette.Grey10}
                Padding="15px"
                BorderRadius="10px"
                alignItems="center"
                justifyContent="center"
                gap="10px"
              >
                <IoBook color={theme.palette.Grey70} size="20px" />
                <Text
                  Margin="5px 0 0 0"
                  type={TextTypeEnum.body}
                  color={theme.palette.Grey70}
                  weight={TextWeightEnum.medium}
                  size={TextSizeEnum.small}
                >
                  {getText("Children.view.action.add.show")}
                </Text>
              </HBox>
            </ButtonOpacity>
            <ButtonOpacity
              onClick={() => {
                setChild(child);
                setIsRemoveConfirm(true);
              }}
            >
              <HBox
                backgroundColor={theme.palette.Red10}
                Padding="15px"
                BorderRadius="10px"
                alignItems="center"
                justifyContent="center"
                gap="10px"
              >
                <IoShareSocial color={theme.palette.Red100} size="20px" />
                <Text
                  Margin="5px 0 0 0"
                  type={TextTypeEnum.body}
                  color={theme.palette.Red100}
                  weight={TextWeightEnum.medium}
                  size={TextSizeEnum.small}
                >
                  {getText("Children.view.action.add.Remove")}
                </Text>
              </HBox>
            </ButtonOpacity>
          </HBox>
        </VBox>
      ))}
    </VBox>
  );
};

export default Children;
