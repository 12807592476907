import { FemaleIcon, MaleIcon, UnknownIcon } from "./SexIcons";

import { PigeonSexEnum } from "../../../models/enums/PigeonSexEnum";
import SelectOption from "../../../models/SelectOption";

const SexOptions = [
  new SelectOption({
    key: PigeonSexEnum.Male,
    title: "Płeć",
    description: "Samiec",
    icon: MaleIcon,
  }),
  new SelectOption({
    key: PigeonSexEnum.Female,
    title: "Płeć",
    description: "Samica",
    icon: FemaleIcon,
  }),
  new SelectOption({
    key: PigeonSexEnum.Unknown,
    title: "Płeć",
    description: "Nieznana",
    icon: UnknownIcon,
  }),
];

export const getInitialSex = (current?: PigeonSexEnum) => {
  const option = SexOptions.find((option) => option.key === current);
  if (option) return option;
  return SexOptions[2];
};

export default SexOptions;
