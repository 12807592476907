import { ChildFilterType } from "./ChildFilterType";
import { ChildFilterValue } from "./ChildFilterValue";
import { FilterName } from "../enums/FilterName";
import { FilterRule } from "../enums/FilterRule";

interface DefinedFilterProps {
  displayName: string;
  name: FilterName;
  rule: FilterRule;
  type: ChildFilterType;
  queryName?: FilterName | string;
  placeholder?: string;
  transformCallback?: (value: ChildFilterValue) => ChildFilterValue;
}

export default class DefinedFilter {
  private _displayName: string;
  private _name: FilterName;
  private _queryName: FilterName | string;
  private _placeholder: string;
  private _type: ChildFilterType;
  private _rule: FilterRule;
  private _transformCallback = (value: ChildFilterValue) => value;

  public get displayName(): string {
    return this._displayName;
  }

  public get name(): FilterName {
    return this._name;
  }

  public get queryName(): FilterName | string {
    return this._queryName;
  }

  public get placeholder(): string {
    return this._placeholder;
  }

  public get type(): ChildFilterType {
    return this._type;
  }

  public get rule(): FilterRule {
    return this._rule;
  }

  public transform(value: ChildFilterValue): ChildFilterValue {
    return this._transformCallback(value);
  }

  constructor(props: DefinedFilterProps) {
    this._displayName = props.displayName;
    this._name = props.name;
    this._queryName = props.queryName || props.name;
    if (props.transformCallback)
      this._transformCallback = props.transformCallback;
    this._placeholder = props.placeholder || "";
    this._type = props.type;
    this._rule = props.rule;
  }
}
