import { Button, ButtonType } from "../../../styles/Button.styled";

import { FlightMarkerProps } from "./IFlightMarkerForm";
import HalfModal from "../../HalfModal/HalfModal";
import TextInput from "../../TextInput";
import { getText } from "../../../locales/initI18n";
import { useState } from "react";

const FlightMarkerForm = ({
  isVisible,
  onClose,
  onConfirm,
  remarks: loadedRemarks,
}: FlightMarkerProps) => {
  const [remarks, setRemarks] = useState(loadedRemarks);
  if (!isVisible) return null;

  return (
    <HalfModal heightDivider={3.0} visible onClose={onClose}>
      <TextInput
        value={remarks}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setRemarks(e.target.value)
        }
        placeholder={getText("FlightMarkerForm.LocationDescription")}
      >
        {getText("Remarks")}
      </TextInput>
      <Button
        margin="10px 0 0 0"
        buttonType={ButtonType.Primary}
        onClick={() => onConfirm({ remarks })}
      >
        {getText("AddMarkerInfo")}
      </Button>
    </HalfModal>
  );
};

export default FlightMarkerForm;
