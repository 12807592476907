import BarOptions, { BarType } from "./IBar";
import {
  CriticalBarStyled,
  GreenBarStyled,
  InfoBarStyled,
  WarnBarStyled,
} from "../../styles/Bar.styled";

import Text from "../Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { VBox } from "../../styles/Flexbox.styled";
import { getText } from "../../locales/initI18n";
import { useTheme } from "styled-components";

const Bar = (props: BarOptions) => {
  const theme = useTheme();

  const getMessage = () => {
    if (typeof props.children === "string") {
      return (
        <Text
          type={TextTypeEnum.body}
          weight={TextWeightEnum.regular}
          size={TextSizeEnum.medium}
          color={theme.palette.Grey70}
        >
          {props.children}
        </Text>
      );
    }
    return props.children;
  };

  switch (props.type) {
    case BarType.Info: {
      return (
        <InfoBarStyled visible={props.visible}>
          <VBox>
            <Text
              type={TextTypeEnum.body}
              size={TextSizeEnum.medium}
              weight={TextWeightEnum.bold}
              color={theme.palette.Primary100}
            >
              {props.title || getText("Bar_Information")}
            </Text>
            {getMessage()}
          </VBox>
        </InfoBarStyled>
      );
    }
    case BarType.Warn: {
      return (
        <WarnBarStyled visible={props.visible}>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.bold}
            color={theme.palette.Yellow100}
          >
            {props.title || getText("Bar_Warning")}
          </Text>
          {getMessage()}
        </WarnBarStyled>
      );
    }
    case BarType.Green: {
      return (
        <GreenBarStyled visible={props.visible}>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.bold}
            color={theme.palette.Green100}
          >
            {props.title || getText("Bar_Success")}
          </Text>
          {getMessage()}
        </GreenBarStyled>
      );
    }
    case BarType.Critical: {
      return (
        <CriticalBarStyled visible={props.visible}>
          <Text
            type={TextTypeEnum.body}
            weight={TextWeightEnum.bold}
            size={TextSizeEnum.small}
            color={theme.palette.Red100}
          >
            {props.title || getText("Bar_Error")}
          </Text>
          {getMessage()}
        </CriticalBarStyled>
      );
    }
  }
};

export default Bar;
