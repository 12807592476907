import { HBox, VBox } from "../../styles/Flexbox.styled";

import { Button } from "../../styles/Button.styled";
import { ButtonOpacity } from "../../styles/ButtonOpacity.styled";
import HighligtedText from "../HighlightedText/HighlightedText";
import Text from "../Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { getText } from "../../locales/initI18n";
import { useState } from "react";
import { useTheme } from "styled-components";

const SecondNews = () => {
  const theme = useTheme();
  const [showRespiroSpiritus, setShowRespiroSpiritus] = useState(false);
  const [showWingforce, setShowWingforce] = useState(false);
  const [showTaubenzuch, setShowTaubenzuch] = useState(false);
  const goTo = (url: string) => {
    window.open(url, "_blank");
  };
  return (
    <VBox width="100%">
      <HBox justifyContent="flex-end">
        <HighligtedText
          text={getText("SecondNews.PublicationDate")}
          backgroundColor={theme.palette.Yellow100}
        />
      </HBox>
      <VBox gap="20px">
        <VBox>
          <Text
            type={TextTypeEnum.h6}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.medium}
            color={theme.palette.Grey90}
          >
            {getText("SecondNews.GeneratePDF")}
          </Text>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
            Margin="0 0 10px 0"
            textAlign="justify"
          >
            {getText("SecondNews.GeneratePDFDescription")}
          </Text>
          <VBox
            justifyContent="center"
            alignItems="center"
            Padding="5px"
            BorderRadius="5px"
            backgroundColor={theme.palette.Grey10}
          >
            <img
              width="90%"
              src="/assets/News/GenerateDocument.png"
              alt="Generate Document"
            />
            <Text
              textAlign="center"
              type={TextTypeEnum.caption}
              size={TextSizeEnum.small}
              weight={TextWeightEnum.regular}
              color={theme.palette.Grey50}
            >
              {getText("SecondNews.GeneratePDFImage")}: <br /> 's-Hertogenbosch,
              NL | 2024-03-17
            </Text>
          </VBox>
        </VBox>
        <VBox>
          <Text
            type={TextTypeEnum.h6}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.medium}
            color={theme.palette.Grey90}
          >
            {getText("SecondNews.YoutubeVideoGraphicsChanges")}
          </Text>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
            Margin="0 0 10px 0"
            textAlign="justify"
          >
            {getText("SecondNews.YoutubeVideoGraphicsChangesDescription")}
          </Text>
          <HBox justifyContent="center">
            <iframe
              width="560"
              height="315"
              style={{ zoom: 0.5 }}
              src="https://www.youtube.com/embed/sqgdhu8ZUrk?si=bof-DzVWCoSrGLA9"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen={true}
            ></iframe>
          </HBox>
        </VBox>
        <VBox>
          <Text
            type={TextTypeEnum.h6}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.medium}
            color={theme.palette.Grey90}
          >
            {getText("SecondNews.NewsInFarm")}
          </Text>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
          >
            {getText("SecondNews.NewsInFarmFirstDescription")}{" "}
            <Text weight={TextWeightEnum.bold}>Digging Barbara Skrzypnik</Text>.
            {getText("SecondNews.NewsInFarmSecondDescription")}{" "}
            <a href="https://digging.pl/" target="_blank" rel="noreferrer">
              https://digging.pl/
            </a>
          </Text>
          <ol>
            <li style={{ marginBottom: "10px" }}>
              <Text
                color={theme.palette.Grey70}
                type={TextTypeEnum.body}
                size={TextSizeEnum.large}
              >
                Respiro Spiritus
              </Text>
              <Text
                type={TextTypeEnum.body}
                size={TextSizeEnum.medium}
                weight={TextWeightEnum.regular}
                color={theme.palette.Grey70}
                textAlign="justify"
              >
                <Text visible={!showRespiroSpiritus}>
                  {getText("SecondNews.RespiroSiritusShortDescription")}
                </Text>
                <Text visible={showRespiroSpiritus}>
                  {getText("SecondNews.RespiroSiritusLongDescription")}
                </Text>
                <VBox
                  display="inline-block"
                  onClick={() => {
                    setShowRespiroSpiritus(!showRespiroSpiritus);
                  }}
                  backgroundColor={theme.palette.Grey30}
                  BorderRadius="20px"
                  Padding="0 8px"
                >
                  <Text
                    type={TextTypeEnum.body}
                    size={TextSizeEnum.small}
                    weight={TextWeightEnum.regular}
                    color={theme.palette.White}
                  >
                    {showRespiroSpiritus
                      ? getText("TextInput.ShowLess")
                      : getText("TextInput.ShowMore")}
                  </Text>
                </VBox>
              </Text>
              <img src="/assets/News/Respiro_Spiritus.png" width="100%" />
              <ButtonOpacity
                onClick={() =>
                  goTo("https://digging.pl/produkt/respiro-spiritus/")
                }
              >
                <VBox>
                  <HBox
                    justifyContent="center"
                    backgroundColor="#0f697e"
                    Padding="5px"
                    BorderRadius="10px"
                  >
                    <Text
                      type={TextTypeEnum.body}
                      size={TextSizeEnum.large}
                      weight={TextWeightEnum.medium}
                      color={theme.palette.White}
                    >
                      {getText("SecondNews.RespiroSiritusBuy")}
                    </Text>
                  </HBox>
                </VBox>
              </ButtonOpacity>
            </li>
            <li style={{ marginBottom: "10px" }}>
              <Text
                color={theme.palette.Grey70}
                type={TextTypeEnum.body}
                size={TextSizeEnum.large}
              >
                Wingforce
              </Text>
              <Text
                type={TextTypeEnum.body}
                size={TextSizeEnum.medium}
                weight={TextWeightEnum.regular}
                textAlign="justify"
                color={theme.palette.Grey70}
              >
                <Text visible={!showWingforce}>
                  {getText("SecondNews.WingforceShortDescription")}
                </Text>
                <Text visible={showWingforce}>
                  {getText("SecondNews.WingforceLongDescription")}
                </Text>
                <VBox
                  display="inline-block"
                  onClick={() => {
                    setShowWingforce(!showWingforce);
                  }}
                  backgroundColor={theme.palette.Grey30}
                  BorderRadius="20px"
                  Padding="0 8px"
                >
                  <Text
                    type={TextTypeEnum.body}
                    size={TextSizeEnum.small}
                    weight={TextWeightEnum.regular}
                    color={theme.palette.White}
                  >
                    {showWingforce
                      ? getText("TextInput.ShowLess")
                      : getText("TextInput.ShowMore")}
                  </Text>
                </VBox>
              </Text>
              <img src="/assets/News/Wingforce.png" width="100%" />
              <ButtonOpacity
                onClick={() => goTo("https://digging.pl/produkt/wingforce/")}
              >
                <VBox>
                  <HBox
                    justifyContent="center"
                    backgroundColor="#dedc00"
                    Padding="5px"
                    BorderRadius="10px"
                  >
                    <Text
                      type={TextTypeEnum.body}
                      size={TextSizeEnum.large}
                      weight={TextWeightEnum.medium}
                      color={theme.palette.White}
                    >
                      {getText("SecondNews.WingforceBuy")}
                    </Text>
                  </HBox>
                </VBox>
              </ButtonOpacity>
            </li>
            <li>
              <Text
                color={theme.palette.Grey70}
                type={TextTypeEnum.body}
                size={TextSizeEnum.large}
              >
                Taubenzuch
              </Text>
              <Text
                type={TextTypeEnum.body}
                size={TextSizeEnum.medium}
                weight={TextWeightEnum.regular}
                textAlign="justify"
                color={theme.palette.Grey70}
              >
                <Text visible={!showTaubenzuch}>
                  {getText("SecondNews.TaubenzuchShortDescription")}
                </Text>
                <Text visible={showTaubenzuch}>
                  {getText("SecondNews.TaubenzuchLongDescription")}
                </Text>
                <VBox
                  display="inline-block"
                  onClick={() => {
                    setShowTaubenzuch(!showTaubenzuch);
                  }}
                  backgroundColor={theme.palette.Grey30}
                  BorderRadius="20px"
                  Padding="0 8px"
                >
                  <Text
                    type={TextTypeEnum.body}
                    size={TextSizeEnum.small}
                    weight={TextWeightEnum.regular}
                    color={theme.palette.White}
                  >
                    {showTaubenzuch
                      ? getText("TextInput.ShowLess")
                      : getText("TextInput.ShowMore")}
                  </Text>
                </VBox>
              </Text>
              <img src="/assets/News/Taubenzuch.png" width="100%" />
              <ButtonOpacity
                onClick={() => goTo("https://digging.pl/produkt/taubenzuch/")}
              >
                <VBox>
                  <HBox
                    justifyContent="center"
                    backgroundColor="#c37bb2"
                    Padding="5px"
                    BorderRadius="10px"
                  >
                    <Text
                      type={TextTypeEnum.body}
                      size={TextSizeEnum.large}
                      weight={TextWeightEnum.medium}
                      color={theme.palette.White}
                    >
                      {getText("SecondNews.TaubenzuchBuy")}
                    </Text>
                  </HBox>
                </VBox>
              </ButtonOpacity>
            </li>
          </ol>
        </VBox>
      </VBox>
    </VBox>
  );
};

export default SecondNews;
