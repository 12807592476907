import {
  AddFlightConfirmProps,
  AddFlightConfirmType,
} from "../AddFlightForm/IAddFlightForm";
import { Button, ButtonType } from "../../../styles/Button.styled";
import { HBox, VBox } from "../../../styles/Flexbox.styled";
import { IoAdd, IoFilter, IoInformationCircleOutline } from "react-icons/io5";
import {
  addFlight,
  getEventsFlights,
  getUserFlights,
  joinToFlight,
  removeFlight,
  updateFlight,
} from "../../../services/FlightService";
import { memo, useEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import AddFlightForm from "../AddFlightForm/AddFlightForm";
import { AxiosResponse } from "axios";
import ChildFilter from "../../../models/Filter/ChildFilter";
import { CloseIconEnum } from "../../HalfModal/CloseIconEnum";
import Debounce from "../../../utils/Debounce";
import FilterButton from "../../FilterButton/FilterButton";
import { FilterName } from "../../../models/enums/FilterName";
import Filters from "../Filters/Filters";
import { Flight } from "../../../models/Flight";
import { FlightFilterEnum } from "../../../models/enums/FlightFilterEnum";
import FlightHighlightedInfo from "../../FlightHighlightedInfo";
import FlightListInfo from "../../FlightListInfo";
import { FlightListOptions } from "./IFlightList";
import { FlightType } from "../../../models/enums/FlightType";
import HalfModal from "../../HalfModal/HalfModal";
import HorizontalMenu from "../../HorizontalMenu/HorizontalMenu";
import HorizontalMenuItem from "../../HorizontalMenu/IHorizontalMenu";
import { IBaseFilter } from "../../../models/Filter/IBaseFilter";
import IChildFilter from "../../../models/Filter/IChildFilter";
import { IUser } from "../../../services/IUserService";
import ListItem from "../../ListItem/ListItem";
import ParentFilter from "../../../models/Filter/ParentFilter";
import TextInput from "../../TextInput";
import { formatDate } from "../../../utils/VariableHelper";
import { getText } from "../../../locales/initI18n";
import { setError } from "../../../redux/modules/error";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import useUser from "../../../hooks/useUser";

const FlightList: React.FC<FlightListOptions> = ({
  isVisible,
  onClose,
  onConfirm,
}) => {
  const theme = useTheme();
  const [isAddForm, setIsAddForm] = useState(false);
  const [isFiltersModal, setIsFiltersModal] = useState(false);
  const [pressedFlight, setPressedFlight] = useState<Flight | undefined>(
    undefined
  );
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<IChildFilter[]>([]);
  const [rawFilters, setRawFilters] = useState<string>("");
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { data: user } = useUser();
  const [tab, setTab] = useState<FlightFilterEnum>(FlightFilterEnum.Private);
  const [showInfo, setShowInfo] = useState(false);
  const flightQueryFn = () => {
    if (tab === FlightFilterEnum.Open) return getEventsFlights(rawFilters);
    return getUserFlights(rawFilters);
  };
  const {
    data: flights,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      tab === FlightFilterEnum.Open ? "flight/event" : "flight/private",
      { filters: rawFilters },
    ],
    queryFn: flightQueryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const onCloseModal = () => {
    onClose();
  };

  const confirmAddFlight = async (data: AddFlightConfirmProps) => {
    setIsAddForm(false);
    setPressedFlight(undefined);
    try {
      let createdFlight: Flight | null = null;
      if (data.type === AddFlightConfirmType.Join) {
        createdFlight = await joinToFlight(data.flight);
      } else if (data.type === AddFlightConfirmType.Create) {
        const newFLight = data.flight;
        newFLight.startDate =
          typeof newFLight.startDate === "string"
            ? newFLight.startDate
            : newFLight.startDate.toISOString();
        createdFlight = newFLight.id
          ? await updateFlight(newFLight)
          : await addFlight(newFLight);
      }
      queryClient.setQueryData(["flight", createdFlight?.id], createdFlight);
      refetch();
    } catch (e) {
      dispatch(setError(e));
    }
  };

  const deleteFlight = async (flight: Flight) => {
    try {
      closeAddFlightForm();
      await removeFlight(flight.id);
      queryClient.setQueryData(
        ["flights", { filters: "" }],
        (old: Flight[] | undefined) => {
          if (!old) return;
          if (
            flight.parentFlight &&
            flight.parentFlight.type === FlightType.Open
          ) {
            return old.map((f) => {
              if (f.id === flight.id) return flight.parentFlight as Flight;
              return f;
            });
          }
          const newData = old.filter((f) => f.id !== flight.id);
          return newData;
        }
      );
    } catch (e) {
      onClose();
    }
  };

  const closeAddFlightForm = () => {
    setPressedFlight(undefined);
    setIsAddForm(false);
  };

  const onFlightPress = (flight: Flight) => {
    setPressedFlight(flight);
    setIsAddForm(true);
  };

  const onShowFlight = (flight?: Flight) => {
    if (!flight) return;
    setIsAddForm(false);
    onConfirm(flight); // TODO: get coordinates to flight
  };
  const onSetFilters = (newFilters: IBaseFilter[]) => {
    setIsFiltersModal(false);
    if (search) {
      const searchFilter = new ChildFilter(FilterName.Name, search);
      newFilters.push(searchFilter);
    }
    const sharedFilter = newFilters.find(
      (f) => f.Name === FilterName.SharedFlights
    );
    if (ChildFilter.is(sharedFilter) && sharedFilter.Value) {
      sharedFilter.Value = user?.id;
    }
    if (tab === FlightFilterEnum.Open) {
      const districtFlights = new ChildFilter(
        FilterName.DistrictId,
        user?.district.id
      );
      newFilters.push(districtFlights);
    }
    const parent = new ParentFilter(newFilters);
    setRawFilters(parent.transform());
    setFilters(newFilters as IChildFilter[]);
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    debounce.current.exec(e.target.value);
  };

  const handleSearchChange = (value: string) => {
    if (!value) return setRawFilters("");

    const searchFilter = new ChildFilter(FilterName.Name, value);
    const parent = new ParentFilter([searchFilter, ...filters]);

    setRawFilters(parent.transform());
  };

  const changeTab = (tab: FlightFilterEnum) => {
    setTab(tab);
  };

  useEffect(() => {
    const districtFlights = new ChildFilter(
      FilterName.DistrictId,
      user?.district.id
    );
    setFilters([districtFlights]);
    setRawFilters(new ParentFilter([districtFlights]).transform());
  }, []);

  const debounce = useRef<Debounce>(new Debounce(handleSearchChange, 500));

  if (!isVisible) return null;
  return (
    <HalfModal
      visible
      heightDivider={1.1}
      isExpander={false}
      busy={isLoading}
      Padding="0 5px 0 5px"
      onClose={() => onCloseModal()}
      title={getText("Flights")}
      customButton={
        <HBox Padding="5px 5px 20px 5px">
          <Button
            height="30px"
            isCustom={true}
            buttonType={ButtonType.Primary}
            onClick={() => setShowInfo(true)}
          >
            <IoInformationCircleOutline size={20} />
          </Button>
        </HBox>
      }
    >
      <FlightListInfo
        isVisible={showInfo}
        onConfirm={() => setShowInfo(false)}
        onClose={() => setShowInfo(false)}
      />
      <AddFlightForm
        isVisible={isAddForm}
        onClose={closeAddFlightForm}
        onConfirm={confirmAddFlight}
        onDelete={deleteFlight}
        onShow={onShowFlight}
        closeIcon={CloseIconEnum.RETURN}
        flightId={pressedFlight?.id}
      />
      <Filters
        isVisible={isFiltersModal}
        onClose={() => setIsFiltersModal(false)}
        onConfirm={onSetFilters}
        title={getText("FlightList.SearchFlights")}
        filters={[
          new ChildFilter(FilterName.StartDate, false, filters),
          new ChildFilter(FilterName.EndDate, false, filters),
          new ChildFilter(FilterName.SharedFlights, false, filters),
        ]}
      />
      <HBox gap="5px" Padding="0 10px">
        <VBox width="100%" flexGrow="1">
          <TextInput
            onChange={onSearchChange}
            value={search}
            placeholder="Torun"
            width="100%"
          >
            {getText("FlightList.Search")}
          </TextInput>
        </VBox>
        <FilterButton
          onClick={() => setIsFiltersModal(true)}
          isActive={filters.some((f) => f.Value)}
        />
        <Button
          isCustom
          width="50px"
          buttonType={ButtonType.Primary}
          onClick={() => setIsAddForm(true)}
        >
          <IoAdd size="20px" color={theme.palette.White} />
        </Button>
      </HBox>
      <HorizontalMenu
        items={[
          new HorizontalMenuItem("Prywatne", FlightFilterEnum.Private),
          new HorizontalMenuItem("Konkursowe", FlightFilterEnum.Open),
        ]}
        onClick={(item) => changeTab(item.id as FlightFilterEnum)}
        active={tab}
      />
      <VBox>
        {flights &&
          flights.map((flight, index) => (
            <ListItem
              padding="20px"
              key={`Flight-${flight.id}-${index}`}
              darkest={index % 2 === 0}
              title={flight.name}
              description={flight.remarks}
              rDescription={formatDate(flight.createDate)}
              onClick={() => onFlightPress(flight)}
            >
              <FlightHighlightedInfo flight={flight} currentUser={user} />
            </ListItem>
          ))}
      </VBox>
    </HalfModal>
  );
};

function areEqual(prevProps: FlightListOptions, nextProps: FlightListOptions) {
  return prevProps.isVisible === nextProps.isVisible;
}

export default memo(FlightList, areEqual);
