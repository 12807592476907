import { IShadowType } from "../styles/IGlobalStyled";

export const addCalc = (
  values: string | undefined,
  scale: number,
  operator = "*"
) => {
  if (!values) return "";
  try {
    const numbers = values.split(" ");
    const preparedValues = numbers
      .map((number) => {
        if (number.includes("calc")) return number;
        return `calc(${number} ${operator} ${scale})`;
      })
      .join(" ");
    return preparedValues;
  } catch (error) {
    return "";
  }
};

export const createShadow = (type: IShadowType | undefined) => {
  if (!type) return "";
  switch (type) {
    case "small":
      return "0px 0px 8px rgba(17, 17, 26, 0.1)";
    case "medium":
      return "rgba(0, 0, 0, 0.1) 0px 0px 20px 6px";
    case "large":
      return "0px 8px 16px rgba(0, 0, 0, 0.4)";
    default:
      return "none";
  }
};
