import auth from "./modules/auth";
import { configureStore } from "@reduxjs/toolkit";
import error from "./modules/error";

const store = configureStore({
  reducer: {
    error,
    auth,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
