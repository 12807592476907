import Confirm from "../Confirm/Confirm";
import ConfirmModalTypeEnum from "../../../models/enums/ConfirmModalTypeEnum";
import { HBox } from "../../../styles/Flexbox.styled";
import { ModalProps } from "../IModalOptions";
import Text from "../../Text/Text";
import TextSizeEnum from "../../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../../models/enums/TextWeightEnum";
import { useTheme } from "styled-components";

const OtherBreederHelpModal = (props: ModalProps<undefined>) => {
  const theme = useTheme();
  return (
    <Confirm
      type={ConfirmModalTypeEnum.Change}
      onConfirm={() => props.onConfirm(undefined)}
      confirmText="Rozumiem"
      onClose={() => props.onConfirm(undefined)}
      title="Gołąb innego hodowcy?"
      isCancel={false}
      isVisible={props.isVisible}
    >
      <Text
        type={TextTypeEnum.body}
        weight={TextWeightEnum.regular}
        size={TextSizeEnum.medium}
      >
        Czy chcesz dodać gołębia, który nie należy do Ciebie?
        <br />
        <HBox
          BorderRadius="10px"
          backgroundColor={theme.palette.Grey5}
          Padding="5px 0"
          Margin="5px 0"
          justifyContent="center"
        >
          <img
            width="300px"
            src="/assets/PigeonOtherBreeder.png"
            alt="Pigeon from other breeder"
          />
        </HBox>
        Wystarczy podać imię i nazwisko hodowcy. Jeśli hodowca znajduje się w
        Twoich znajomych, możesz go wybrać z listy. Aby dodać użytkownika, z
        listy znajomych, kliknij przycisk, który prezentuje się tak jak poniżej.
        <br />
        <HBox
          BorderRadius="10px"
          backgroundColor={theme.palette.Grey5}
          Padding="5px 0"
          Margin="5px 0"
          justifyContent="center"
        >
          <img
            width="300px"
            src="/assets/PigeonFromFriends.png"
            alt="Add user as pigeon owner"
          />
        </HBox>
        Następnie pokaże się lista Twoich znajomych, z której możesz wybrać
        osobę, która jest właścicielem gołębia. Dzięki temu będziesz mógł zawsze
        zerknąć do danych danego użytkownika.
        <br />
        <HBox
          BorderRadius="10px"
          backgroundColor={theme.palette.Grey5}
          Padding="5px 0"
          Margin="5px 0"
          justifyContent="center"
        >
          <img
            width="200px"
            src="/assets/AddPigeonFriend.png"
            alt="Friends list"
          />
        </HBox>
        Jeśli użytkownik nie znajduje się na liście, a wiesz że posiada konto na
        Pigeon Map - zaproś go do znajomych.
        <br />
        Pamiętaj, że możesz wpisać imię i nazwisko hodowcy ręcznie, i wybrać
        użytkownika z listy znajomych.
      </Text>
    </Confirm>
  );
};

export default OtherBreederHelpModal;
