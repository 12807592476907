import { post } from "./BaseService";

export const resetPassword = async (token: string, password: string) => {
  try {
    await post(`auth/password/reset`, { token, newPassword: password });
    return true;
  } catch (e) {
    return false;
  }
};
