import { AddTagFormProps, OnConfirmModalProps } from "./IAddTagForm";
import { Button, ButtonType } from "../../../styles/Button.styled";
import { Field, FieldInstance, Form, FormInstance } from "houseform";
import { HBox, VBox } from "../../../styles/Flexbox.styled";
import { useEffect, useRef, useState } from "react";

import AddTagFormModal from "./AddTagFormModal";
import { AddTagFormModalEnum } from "./AddTagFormModalEnum";
import { AxiosResponse } from "axios";
import Checkbox from "../../Checkbox";
import CityList from "../../CityList/CityList";
import HalfModal from "../../HalfModal/HalfModal";
import { ITag } from "../../../models/Tag";
import { TagType } from "../../../models/enums/TagType";
import Text from "../../Text/Text";
import TextInput from "../../TextInput";
import { TextInputType } from "../../../styles/ITextInput";
import TextSizeEnum from "../../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../../models/enums/TextWeightEnum";
import { fixFloat } from "../../../utils/VariableHelper";
import { getText } from "../../../locales/initI18n";
import { setVisible } from "../../../services/TagService";
import useCotes from "../../../hooks/useCotes";
import { useQueryClient } from "@tanstack/react-query";
import { useTheme } from "styled-components";
import { validateRequired } from "../../../utils/ValidationHelper";

const AddTagForm: React.FC<AddTagFormProps> = ({
  isVisible,
  isCote,
  tag,
  title,
  isSelect,
  closeIcon,
  onClose,
  onConfirm,
  onDelete,
  onSelect,
}) => {
  const theme = useTheme();
  const form = useRef<FormInstance<ITag>>(null);
  const tagFormData = useRef<ITag>();
  const [modal, setModal] = useState<AddTagFormModalEnum>(
    AddTagFormModalEnum.None
  );
  const [isVisibleTag, setIsVisibleTag] = useState(false);
  const [country, setCountry] = useState<string>("");
  const [busy, setBusy] = useState(false);

  const parseData = (data: ITag): ITag => {
    return {
      ...data,
      latitude: parseFloat(fixFloat(data.latitude)),
      longitude: parseFloat(fixFloat(data.longitude)),
      type: TagType.Private,
      country: !!country ? country : data.country,
    };
  };

  const onSubmit = (data: ITag) => {
    tagFormData.current = tag ? { ...tag, ...data } : data;
    if (!tag) submitTag();
    else setModal(AddTagFormModalEnum.Change);
  };

  const submitTag = () => {
    if (!tagFormData.current) return;
    if (tagFormData.current.isCote && !tagFormData.current.id)
      tagFormData.current.isVisible = true;
    onConfirm(parseData(tagFormData.current));
  };

  const onConfirmDelete = () => {
    setModal(AddTagFormModalEnum.None);
    if (tag) onDelete(tag.id);
  };

  const onConfirmChange = () => {
    setModal(AddTagFormModalEnum.None);
    submitTag();
  };

  const onSetVisible = async () => {
    if (!tag) return;
    setIsVisibleTag(!isVisibleTag);
    tag.isVisible = !isVisibleTag;
  };

  const setCoordinates = (lat: number, lon: number) => {
    if (!form.current) return;

    const latitude = form.current.getFieldValue("latitude") as FieldInstance<
      string,
      ITag
    >;
    const longitude = form.current.getFieldValue("longitude") as FieldInstance<
      string,
      ITag
    >;

    latitude.setValue(lat.toString());
    longitude.setValue(lon.toString());
  };

  const onCurrentLocalization = () => {
    setBusy(true);
    navigator.geolocation.getCurrentPosition((position) => {
      setCoordinates(position.coords.latitude, position.coords.longitude);
      setBusy(false);
    });
  };

  const onConfirmModal = (props: OnConfirmModalProps) => {
    switch (props.modal) {
      case AddTagFormModalEnum.Delete:
        onConfirmDelete();
        break;
      case AddTagFormModalEnum.Change:
        onConfirmChange();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isVisible && tag) {
      setIsVisibleTag(tag.isVisible);
    }
  }, [isVisible, tag]);

  return (
    <HalfModal
      visible={isVisible}
      onClose={onClose}
      heightDivider={1.1}
      Padding="0 5px 0 5px"
      isExpander={false}
      closeIcon={closeIcon}
    >
      <AddTagFormModal
        modal={modal}
        isCote={isCote || false}
        onClose={() => setModal(AddTagFormModalEnum.None)}
        onConfirm={onConfirmModal}
      />
      <VBox Margin="40px 20px 0 20px">
        <Text type="h4" size="large" weight="bold">
          {!!tag ? tag.name : title}
        </Text>
      </VBox>
      <VBox Padding="20px">
        <Form onSubmit={onSubmit} ref={form}>
          {({ submit, value }) => (
            <>
              <VBox Margin="10px 0 10px 0">
                <Field<string>
                  name="name"
                  initialValue={tag?.name}
                  onSubmitValidate={validateRequired()}
                >
                  {({ value, setValue, onBlur, isValid, errors }) => (
                    <HBox width="100%" gap="10px">
                      <TextInput
                        isErrorState={!isValid}
                        value={value}
                        onBlur={onBlur}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setValue(e.target.value)
                        }
                        placeholder={getText("Tag.namePlaceholder")}
                        errors={errors}
                        disabled={!!tag && tag.type !== TagType.Private}
                      >
                        {getText("AddTagForm.inputNameTitle")}
                      </TextInput>
                    </HBox>
                  )}
                </Field>
              </VBox>
              <VBox Margin="0 0 10px 0">
                <Field<string> name="remarks" initialValue={tag?.remarks}>
                  {({ value, setValue, onBlur, isValid, errors }) => (
                    <HBox width="100%" gap="10px">
                      <TextInput
                        isErrorState={!isValid}
                        inputType={TextInputType.TextArea}
                        value={value}
                        onBlur={onBlur}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setValue(e.target.value)
                        }
                        placeholder={getText("Tag.remarksPlaceholder")}
                        errors={errors}
                        disabled={!!tag && tag.type !== TagType.Private}
                      >
                        {`${getText("AddTagForm.inputRemarksTitle")}*`}
                      </TextInput>
                    </HBox>
                  )}
                </Field>
              </VBox>
              <VBox Margin="0 0 10px 0" zIndex={1}>
                <Field<string>
                  name="city"
                  initialValue={tag?.city}
                  onSubmitValidate={validateRequired()}
                >
                  {({ value, setValue, errors }) => (
                    <CityList
                      city={value}
                      errors={errors}
                      onSelect={(region) => {
                        setValue(region.name);
                        setCountry(region.country);
                        if (region.lat !== Infinity) {
                          setCoordinates(region.lat, region.lon);
                        }
                      }}
                    />
                  )}
                </Field>
              </VBox>
              <HBox Margin="0 0 5px 0" justifyContent="flex-end">
                <Button
                  isCustom
                  height="30px"
                  padding="0 10px"
                  buttonType={ButtonType.Border}
                  onClick={onCurrentLocalization}
                >
                  <Text
                    color={theme.palette.Grey60}
                    type={TextTypeEnum.caption}
                    weight={TextWeightEnum.medium}
                    size={TextSizeEnum.large}
                  >
                    {busy
                      ? getText("AddTagForm.FetchingLocation")
                      : getText("AddTagForm.SelectMyLocation")}
                  </Text>
                </Button>
              </HBox>
              <VBox Margin="0 0 10px 0">
                <Field<string>
                  name="latitude"
                  initialValue={tag?.latitude?.toString()}
                  onSubmitValidate={validateRequired()}
                >
                  {({ value, setValue, onBlur, isValid, errors }) => (
                    <HBox width="100%" gap="10px">
                      <TextInput
                        isErrorState={!isValid}
                        value={value}
                        type="number"
                        onBlur={onBlur}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setValue(e.target.value)
                        }
                        placeholder={getText("latitudePlaceholder")}
                        errors={errors}
                        disabled={!!tag && tag.type !== TagType.Private}
                      >
                        {getText("Latitude")}
                      </TextInput>
                    </HBox>
                  )}
                </Field>
              </VBox>
              <VBox Margin="0 0 10px 0">
                <Field<string>
                  name="longitude"
                  initialValue={tag?.longitude?.toString()}
                  onSubmitValidate={validateRequired()}
                >
                  {({ value, setValue, onBlur, isValid, errors }) => (
                    <HBox width="100%" gap="10px">
                      <TextInput
                        isErrorState={!isValid}
                        value={value}
                        type="number"
                        onBlur={onBlur}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setValue(e.target.value)
                        }
                        placeholder={getText("longitudePlaceholder")}
                        errors={errors}
                        disabled={!!tag && tag.type !== TagType.Private}
                      >
                        {getText("Longitude")}
                      </TextInput>
                    </HBox>
                  )}
                </Field>
              </VBox>
              <HBox
                visible={!tag || tag.type === TagType.Private}
                width="100%"
                gap="20px"
              >
                <Text type="caption" size="large" weight="regular">
                  {getText("OptionalFields")}
                </Text>
              </HBox>
              <VBox Margin="20px 0 0 0" gap="20px">
                <Checkbox
                  state={isVisibleTag}
                  onClick={onSetVisible}
                  title="Widoczny na mapie"
                  visible={!!tag && tag.type === TagType.Private && tag.isCote}
                />
                <Button
                  buttonType={ButtonType.Success}
                  height="56px"
                  width="100%"
                  visible={isSelect}
                  onClick={() => onSelect?.(tag as ITag)}
                >
                  {isCote
                    ? getText("AddTagForm.selectCote")
                    : getText("AddTagForm.selectRelease")}
                </Button>
                <Button
                  buttonType={ButtonType.Primary}
                  height="56px"
                  width="100%"
                  onClick={submit}
                  visible={!tag || tag.type === TagType.Private}
                >
                  {!!tag
                    ? getText("AddTagForm.Edit")
                    : getText("AddTagForm.Add")}
                </Button>
                <Button
                  buttonType={ButtonType.Critical}
                  height="56px"
                  width="100%"
                  onClick={() => setModal(AddTagFormModalEnum.Delete)}
                  visible={!!tag && tag.type === TagType.Private}
                >
                  {getText("AddTagForm.Delete")}
                </Button>
                <VBox Margin="250px 0 0 0" />
              </VBox>
            </>
          )}
        </Form>
      </VBox>
    </HalfModal>
  );
};

export default AddTagForm;
