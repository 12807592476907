import { HBox, VBox } from "../../styles/Flexbox.styled";
import { IoAdd, IoBook, IoShareSocial } from "react-icons/io5";
import {
  addAchievement,
  getAchievements,
  removeAchievement,
  updateAchievement,
} from "../../services/PigeonService";

import AddPigeonAchievement from "../../components/modals/AddPigeonAchievement/AddPigeonAchievement";
import { ButtonOpacity } from "../../styles/ButtonOpacity.styled";
import Confirm from "../../components/modals/Confirm/Confirm";
import ConfirmModalTypeEnum from "../../models/enums/ConfirmModalTypeEnum";
import ListItem from "../../components/ListItem/ListItem";
import { ListItemAction } from "../../components/ListItem/IListItem";
import Pigeon from "../../models/Pigeon";
import PigeonAchievement from "../../models/PigeonAchievement";
import Text from "../../components/Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { getText } from "../../locales/initI18n";
import { setError } from "../../redux/modules/error";
import { useAppDispatch } from "../../redux/hooks";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTheme } from "styled-components";

interface AchievementsProps {
  pigeon: Pigeon;
}

const Achievements = ({ pigeon }: AchievementsProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [isAddForm, setIsAddForm] = useState(false);
  const [achievement, setAchievement] = useState<PigeonAchievement | undefined>(
    undefined
  );
  const [isRemoveConfirm, setIsRemoveConfirm] = useState(false);

  const fnGetAchievements = () => {
    try {
      return getAchievements(pigeon);
    } catch (e) {
      dispatch(setError(e));
    }
  };

  const { data: achievements, refetch } = useQuery({
    queryKey: [`pigeonAchievements`, pigeon.id],
    queryFn: () => fnGetAchievements(),
  });

  const onAddPigeonAchievement = async (data: PigeonAchievement) => {
    try {
      data.pigeonId = pigeon.id;
      if (!achievement) await addAchievement(data);
      else await updateAchievement(data);
      refetch();
      setIsAddForm(false);
    } catch (e) {
      dispatch(setError(e));
    }
  };

  const onRemoveAchievement = async () => {
    try {
      if (achievement) await removeAchievement(achievement);
      setIsRemoveConfirm(false);
      refetch();
    } catch (e) {
      dispatch(setError(e));
    }
  };

  if (!achievements) return null;

  return (
    <VBox>
      <AddPigeonAchievement
        isVisible={isAddForm}
        onClose={() => setIsAddForm(false)}
        onConfirm={onAddPigeonAchievement}
        achievement={achievement}
      />
      <Confirm
        isVisible={isRemoveConfirm}
        onClose={() => setIsRemoveConfirm(false)}
        onConfirm={onRemoveAchievement}
        title={`${getText("Achievements.view.modal.remove.title")}: ${
          achievement?.name
        }`}
        description={getText("Achievements.view.modal.remove.description")}
        type={ConfirmModalTypeEnum.Delete}
      />
      <ListItem
        overflowX="hidden"
        overflowY="hidden"
        borderRadius="10px"
        title={getText("Achievements.view.action.add.title")}
        description={getText("Achievements.view.action.add.description")}
        rIcon={<IoAdd color={theme.palette.White} />}
        action={ListItemAction.Success}
        onClick={() => {
          setAchievement(undefined);
          setIsAddForm(true);
        }}
        margin="0 0 10px 0"
      />
      {achievements.map((achievement) => (
        <VBox
          key={achievement.id}
          overflowX="hidden"
          overflowY="hidden"
          backgroundColor={theme.palette.White}
          BorderRadius="10px"
          alignItems="center"
          Padding="0 20px 20px 20px"
          Margin="0 0 10px 0"
        >
          <ListItem
            padding="10px 0"
            title={achievement.name}
            description={achievement.year || "-"}
            action={ListItemAction.Transparent}
            onClick={() => {}}
          />
          <VBox
            width="100%"
            height="2px"
            backgroundColor={theme.palette.Grey10}
            Margin="0 0 10px 0"
          />
          <VBox alignItems="flex-start" width="100%">
            <Text
              type={TextTypeEnum.body}
              color={theme.palette.Grey50}
              weight={TextWeightEnum.regular}
              size={TextSizeEnum.small}
              visible={!!achievement.remarks}
            >
              {getText("Achievements.view.form.description")}:{" "}
              {achievement.remarks}
            </Text>
            <Text
              type={TextTypeEnum.body}
              color={theme.palette.Grey50}
              weight={TextWeightEnum.regular}
              size={TextSizeEnum.small}
            >
              {getText("Achievements.view.form.useInPedigree")}:{" "}
              {achievement.useInPedigree
                ? getText("General.Yes")
                : getText("General.No")}
            </Text>
          </VBox>
          <VBox
            width="100%"
            height="2px"
            backgroundColor={theme.palette.Grey10}
            Margin="10px 0"
          />
          <HBox gap="10px" width="100%">
            <ButtonOpacity
              onClick={() => {
                setAchievement(achievement);
                setIsAddForm(true);
              }}
            >
              <HBox
                height="100%"
                backgroundColor={theme.palette.Primary10}
                Padding="15px"
                BorderRadius="10px"
                alignItems="center"
                justifyContent="center"
                gap="10px"
              >
                <IoBook color={theme.palette.Primary100} size="20px" />
                <Text
                  Margin="5px 0 0 0"
                  type={TextTypeEnum.body}
                  color={theme.palette.Primary100}
                  weight={TextWeightEnum.medium}
                  size={TextSizeEnum.small}
                >
                  {getText("Achievements.view.form.Edit")}
                </Text>
              </HBox>
            </ButtonOpacity>
            <ButtonOpacity
              onClick={() => {
                setAchievement(achievement);
                setIsRemoveConfirm(true);
              }}
            >
              <HBox
                backgroundColor={theme.palette.Red10}
                Padding="15px"
                BorderRadius="10px"
                alignItems="center"
                justifyContent="center"
                gap="10px"
              >
                <IoShareSocial color={theme.palette.Red100} size="20px" />
                <Text
                  Margin="5px 0 0 0"
                  type={TextTypeEnum.body}
                  color={theme.palette.Red100}
                  weight={TextWeightEnum.medium}
                  size={TextSizeEnum.small}
                >
                  {getText("Achievements.view.form.Remove")}
                </Text>
              </HBox>
            </ButtonOpacity>
          </HBox>
        </VBox>
      ))}
    </VBox>
  );
};

export default Achievements;
