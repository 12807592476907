import { Base } from "./Base";
import { Branch } from "./Branch";
import { DistrictType } from "./enums/DistrictType";

export class District extends Base {
  name!: string;
  country!: string;
  type!: DistrictType;
  branches?: Branch[] = [];

  override fillData(data: object): this {
    super.fillData(data);
    this.branches = this.branches?.map(Branch.create.bind(Branch)) as Branch[];

    return this;
  }
}
