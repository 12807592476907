import { LogoProps } from "./ILogo";
import Text from "../Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { VBox } from "../../styles/Flexbox.styled";
import { useTheme } from "styled-components";

const Logo = ({ imgOnly = false, textOnly = false }: LogoProps) => {
  const theme = useTheme();
  return (
    <VBox alignItems="center">
      {!textOnly && (
        <img
          alt="Pigeon Map logo"
          src="/assets/android/logo192.png"
          width="120px"
        />
      )}
      <Text
        visible={!imgOnly}
        color={theme.palette.Grey80}
        type={TextTypeEnum.h2}
        weight={TextWeightEnum.bold}
        size={TextSizeEnum.large}
        Margin="0 0 20px 0"
      >
        Pigeon Map
      </Text>
    </VBox>
  );
};

export default Logo;
