import { Flight } from "./Flight";

export default class MapFlight {
  public interval!: NodeJS.Timer;
  public flight!: Flight;

  get name() {
    return this.flight.id.toString();
  }

  clearInterval() {
    clearInterval(this.interval);
  }
}
