import { AuthState } from "./state";
import Cookies from "js-cookie";
import { PayloadAction } from "@reduxjs/toolkit";

const setAuth = (state: AuthState, action: PayloadAction<AuthState>) => {
  const { token } = action.payload;
  state.token = token;
  Cookies.set("token", token, { path: "/" });
};

const resetAuth = (state: AuthState) => {
  state.token = "";
  Cookies.remove("token", { path: "/" });
};

const reducers = {
  setAuth,
  resetAuth,
};

export default reducers;
