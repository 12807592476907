import { Marker } from "../models/Marker";

/**
 * @description Count distance between two points
 * @param first Type Marker, first point
 * @param second Type Marker, second point
 * @returns amount of kilomeneters between two points
 * @example
 * const first = { latitude: 1, longitude: 1 };
 * const second = { latitude: 2, longitude: 2 };
 * const distance = countDistance(first, second);
 * console.log(distance); // 1.4142135623730951
 * @see https://en.wikipedia.org/wiki/Euclidean_distance
 * @see https://www.npmjs.com/package/geolib
 * @see https://www.npmjs.com/package/geo-distance
 * @see https://www.npmjs.com/package/geodist
 */
export const distance = (first: Marker, second: Marker) => {
  if (
    first.latitude == second.latitude &&
    first.longitude == second.longitude
  ) {
    return 0;
  } else {
    var radlat1 = (Math.PI * first.latitude) / 180;
    var radlat2 = (Math.PI * second.latitude) / 180;
    var theta = first.longitude - second.longitude;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;
    return dist.toFixed(2);
  }
};

export const autoCompleteHandler = (fn: () => void) => {
  document.addEventListener("onautocomplete", (e: any) => {
    if (e.target.hasAttribute("autocompleted")) fn();
  });
};

export const convertTempFToC = (fahrenheit: number) => {
  return Math.round((fahrenheit - 32) * (5 / 9));
};

export const convertMPHtoKPH = (mph: number) => {
  return mph * 1.60934;
};

export const degToCompass = (degrees: number | null | undefined) => {
  if (!degrees) return "-";
  const directions = ["N", "NE", "E", "SE", "S", "SW", "W", "NW"];

  const directionIndex = Math.round((degrees % 360) / 45);

  return directions[directionIndex] || "-";
};
