import { useCallback, useEffect, useRef } from "react";

import { DateInputProps } from "./IDateInput";
import { HBox } from "../../styles/Flexbox.styled";
import { Instance } from "flatpickr/dist/types/instance";
import TextInput from "../TextInput";
import flatpickr from "flatpickr";

const DateInput = (props: DateInputProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const pickrRef = useRef<Instance>();

  const getValue = useCallback(() => {
    if (!props.value) return new Date();
    if (typeof props.value === "string") return new Date(props.value);
    return props.value;
  }, [props.value]);

  useEffect(() => {
    const input = ref.current as HTMLInputElement;
    pickrRef.current = flatpickr(input, {
      defaultDate: [getValue().toISOString()],
      onChange: (selectedDates) => {
        props.onChange(selectedDates[0]);
      },
      enableTime: true,
    });
  }, []);

  useEffect(() => {
    pickrRef.current?.setDate(getValue());
  }, [props.value]);

  return (
    <HBox disabled={props.disabled} width="100%">
      <TextInput inputRef={ref} {...props} />
    </HBox>
  );
};

export default DateInput;
