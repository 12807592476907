import styled from "styled-components";

interface IButtonOpacity {
  width?: string;
}

export const ButtonOpacity = styled.button<IButtonOpacity>`
  border: none;
  background-color: transparent;
  width: ${({ width }) => (width ? width : "100%")};
  font-family: "Roboto", sans-serif;
  padding: 0;
  cursor: pointer;
`;
