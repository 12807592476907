import { Button, ButtonType } from "../../../styles/Button.styled";

import HalfModal from "../../HalfModal/HalfModal";
import { RegisterConfirmationProps } from "./IRegisterConfirmation";
import Text from "../../Text/Text";
import { getText } from "../../../locales/initI18n";
import { useNavigate } from "react-router-dom";

const RegisterConfirmation: React.FC<RegisterConfirmationProps> = ({
  isVisible,
}) => {
  const navigate = useNavigate();
  return (
    <HalfModal
      visible={isVisible}
      onClose={() => navigate("/")}
      heightDivider={1.7}
      isExpander={false}
    >
      <Text type="h3" size="large" weight="bold" Margin="0 0 20px 0">
        {getText("RegisterConfirmation")}
      </Text>
      <Text type="body" size="large" weight="regular" Margin="0 0 20px 0">
        {getText("RegisterConfirmationText")}
      </Text>
      <Button
        onClick={() => navigate("/")}
        buttonType={ButtonType.Primary}
        width="100%"
      >
        {getText("GoToStart")}
      </Button>
    </HalfModal>
  );
};

export default RegisterConfirmation;
