import { useQuery } from "@tanstack/react-query";
import { getUserStatistics, getUsersStatistics } from "../services/UserService";
import UsersStatistics from "../models/UsersStatistics";
import {
  getPigeonsStatistics,
  getUserPigeonsStatistics,
} from "../services/PigeonService";
import {
  getFlightsStatistics,
  getUserFlightsStatistics,
} from "../services/FlightService";
import BaseStatistics from "../models/BaseStatistics";
import UserStatistics from "../models/UserStatistics";
import useUser from "./useUser";

const useStatistics = (userId?: number) => {
  const { data: admin } = useUser();

  const users = useQuery({
    enabled: admin?.isAdmin(),
    queryKey: ["users/statistics"],
    queryFn: () => getUsersStatistics(),
  });

  const user = useQuery({
    queryKey: ["users/statistics", userId],
    enabled: !!userId && admin?.isAdmin(),
    queryFn: () => getUserStatistics(userId || 0),
  });

  const pigeons = useQuery({
    queryKey: ["pigeons/statistics"],
    enabled: admin?.isAdmin(),
    queryFn: () => getPigeonsStatistics(),
  });

  const userPigeons = useQuery({
    queryKey: ["pigeons/statistics", userId],
    enabled: !!userId && admin?.isAdmin(),
    queryFn: () => getUserPigeonsStatistics(userId || 0),
  });

  const flights = useQuery({
    queryKey: ["flights/statistics"],
    enabled: admin?.isAdmin(),
    queryFn: () => getFlightsStatistics(),
  });

  const userFlights = useQuery({
    queryKey: ["flights/statistics", userId],
    enabled: !!userId && admin?.isAdmin(),
    queryFn: () => getUserFlightsStatistics(userId || 0),
  });

  return {
    isAdmin: admin?.isAdmin(),
    users: users.data || new UsersStatistics(),
    user: user.data || new UserStatistics(),
    pigeons: pigeons.data || new BaseStatistics(),
    flights: flights.data || new BaseStatistics(),
    userFlights: userFlights.data || new BaseStatistics(),
    userPigeons: userPigeons.data || new BaseStatistics(),
  };
};

export default useStatistics;
