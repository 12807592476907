import { useEffect, useRef, useState } from "react";

import { CityListProps } from "./ICityList";
import { CityLocalization } from "../../models/CityLocalization";
import ListItem from "../ListItem/ListItem";
import RegionDetector from "../../locales/RegionDetector";
import Text from "../Text/Text";
import TextInput from "../TextInput";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { VBox } from "../../styles/Flexbox.styled";
import { getCityLocalization } from "../../services/WeatherService";
import { getText } from "../../locales/initI18n";
import { useTheme } from "styled-components";

const CityList = ({ onSelect, city, errors }: CityListProps) => {
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const theme = useTheme();
  const [value, setValue] = useState<string>("");
  const [regions, setRegions] = useState<CityLocalization[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (e.target.value.length === 0) return setRegions([]);
    if (e.target.value.length < 3) return;
    timeout.current = setTimeout(async () => {
      const regions = await getCityLocalization(e.target.value);
      setRegions(regions);
    }, 500);
  };

  const getTranslatedState = (state: string) => {
    const translated = getText(`voivodeship.${state}`);
    if (translated.includes("voivodeship")) return state;
    return translated;
  };

  const onOutsideClick = (e: MouseEvent) => {
    if (!containerRef.current) return;
    if (containerRef.current.contains(e.target as Node)) return;
    setRegions([]);
  };

  useEffect(() => {
    window.addEventListener("click", onOutsideClick);
    return () => {
      window.removeEventListener("click", onOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (!city) return;
    setValue(city);
  }, [city]);

  const select = (region?: CityLocalization) => {
    setRegions([]);
    if (!region) {
      onSelect({
        name: value,
        lat: Infinity,
        lon: Infinity,
        country: "Polska",
        state: "",
      });
      return;
    }
    const country = RegionDetector.GetRegionName(region.country) || "";
    onSelect({
      ...region,
      country,
    });
  };

  return (
    <VBox ref={containerRef} position="relative">
      <TextInput value={value} onChange={onChange} placeholder="Sikorowo">
        {getText("AddTagForm.inputCityTitle")}
      </TextInput>
      <Text
        color={theme.palette.Red80}
        type={TextTypeEnum.caption}
        size={TextSizeEnum.large}
        weight={TextWeightEnum.regular}
      >
        {errors?.[0]}
      </Text>
      <VBox
        height="400px"
        position="absolute"
        Top="50px"
        Margin="5px 0 0 0"
        BorderRadius="20px"
        border={regions.length ? `2px solid ${theme.palette.Grey20}` : ""}
        overflowY="auto"
        backgroundColor={theme.palette.White}
      >
        <ListItem
          visible={value.length > 0 && regions.length !== 0}
          onClick={() => select()}
          title={value}
          description={getText("CityList.WithoutLatLon")}
          darkest
        />
        {regions.map((region, index) => (
          <ListItem
            darkest={index % 2 === 1}
            key={region.lat + region.lon}
            title={region.name}
            description={RegionDetector.GetRegionName(region.country)}
            rTitle={region.lat + " " + region.lon}
            rDescription={getTranslatedState(region.state)}
            onClick={() => select(region)}
          />
        ))}
      </VBox>
    </VBox>
  );
};

export default CityList;
