import { HBox, VBox } from "../../styles/Flexbox.styled";
import {
  IoBook,
  IoFemale,
  IoMale,
  IoPencil,
  IoShareSocial,
  IoTrash,
} from "react-icons/io5";
import {
  removeFather,
  removeMother,
  setFather,
  setMother,
} from "../../services/PigeonService";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ButtonOpacity } from "../../styles/ButtonOpacity.styled";
import ChooseFather from "../../components/modals/ChoosePigeon/ChooseFather";
import ChooseMother from "../../components/modals/ChoosePigeon/ChooseMother";
import Confirm from "../../components/modals/Confirm/Confirm";
import ConfirmModalTypeEnum from "../../models/enums/ConfirmModalTypeEnum";
import ConfirmRemoveDependencyParent from "../../components/ConfirmRemoveDependencyParent";
import ListItem from "../../components/ListItem/ListItem";
import { ListItemAction } from "../../components/ListItem/IListItem";
import Pigeon from "../../models/Pigeon";
import Text from "../../components/Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { getText } from "../../locales/initI18n";
import { useState } from "react";
import { useTheme } from "styled-components";

interface ParentsProps {
  pigeon: Pigeon;
}

const Parents = ({ pigeon }: ParentsProps) => {
  const [isChooseFatherVisible, setIsChooseFatherVisible] = useState(false);
  const [isChooseMotherVisible, setIsChooseMotherVisible] = useState(false);
  const [isDeleteFatherVisible, setIsDeleteFatherVisible] = useState(false);
  const [isDeleteMotherVisible, setIsDeleteMotherVisible] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const navigate = useNavigate();

  const getPigeonData = (data?: string | number) => {
    return data?.toString() || "-";
  };

  const closeChoosePigeon = () => {
    setIsChooseFatherVisible(false);
    setIsChooseMotherVisible(false);
  };

  const closeDeletePigeon = () => {
    setIsDeleteFatherVisible(false);
    setIsDeleteMotherVisible(false);
  };

  const setParent = (parent: Pigeon) => {
    if (isChooseFatherVisible) {
      setFather(pigeon, parent);
      pigeon.father = parent;
    } else {
      setMother(pigeon, parent);
      pigeon.mother = parent;
    }
    closeChoosePigeon();
  };

  const removeDependencyParent = async () => {
    if (isDeleteFatherVisible) {
      await removeFather(pigeon);
      pigeon.removeFather();
    } else {
      await removeMother(pigeon);
      pigeon.removeMother();
    }
    closeDeletePigeon();
  };

  const navigateToPigeon = (parent?: Pigeon) => {
    if (!parent) return;
    searchParams.set("tab", "0");
    const breadcrumbs = searchParams.get("breadcrumbs");
    if (breadcrumbs) {
      const parsedBreadcrumbs = JSON.parse(breadcrumbs) as [number, string][];
      parsedBreadcrumbs.push([pigeon.id, pigeon.ring]);
      searchParams.set("breadcrumbs", JSON.stringify(parsedBreadcrumbs));
    } else {
      searchParams.set("breadcrumbs", JSON.stringify([pigeon.id, pigeon.ring]));
    }
    navigate(`/dashboard/pigeons/${parent.id}?${searchParams.toString()}`);
  };

  return (
    <VBox gap="10px" Margin="0 0 40px 0">
      <ChooseFather
        pigeon={pigeon}
        isVisible={isChooseFatherVisible}
        onClose={closeChoosePigeon}
        onConfirm={(pigeon) => setParent(pigeon)}
      />
      <ChooseMother
        pigeon={pigeon}
        isVisible={isChooseMotherVisible}
        onClose={closeChoosePigeon}
        onConfirm={(pigeon) => setParent(pigeon)}
      />
      <ConfirmRemoveDependencyParent
        pigeon={pigeon}
        parent={isDeleteFatherVisible ? pigeon.father : pigeon.mother}
        onClose={closeDeletePigeon}
        onConfirm={removeDependencyParent}
        isVisible={isDeleteFatherVisible || isDeleteMotherVisible}
      />
      <ListItem
        visible={!pigeon.father}
        title={getText("General.father")}
        description={getText("Parents.view.father.description")}
        action={ListItemAction.Primary}
        icon={<IoMale />}
        rIcon={<IoShareSocial color={theme.palette.White} />}
        onClick={() => setIsChooseFatherVisible(true)}
      />
      <ListItem
        visible={!pigeon.mother}
        title={getText("General.mother")}
        description={getText("Parents.view.mother.description")}
        action={ListItemAction.Primary}
        icon={<IoFemale />}
        rIcon={<IoShareSocial color={theme.palette.White} />}
        onClick={() => setIsChooseMotherVisible(true)}
      />
      <VBox
        overflowX="hidden"
        overflowY="hidden"
        backgroundColor={theme.palette.White}
        BorderRadius="10px"
        alignItems="center"
        Padding="0 20px 20px 20px"
        visible={!!pigeon.father}
      >
        <ListItem
          title={getText("General.father")}
          description={`${getPigeonData(pigeon?.father?.ring)} (${getPigeonData(
            pigeon?.father?.name
          )})`}
          action={ListItemAction.Transparent}
          icon={<IoMale />}
          onClick={() => {}}
        />
        <VBox
          width="100%"
          height="2px"
          backgroundColor={theme.palette.Grey10}
          Margin="0 0 10px 0"
        />
        <VBox alignItems="flex-start" width="100%">
          <Text
            type={TextTypeEnum.body}
            color={theme.palette.Grey50}
            weight={TextWeightEnum.regular}
            size={TextSizeEnum.small}
          >
            {getText("General.Color")}: {pigeon.father?.ReadableColor}
          </Text>
          <Text
            type={TextTypeEnum.body}
            color={theme.palette.Grey50}
            weight={TextWeightEnum.regular}
            size={TextSizeEnum.small}
          >
            {getText("General.Breed")}: {pigeon.father?.breed || "-"}
          </Text>
          <Text
            type={TextTypeEnum.body}
            color={theme.palette.Grey50}
            weight={TextWeightEnum.regular}
            size={TextSizeEnum.small}
          >
            {getText("General.State")}: {pigeon.father?.ReadableState}
          </Text>
        </VBox>
        <VBox
          width="100%"
          height="2px"
          backgroundColor={theme.palette.Grey10}
          Margin="10px 0"
        />
        <HBox gap="10px">
          <ButtonOpacity onClick={() => navigateToPigeon(pigeon.father)}>
            <VBox
              height="100%"
              backgroundColor={theme.palette.Grey5}
              Padding="15px"
              BorderRadius="10px"
              alignItems="center"
              justifyContent="center"
            >
              <IoBook color={theme.palette.Grey60} size="16px" />
              <Text
                Margin="5px 0 0 0"
                type={TextTypeEnum.body}
                color={theme.palette.Grey60}
                weight={TextWeightEnum.medium}
                size={TextSizeEnum.small}
              >
                {getText("Parents.view.action.show")}
              </Text>
            </VBox>
          </ButtonOpacity>
          <ButtonOpacity onClick={() => setIsChooseFatherVisible(true)}>
            <VBox
              backgroundColor={theme.palette.Primary10}
              Padding="15px"
              BorderRadius="10px"
              alignItems="center"
              justifyContent="center"
            >
              <IoShareSocial color={theme.palette.Primary100} size="16px" />
              <Text
                Margin="5px 0 0 0"
                type={TextTypeEnum.body}
                color={theme.palette.Primary100}
                weight={TextWeightEnum.medium}
                size={TextSizeEnum.small}
              >
                {getText("Parents.view.action.ChangeFather")}
              </Text>
            </VBox>
          </ButtonOpacity>
          <ButtonOpacity onClick={() => setIsDeleteFatherVisible(true)}>
            <VBox
              backgroundColor={theme.palette.Red10}
              Padding="15px"
              BorderRadius="10px"
              alignItems="center"
              justifyContent="center"
            >
              <IoTrash color={theme.palette.Red100} size="16px" />
              <Text
                Margin="5px 0 0 0"
                type={TextTypeEnum.body}
                color={theme.palette.Red100}
                weight={TextWeightEnum.medium}
                size={TextSizeEnum.small}
              >
                {getText("Parents.view.action.RemoveDependency")}
              </Text>
            </VBox>
          </ButtonOpacity>
        </HBox>
      </VBox>
      <VBox
        overflowX="hidden"
        overflowY="hidden"
        backgroundColor={theme.palette.White}
        BorderRadius="10px"
        alignItems="center"
        Padding="0 20px 20px 20px"
        visible={!!pigeon.mother}
      >
        <ListItem
          title={getText("General.mother")}
          description={`${getPigeonData(pigeon?.mother?.ring)} (${getPigeonData(
            pigeon?.mother?.name
          )})`}
          action={ListItemAction.Transparent}
          icon={<IoFemale />}
          onClick={() => {}}
        />
        <VBox
          width="100%"
          height="2px"
          backgroundColor={theme.palette.Grey10}
          Margin="0 0 10px 0"
        />
        <VBox alignItems="flex-start" width="100%">
          <Text
            type={TextTypeEnum.body}
            color={theme.palette.Grey50}
            weight={TextWeightEnum.regular}
            size={TextSizeEnum.small}
          >
            {getText("General.Color")}: {pigeon.mother?.ReadableColor}
          </Text>
          <Text
            type={TextTypeEnum.body}
            color={theme.palette.Grey50}
            weight={TextWeightEnum.regular}
            size={TextSizeEnum.small}
          >
            {getText("General.Breed")}: {pigeon.mother?.breed || "-"}
          </Text>
          <Text
            type={TextTypeEnum.body}
            color={theme.palette.Grey50}
            weight={TextWeightEnum.regular}
            size={TextSizeEnum.small}
          >
            {getText("General.State")}: {pigeon.mother?.ReadableState}
          </Text>
        </VBox>
        <VBox
          width="100%"
          height="2px"
          backgroundColor={theme.palette.Grey10}
          Margin="10px 0"
        />
        <HBox gap="10px">
          <ButtonOpacity onClick={() => navigateToPigeon(pigeon.mother)}>
            <VBox
              height="100%"
              backgroundColor={theme.palette.Grey5}
              Padding="15px"
              BorderRadius="10px"
              alignItems="center"
              justifyContent="center"
            >
              <IoBook color={theme.palette.Grey60} size="16px" />
              <Text
                Margin="5px 0 0 0"
                type={TextTypeEnum.body}
                color={theme.palette.Grey60}
                weight={TextWeightEnum.medium}
                size={TextSizeEnum.small}
              >
                {getText("Parents.view.action.show")}
              </Text>
            </VBox>
          </ButtonOpacity>
          <ButtonOpacity onClick={() => setIsChooseMotherVisible(true)}>
            <VBox
              backgroundColor={theme.palette.Primary10}
              Padding="15px"
              BorderRadius="10px"
              alignItems="center"
              justifyContent="center"
            >
              <IoShareSocial color={theme.palette.Primary100} size="16px" />
              <Text
                Margin="5px 0 0 0"
                type={TextTypeEnum.body}
                color={theme.palette.Primary100}
                weight={TextWeightEnum.medium}
                size={TextSizeEnum.small}
              >
                {getText("Parents.view.action.ChangeMother")}
              </Text>
            </VBox>
          </ButtonOpacity>
          <ButtonOpacity onClick={() => setIsDeleteMotherVisible(true)}>
            <VBox
              backgroundColor={theme.palette.Red10}
              Padding="15px"
              BorderRadius="10px"
              alignItems="center"
              justifyContent="center"
            >
              <IoTrash color={theme.palette.Red100} size="16px" />
              <Text
                Margin="5px 0 0 0"
                type={TextTypeEnum.body}
                color={theme.palette.Red100}
                weight={TextWeightEnum.medium}
                size={TextSizeEnum.small}
              >
                {getText("Parents.view.action.RemoveDependency")}
              </Text>
            </VBox>
          </ButtonOpacity>
        </HBox>
      </VBox>
    </VBox>
  );
};

export default Parents;
