import Loader from "./Loader/loader";
import Text from "./Text/Text";
import TextSizeEnum from "../models/enums/TextSizeEnum";
import TextTypeEnum from "../models/enums/TextTypeEnum";
import TextWeightEnum from "../models/enums/TextWeightEnum";
import { VBox } from "../styles/Flexbox.styled";
import { getText } from "../locales/initI18n";
import { useTheme } from "styled-components";

const Busy = ({
  visible,
  opacity = 0.5,
  text,
}: {
  visible: boolean;
  opacity?: number;
  text?: string;
}) => {
  const theme = useTheme();
  return (
    <VBox
      className="BUSY_PIGEONMAP"
      visible={visible}
      alignItems="center"
      zIndex={5}
      justifyContent="center"
      width="100%"
      position="absolute"
      height="100%"
    >
      <VBox
        position="absolute"
        Top="0"
        Left="0"
        width="100%"
        height="100%"
        backgroundColor={theme.palette.White}
        style={{ opacity }}
      />
      <Loader />
      <Text
        type={TextTypeEnum.body}
        size={TextSizeEnum.medium}
        weight={TextWeightEnum.medium}
        color={theme.palette.Primary80}
      >
        {text || getText("Busy.PleaseWait")}
      </Text>
    </VBox>
  );
};

export default Busy;
