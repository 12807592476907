import Pigeon from "../../models/Pigeon";

export enum PigeonNodeAction {
  Show,
  Edit,
  Remove,
}

export type PigeonActionFunction = (
  type: PigeonNodeAction,
  pigeon: Pigeon
) => void;

export interface EmptyNodeData {
  pigeon: Pigeon;
  level: number;
  isFather: boolean;
}

export interface PigeonNodeData {
  pigeon: Pigeon;
  predecessor?: Pigeon;
  level: number;
  onAction: PigeonActionFunction;
}
