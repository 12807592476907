import { IoFlagOutline, IoHomeOutline, IoMapOutline } from "react-icons/io5";
import { MapOption, MapProps } from "./IMapOptions";

import HalfModal from "../../HalfModal/HalfModal";
import ListItem from "../../ListItem/ListItem";
import { ListItemAction } from "../../ListItem/IListItem";
import MapOptionsModals from "./MapOptionsModals";
import Text from "../../Text/Text";
import { VBox } from "../../../styles/Flexbox.styled";
import { getText } from "../../../locales/initI18n";
import { useState } from "react";
import { useTheme } from "styled-components";

const MapOptions: React.FC<MapProps> = ({ isVisible, onClose, onConfirm }) => {
  const theme = useTheme();
  const [mapOption, setMapOption] = useState<MapOption>(MapOption.NONE);

  const hideModal = () => setMapOption(MapOption.NONE);

  if (!isVisible) return null;
  return (
    <>
      <MapOptionsModals
        mapOption={mapOption}
        onClose={hideModal}
        onConfirm={(props) => {
          hideModal();
          onConfirm(props);
        }}
      />
      <HalfModal
        visible
        onClose={onClose}
        heightDivider={1.9}
        isExpander={false}
        Padding="40px 0 20px 0"
      >
        <VBox>
          <Text
            type="h4"
            size="large"
            weight="bold"
            Margin="0 20px 10px 20px"
            color={theme.palette.Grey100}
          >
            {getText("Map.Options")}
          </Text>
          <ListItem
            darkest
            title={getText("Map.OptionFlights")}
            description={getText("Map.OptionFlights.Description")}
            rIcon={<IoMapOutline color={theme.palette.White} />}
            action={ListItemAction.Primary}
            onClick={() => setMapOption(MapOption.FLIGHT)}
          />
          <ListItem
            title={getText("Map.OptionReleasePlaces")}
            description={getText("Map.OptionReleasePlaces.Description")}
            rIcon={<IoFlagOutline color={theme.palette.Grey80} />}
            action={ListItemAction.Secondary}
            onClick={() => setMapOption(MapOption.RELEASE)}
          />
          <ListItem
            darkest
            title={getText("Map.OptionCotes")}
            description={getText("Map.OptionCotes.Description")}
            rIcon={<IoHomeOutline color={theme.palette.Grey80} />}
            action={ListItemAction.Secondary}
            onClick={() => setMapOption(MapOption.COTE)}
          />
        </VBox>
      </HalfModal>
    </>
  );
};

export default MapOptions;
