import ChildFilter from "../../../models/Filter/ChildFilter";
import { ChooseFatherProps } from "./IChooseFather";
import ChoosePigeon from "./ChoosePigeon";
import { FilterName } from "../../../models/enums/FilterName";
import ParentFilter from "../../../models/Filter/ParentFilter";
import { ParentFilterOperator } from "../../../models/Filter/ParentFilterOperator";
import { getText } from "../../../locales/initI18n";

const ChooseFather = (props: ChooseFatherProps) => {
  return (
    <ChoosePigeon
      isVisible={props.isVisible}
      onConfirm={props.onConfirm}
      onClose={props.onClose}
      title={getText("ChoosePigeonFather.title")}
      description={getText("ChoosePigeonFather.description")}
      initFilter={
        new ParentFilter(
          [
            new ChildFilter(FilterName.Sex, 0),
            new ChildFilter(FilterName.NotId, props.pigeon.id),
          ],
          ParentFilterOperator.And
        )
      }
    />
  );
};
export default ChooseFather;
