import { HBox, VBox } from "../../styles/Flexbox.styled";
import { Handle, NodeProps, Position } from "reactflow";

import { EmptyNodeData } from "./NodeProps";
import { IoAddOutline } from "react-icons/io5";
import Text from "../Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { getText } from "../../locales/initI18n";
import { memo } from "react";
import { useTheme } from "styled-components";

export default memo(({ isConnectable, data }: NodeProps<EmptyNodeData>) => {
  const theme = useTheme();

  return (
    <VBox
      position="relative"
      Padding="10px"
      BorderRadius="10px"
      justifyContent="center"
      border={`1px solid ${theme.palette.Grey10}`}
      backgroundColor={theme.palette.Grey5}
      minWidth="200px"
      minHeight="150px"
    >
      <HBox
        justifyContent="center"
        alignItems="center"
        backgroundColor={theme.palette.White}
        border={`1px solid ${theme.palette.Grey10}`}
        BorderRadius="10px"
        Padding="10px 0"
      >
        <IoAddOutline size={24} color={theme.palette.Grey30} />
        <Text
          Padding="0 0 0 5px"
          color={theme.palette.Grey60}
          size={TextSizeEnum.medium}
          weight={TextWeightEnum.regular}
          type={TextTypeEnum.body}
        >
          {data.isFather
            ? getText("EmptyNode.action.AddFather")
            : getText("EmptyNode.action.AddMother")}
        </Text>
      </HBox>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: theme.palette.Grey40 }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
    </VBox>
  );
});
