import ChildFilter from "../models/Filter/ChildFilter";
import { FilterName } from "../models/enums/FilterName";
import { IBaseFilter } from "../models/Filter/IBaseFilter";
import ParentFilter from "../models/Filter/ParentFilter";
import { ParentFilterOperator } from "../models/Filter/ParentFilterOperator";
import { getCotes } from "../services/TagService";
import { useQuery } from "@tanstack/react-query";
import useUser from "./useUser";
import PageOptions from "../models/PageOptions";

const useCotes = (filters?: IBaseFilter) => {
  const { data: user } = useUser();

  const getFilters = () => {
    if (filters) return filters.transform();
    return new ParentFilter(
      [
        new ChildFilter(FilterName.UserId, user?.id),
        new ChildFilter(FilterName.IsCote, true),
      ],
      ParentFilterOperator.And
    ).transform();
  };

  const result = useQuery({
    queryKey: ["cotes", { filters: getFilters() }],
    enabled: !!user,
    queryFn: () => {
      const options = new PageOptions();
      return getCotes(options, getFilters());
    },
  });

  return result;
};

export default useCotes;
