import { Button, ButtonType } from "../../../styles/Button.styled";
import { HBox, VBox } from "../../../styles/Flexbox.styled";
import {
  IoAnalyticsOutline,
  IoArrowUp,
  IoDocumentOutline,
  IoEllipseOutline,
  IoEyeOutline,
  IoPencilOutline,
  IoRainyOutline,
  IoThermometerOutline,
  IoTimeOutline,
} from "react-icons/io5";
import { degToCompass, distance } from "../../../utils/utils";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import FlightMarkerForm from "../FlightMarkerForm/FlightMarkerForm";
import { FlightMarkerInformationProps } from "./IFlightMarkerInformation";
import { FlightMarkerPosition } from "../../../models/enums/FlightMarkerPosition";
import Formatter from "../../../utils/Formatter";
import HalfModal from "../../HalfModal/HalfModal";
import HighligtedText from "../../HighlightedText/HighlightedText";
import ListItem from "../../ListItem/ListItem";
import Text from "../../Text/Text";
import TextSizeEnum from "../../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../../models/enums/TextWeightEnum";
import { formatDateTime } from "../../../utils/VariableHelper";
import { getText } from "../../../locales/initI18n";
import { getWeather } from "../../../services/WeatherService";
import { updateFlightMarker } from "../../../services/FlightService";
import { useTheme } from "styled-components";

const FlightMarkerInformation = ({
  isVisible,
  marker,
  flight,
  onClose,
  isMapWeatherVisible,
  onChangeMapWeatherVisibility,
}: FlightMarkerInformationProps) => {
  const theme = useTheme();
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const [isForm, setIsForm] = useState(false);
  const [isWeatherVisible, setIsWeatherVisible] = useState<boolean>(true);

  const getMarkerWeather = async () => {
    if (!marker) return;
    return getWeather(marker, marker.estimateDate);
  };

  const { data: weather, isLoading } = useQuery({
    queryKey: [
      "weather",
      marker?.latitude,
      marker?.longitude,
      marker?.estimateDate,
    ],
    queryFn: getMarkerWeather,
    enabled: isVisible && !!marker && !!flight,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const getFlightDistance = (place: "start" | "end") => {
    if (!marker || !flight) return "";
    const numberOfPoints = flight.coordinates.length;
    if (place === "start") return distance(marker, flight.coordinates[0]);
    return distance(marker, flight.coordinates[numberOfPoints - 1]);
  };

  const getFlightPositionColor = () => {
    if (!marker) return theme.palette.Grey40;
    switch (marker.position) {
      case FlightMarkerPosition.START:
        return theme.palette.Yellow100;
      case FlightMarkerPosition.END:
        return theme.palette.Green100;
      case FlightMarkerPosition.MIDDLE:
        return theme.palette.Primary40;
      default:
        return theme.palette.Primary40;
    }
  };
  const getFlightPositonText = () => {
    if (!marker) return "";
    switch (marker.position) {
      case FlightMarkerPosition.START:
        return getText("StartingPosition");
      case FlightMarkerPosition.END:
        return getText("EndingPosition");
      case FlightMarkerPosition.MIDDLE:
        return getText("IntermediatePosition");
    }
  };

  const addRemarks = async ({ remarks }: { remarks: string }) => {
    await updateFlightMarker(marker?.flightId, marker?.id, remarks);
    if (marker) marker.remarks = remarks;
    setIsForm(false);
  };

  useEffect(() => {
    if (isMapWeatherVisible !== isWeatherVisible)
      setIsWeatherVisible(isMapWeatherVisible);
  }, [isMapWeatherVisible]);

  if (!isVisible || !marker) return null;
  return (
    <HalfModal
      visible
      heightDivider={3.0}
      isOutsideClickable
      onClose={onClose}
      busy={isLoading}
      onExpand={(expand: boolean) => setIsExpand(expand)}
      Padding="0"
      customButton={
        <Button
          isCustom={true}
          buttonType={ButtonType.Primary}
          height="50px"
          width="130px"
          padding="0 10px 0 10px"
          onClick={() => {
            onChangeMapWeatherVisibility(!isWeatherVisible);
            setIsWeatherVisible(!isWeatherVisible);
          }}
        >
          <Text
            type={TextTypeEnum.caption}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.bold}
            color={theme.palette.White}
            textAlign="center"
            textWrap="wrap"
          >
            {isWeatherVisible
              ? getText("FlightMarkerInformation.HideWeather")
              : getText("FlightMarkerInformation.ShowWeather")}
          </Text>
        </Button>
      }
    >
      <VBox Margin="40px 0 0 20px">
        <FlightMarkerForm
          onConfirm={addRemarks}
          remarks={marker?.remarks || ""}
          onClose={() => setIsForm(false)}
          isVisible={isForm}
        />
        <HBox alignItems="center">
          <Text
            type="body"
            size="large"
            weight="bold"
            color={theme.palette.Grey90}
            Margin="0 5px 0 0"
          >
            {getText("FlightMarkerInformation.FlightPoint")}
          </Text>
          <HighligtedText
            text={getFlightPositonText()}
            backgroundColor={getFlightPositionColor()}
          />
        </HBox>
        <Text
          type={TextTypeEnum.body}
          size={TextSizeEnum.medium}
          weight={TextWeightEnum.regular}
          color={theme.palette.Grey70}
        >
          {`${marker.latitude}, ${marker.longitude}`}
        </Text>
      </VBox>
      <VBox overflowY="auto" visible={!isExpand}>
        <ListItem
          busy={isLoading}
          padding="5px 20px"
          title={getText("FlightMarkerInformation.WindSpeed")}
          rTitle={getText("FlightMarkerInformation.Temperature")}
          description={Formatter.WindSpeedAndDirection(
            weather?.windSurface,
            weather?.WindDirectionSurface
          )}
          rDescription={Formatter.Temperature(weather?.tempSurface)}
        />
        <ListItem
          darkest
          busy={isLoading}
          padding="5px 20px"
          title={getText("FlightMarkerInformation.DistanceToPigeonLoft")}
          rTitle={getText("Liquid")}
          description={getFlightDistance("end")}
          rDescription={Formatter.Liquid(weather?.liquid)}
        />
      </VBox>
      <VBox overflowY="auto" visible={isExpand}>
        {/* EXPAND */}
        <Text type="h5" size="large" weight="medium" Margin="10px 5px 0 20px">
          {getText("FlightMarkerInformation.MarkerDetails")}
        </Text>
        <ListItem
          title={getText("ArrivalDate")}
          description={
            marker?.estimateDate ? formatDateTime(marker.estimateDate) : "-"
          }
          icon={<IoTimeOutline size={24} color={theme.palette.Grey50} />}
          darkest={true}
        />
        <ListItem
          title={getText("DistanceToStart")}
          description={getFlightDistance("start") + " km"}
          icon={<IoAnalyticsOutline size={24} color={theme.palette.Grey50} />}
          darkest={false}
        />
        <ListItem
          title={getText("DistanceToEnd")}
          description={getFlightDistance("end") + " km"}
          icon={<IoAnalyticsOutline size={24} color={theme.palette.Grey50} />}
          darkest={true}
        />
        <ListItem
          title={getText("Remarks")}
          description={marker?.remarks || "(click to edit)"}
          icon={<IoDocumentOutline size={24} color={theme.palette.Grey50} />}
          darkest={false}
          onClick={() => setIsForm(true)}
          rIcon={<IoPencilOutline size={24} color={theme.palette.Grey80} />}
        />
        <Text type="h5" size="large" weight="medium" Margin="10px 5px 0 20px">
          {getText("FlightMarkerInformation.Weather")}
        </Text>
        <ListItem
          title={getText("Temperature")}
          description={Formatter.Temperature(weather?.tempSurface)}
          icon={<IoThermometerOutline size={24} color={theme.palette.Grey50} />}
          darkest={false}
        />
        <ListItem
          title={getText("Temperature100m")}
          description={Formatter.Temperature(weather?.temp100m)}
          icon={<IoThermometerOutline size={24} color={theme.palette.Grey50} />}
          darkest={true}
        />
        <ListItem
          title={getText("WindSpeed")}
          description={Formatter.WindSpeedAndDirection(
            weather?.windSurface,
            weather?.WindDirectionSurface
          )}
          icon={
            <IoArrowUp
              style={{
                transform: `rotate(${weather?.WindDirectionSurface || 0}deg)`,
              }}
              size={24}
              color={theme.palette.Grey50}
            />
          }
          darkest={false}
        />
        <ListItem
          title={getText("WindSpeed100m")}
          description={Formatter.WindSpeedAndDirection(
            weather?.wind100m,
            weather?.WindDirection100m
          )}
          icon={
            <IoArrowUp
              style={{
                transform: `rotate(${weather?.WindDirection100m || 0}deg)`,
              }}
              size={24}
              color={theme.palette.Grey50}
            />
          }
          darkest={false}
        />
        <ListItem
          title={getText("Liquid")}
          description={Formatter.Liquid(weather?.liquid)}
          icon={<IoRainyOutline size={24} color={theme.palette.Grey50} />}
          darkest={false}
        />
        <ListItem
          title={getText("Pressure")}
          description={
            weather?.pressure ? `${weather.pressure.toFixed(2)}mb` : "-"
          }
          icon={<IoEllipseOutline size={24} color={theme.palette.Grey50} />}
          darkest={true}
        />
        <ListItem
          title={getText("Humidity")}
          description={
            weather?.humidity ? `${weather.humidity.toFixed(2)}%` : "-"
          }
          icon={<IoEllipseOutline size={24} color={theme.palette.Grey50} />}
          darkest={false}
        />
        <ListItem
          title={getText("Visibility")}
          description={
            weather?.visibility ? `${weather.visibility.toFixed(2)} km` : "-"
          }
          icon={<IoEyeOutline size={24} color={theme.palette.Grey50} />}
          darkest={true}
        />
      </VBox>
    </HalfModal>
  );
};

export default FlightMarkerInformation;
