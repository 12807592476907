import { Button, ButtonType } from "../../../styles/Button.styled";
import { HBox, VBox } from "../../../styles/Flexbox.styled";
import {
  IoArrowUp,
  IoDocumentOutline,
  IoEllipseOutline,
  IoEyeOutline,
  IoLocationOutline,
  IoRainyOutline,
  IoThermometerOutline,
  IoTimeOutline,
} from "react-icons/io5";
import { formatDateTime, getRelativeTime } from "../../../utils/VariableHelper";
import { useEffect, useRef, useState } from "react";

import { AxiosResponse } from "axios";
import { CloseIconEnum } from "../../HalfModal/CloseIconEnum";
import DateHelper from "../../../utils/DateHelper";
import FlightHelper from "../../../utils/FlightHelper";
import FlightHighlightedInfo from "../../FlightHighlightedInfo";
import { FlightSummaryOptions } from "./IFlightSummary";
import Formatter from "../../../utils/Formatter";
import HalfModal from "../../HalfModal/HalfModal";
import { IUser } from "../../../services/IUserService";
import ListItem from "../../ListItem/ListItem";
import Text from "../../Text/Text";
import TextSizeEnum from "../../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../../models/enums/TextWeightEnum";
import TimeSlider from "../../TimeSlider";
import { Weather } from "../../../models/Weather";
import { distance } from "../../../utils/utils";
import { getSummaryWeather } from "../../../services/WeatherService";
import { getText } from "../../../locales/initI18n";
import { useQuery } from "@tanstack/react-query";
import { useTheme } from "styled-components";
import useUser from "../../../hooks/useUser";

const FlightSummary = ({
  isVisible,
  onClose,
  flight,
  changeTime,
  onAfterChangeTime,
  isMapWeatherVisible,
  onChangeMapWeatherVisibility,
}: FlightSummaryOptions) => {
  const EXPANDED_VALUE = 1.1 as const;
  const COLLAPSED_VALUE = 3.0 as const;
  const theme = useTheme();
  const [expandValue, setExpandValue] = useState<number>(COLLAPSED_VALUE);
  const [timeLeft, setTimeLeft] = useState<string>("");
  const interval = useRef<NodeJS.Timeout | null>(null);
  const [isWeatherVisible, setIsWeatherVisible] = useState<boolean>(true);

  const isExpand = () => expandValue === EXPANDED_VALUE;
  const isCollapsed = () => expandValue === COLLAPSED_VALUE;

  const getSummaryData = async () => {
    if (!flight) return;
    const weather = await getSummaryWeather(flight.id);
    return {
      weather,
      startLatitude: flight.coordinates[0].latitude,
      startLongitude: flight.coordinates[0].longitude,
      endLatitude: flight.coordinates[flight.coordinates.length - 1].latitude,
      endLongitude: flight.coordinates[flight.coordinates.length - 1].longitude,
      startDate: flight.coordinates[0].estimateDate,
      endDate: flight.coordinates[flight.coordinates.length - 1].estimateDate,
    };
  };

  const flightDistance = () => {
    if (!flight) return;
    const start = flight.coordinates[0];
    const end = flight.coordinates[flight.coordinates.length - 1];
    return distance(start, end);
  };

  const flightedDistance = () => {
    const now = new Date();
    const startDate = DateHelper.toDate(flight?.startDate);
    const endDate = DateHelper.toDate(flight?.endDate);
    if (now < startDate) return 0;
    if (now > endDate) return flightDistance() || 0;

    const flightedDiff = DateHelper.getDiff(startDate, now);
    const flightDiff = DateHelper.getDiff(startDate, endDate);
    const ratio = flightDiff / flightedDiff;
    if (!ratio) return 0;
    return flightDistance() || 0 * ratio;
  };

  const { data: user } = useUser();
  const { data: summary, isLoading } = useQuery({
    queryKey: ["weather", flight?.id],
    queryFn: getSummaryData,
    enabled: isVisible,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (interval.current) clearInterval(interval.current);
    if (!isVisible) {
      setTimeLeft("");
      return;
    }
    if (!flight?.endDate) return;
    interval.current = setInterval(() => {
      if (!flight) return;
      setTimeLeft(getRelativeTime(new Date(flight.endDate)) || "");
    }, 1000);
    return () => {
      interval.current && clearInterval(interval.current);
    };
  }, [isVisible]);

  useEffect(() => {
    if (isMapWeatherVisible !== isWeatherVisible)
      setIsWeatherVisible(isMapWeatherVisible);
  }, [isMapWeatherVisible]);

  const onClosePress = () => {
    if (isExpand()) setExpandValue(COLLAPSED_VALUE);
    if (isCollapsed()) onClose();
  };

  if (!isVisible || !flight) return null;

  const weather: Partial<Weather> = summary?.weather || {};
  if (!isVisible) return null;
  return (
    <HalfModal
      visible
      onClose={onClosePress}
      closeIcon={isExpand() ? CloseIconEnum.COLLAPSE : CloseIconEnum.CLOSE}
      heightDivider={expandValue}
      isExpander={isCollapsed()}
      Padding="0"
      isOutsideClickable={isCollapsed()}
      busy={isLoading && isExpand()}
      onExpand={(expand: boolean) =>
        setExpandValue(expand ? EXPANDED_VALUE : COLLAPSED_VALUE)
      }
      customButton={
        <Button
          isCustom={true}
          buttonType={ButtonType.Primary}
          height="50px"
          width="130px"
          padding="0 10px 0 10px"
          onClick={() => {
            onChangeMapWeatherVisibility(!isWeatherVisible);
            setIsWeatherVisible(!isWeatherVisible);
          }}
        >
          <Text
            type={TextTypeEnum.caption}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.bold}
            color={theme.palette.White}
            textAlign="center"
            textWrap="wrap"
          >
            {isWeatherVisible
              ? getText("FlightMarkerInformation.HideWeather")
              : getText("FlightMarkerInformation.ShowWeather")}
          </Text>
        </Button>
      }
    >
      <VBox
        width="100%"
        overflowY="auto"
        Padding="10px 0px 0 0px"
        visible={isCollapsed()}
      >
        <VBox Padding="20px 20px 0 20px" width="100%">
          <FlightHighlightedInfo flight={flight} currentUser={user} />
          <VBox width="100%" whiteSpace="nowrap">
            <Text
              type={TextTypeEnum.body}
              size={TextSizeEnum.large}
              weight={TextWeightEnum.bold}
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {`${getText("FlightSummary.FlightPanel")} | ${flight?.name}`}
            </Text>
          </VBox>
        </VBox>
        <VBox Padding="0 20px 0 20px" visible={!!timeLeft}>
          <HBox
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Text
              type={TextTypeEnum.body}
              size={TextSizeEnum.small}
              weight={TextWeightEnum.regular}
              color={theme.palette.Grey60}
            >
              {`${getText("FlightSummary.FlightEnd")} ${timeLeft}.`}
            </Text>
          </HBox>
        </VBox>
        <VBox Padding="0 20px" visible={FlightHelper.isWeatherLayer(flight)}>
          <TimeSlider
            changeTime={changeTime}
            onAfterChange={onAfterChangeTime}
          />
        </VBox>
        <ListItem
          busy={isLoading}
          padding="5px 20px"
          title={getText("FlightSummary.WindSpeed")}
          rTitle={getText("FlightSummary.Temperature")}
          description={Formatter.WindSpeedAndDirection(
            weather?.windSurface,
            weather?.WindDirectionSurface
          )}
          rDescription={Formatter.Temperature(weather?.tempSurface)}
          darkest={true}
        />
        <ListItem
          busy={isLoading}
          padding="5px 20px"
          title={getText("FlightSummary.DistanceCovered")}
          rTitle={getText("Liquid")}
          description={`${flightedDistance()} km`}
          rDescription={Formatter.Liquid(weather?.liquid)}
          darkest={true}
        />
      </VBox>
      <VBox overflowY="auto" visible={isExpand()}>
        <Text type="h5" size="large" weight="medium" Margin="10px 5px 0 20px">
          {getText("FlightSummary.BasicInformation")}
        </Text>
        <ListItem
          title={getText("FlightSummary.FlightName")}
          description={flight.name}
          icon={<IoDocumentOutline size={24} color={theme.palette.Grey50} />}
          darkest={true}
        />
        <ListItem
          title={getText("StartDate")}
          description={formatDateTime(flight.startDate)}
          icon={<IoTimeOutline size={24} color={theme.palette.Grey50} />}
          darkest={false}
        />
        <ListItem
          title={getText("EndDate")}
          description={formatDateTime(summary?.endDate)}
          icon={<IoTimeOutline size={24} color={theme.palette.Grey50} />}
          darkest={true}
        />
        <ListItem
          title={getText("Distance")}
          description={`${flightDistance()} km`}
          icon={<IoLocationOutline size={24} color={theme.palette.Grey50} />}
          darkest={false}
        />
        <Text type="h5" size="large" weight="medium" Margin="10px 5px 0 20px">
          {getText("Weather")}
        </Text>
        <ListItem
          title={getText("Temperature")}
          description={Formatter.Temperature(weather?.tempSurface)}
          icon={<IoThermometerOutline size={24} color={theme.palette.Grey50} />}
          darkest={true}
        />
        <ListItem
          title={getText("Temperature100m")}
          description={Formatter.Temperature(weather?.temp100m)}
          icon={<IoThermometerOutline size={24} color={theme.palette.Grey50} />}
          darkest={true}
        />
        <ListItem
          title={getText("WindSpeed")}
          description={Formatter.WindSpeedAndDirection(
            weather?.windSurface,
            weather?.WindDirectionSurface
          )}
          icon={
            <IoArrowUp
              style={{
                transform: `rotate(${
                  (weather?.WindDirectionSurface || 0) + 180
                }deg)`,
              }}
              size={24}
              color={theme.palette.Grey50}
            />
          }
          darkest={false}
        />
        <ListItem
          title={getText("WindSpeed100m")}
          description={Formatter.WindSpeedAndDirection(
            weather?.wind100m,
            weather?.WindDirection100m
          )}
          icon={
            <IoArrowUp
              style={{
                transform: `rotate(${
                  (weather?.WindDirection100m || 0) + 180
                }deg)`,
              }}
              size={24}
              color={theme.palette.Grey50}
            />
          }
          darkest={false}
        />
        <ListItem
          title={getText("Liquid")}
          description={Formatter.Liquid(weather?.liquid)}
          icon={<IoRainyOutline size={24} color={theme.palette.Grey50} />}
          darkest={true}
        />
        <ListItem
          title={getText("Pressure")}
          description={
            weather.pressure ? `${weather.pressure.toFixed(2)}mb` : "-"
          }
          icon={<IoEllipseOutline size={24} color={theme.palette.Grey50} />}
          darkest={false}
        />
        <ListItem
          title={getText("Humidity")}
          description={
            weather.humidity ? `${weather.humidity.toFixed(2)}%` : "-"
          }
          icon={<IoEllipseOutline size={24} color={theme.palette.Grey50} />}
          darkest={true}
        />
        <ListItem
          title={getText("Visibility")}
          description={
            weather.visibility ? `${weather.visibility.toFixed(2)} km` : "-"
          }
          icon={<IoEyeOutline size={24} color={theme.palette.Grey50} />}
          darkest={false}
        />
        <Text type="h5" size="large" weight="medium" Margin="10px 5px 0 20px">
          {getText("LocationData")}
        </Text>
        <ListItem
          title={getText("Start")}
          description={`${summary?.startLatitude}, ${summary?.startLongitude}`}
          icon={<IoLocationOutline size={24} color={theme.palette.Grey50} />}
          darkest={false}
        />
        <ListItem
          title={getText("End")}
          description={`${summary?.endLatitude}, ${summary?.endLongitude}`}
          icon={<IoLocationOutline size={24} color={theme.palette.Grey50} />}
          darkest={true}
        />
      </VBox>
    </HalfModal>
  );
};

export default FlightSummary;
