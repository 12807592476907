import { ReactNode } from "react";
export enum BarType {
  Info,
  Warn,
  Critical,
  Green,
}

interface BarOptions {
  type: BarType;
  children: string | ReactNode;
  title?: string;
  visible?: boolean;
}

export default BarOptions;
