import "reactflow/dist/style.css";

import { Button, ButtonType } from "../../styles/Button.styled";
import {
  EmptyNodeData,
  PigeonNodeAction,
  PigeonNodeData,
} from "../../components/Graph/NodeProps";
import { HBox, VBox } from "../../styles/Flexbox.styled";
import { IoChevronForward, IoInformationCircleOutline } from "react-icons/io5";
import { MouseEvent, useEffect, useRef, useState } from "react";
import ReactFlow, {
  Background,
  Controls,
  Node,
  NodeChange,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import {
  getPedigree,
  setFather,
  setMother,
} from "../../services/PigeonService";

import ChooseFather from "../../components/modals/ChoosePigeon/ChooseFather";
import ChooseMother from "../../components/modals/ChoosePigeon/ChooseMother";
import EmptyNode from "../../components/Graph/EmptyNode";
import GraphData from "../../models/GraphData";
import PedigreeInformaion from "../../components/PedigreeInformation";
import Pigeon from "../../models/Pigeon";
import PigeonNode from "../../components/Graph/PigeonNode";
import Text from "../../components/Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { getText } from "../../locales/initI18n";
import { useSearchParams } from "react-router-dom";
import { useTheme } from "styled-components";

const nodeTypes = {
  PigeonNode: PigeonNode,
  EmptyNode: EmptyNode,
};

interface DnaProps {
  pigeon: Pigeon;
}

const Dna = ({ pigeon }: DnaProps) => {
  const theme = useTheme();
  const reactFlow = useReactFlow();
  const graphData = useRef<GraphData>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const isInitlized = useRef(false);
  const [isChooseFatherVisible, setIsChooseFatherVisible] = useState(false);
  const [isChooseMotherVisible, setIsChooseMotherVisible] = useState(false);
  const [parentChooseFor, setParentChooseFor] = useState<Pigeon>(pigeon);
  const [maxLevel, setMaxLevel] = useState(3);
  const [showGenerateIncoming, setShowGenerateIncoming] = useState(true);
  const [showPedigreeInfo, setShowPedigreeInfo] = useState(false);

  const getGraphData = () => {
    if (!graphData.current) {
      graphData.current = new GraphData();
    }
    graphData.current.setActionCallback(onNodeAction);
    return graphData.current;
  };

  const onNodeAction = (type: PigeonNodeAction, pigeon: Pigeon) => {
    switch (type) {
      case PigeonNodeAction.Show:
        break;
      case PigeonNodeAction.Edit:
        break;
      case PigeonNodeAction.Remove:
        break;
    }
    void buildGraph();
  };

  const buildGraph = async () => {
    const pedigree = await getPedigree(pigeon.id, maxLevel);

    const graphData = getGraphData();
    graphData.init(pedigree, maxLevel);

    const { nodes, edges } = await graphData.build();

    setNodes(nodes);
    setEdges(edges);
  };

  const onNodeClick = (
    event: MouseEvent,
    node: Node<PigeonNodeData | EmptyNodeData>
  ) => {
    if (node.type === "EmptyNode") {
      const data = node.data as EmptyNodeData;
      setParentChooseFor(data.pigeon);
      if (data.isFather) {
        setIsChooseFatherVisible(true);
      } else {
        setIsChooseMotherVisible(true);
      }
    }
  };

  const onChangeNodes = (nodes: NodeChange[]) => {
    if (reactFlow.getNodes().length !== 0 && isInitlized.current === false) {
      isInitlized.current = true;
      reactFlow.fitView({ padding: 0.1 });
    }
  };

  const closeChoosePigeon = () => {
    setIsChooseFatherVisible(false);
    setIsChooseMotherVisible(false);
  };

  const setParent = async (parent: Pigeon) => {
    if (isChooseFatherVisible) {
      await setFather(parentChooseFor, parent);
      parentChooseFor.father = parent;
    } else {
      await setMother(parentChooseFor, parent);
      parentChooseFor.mother = parent;
    }
    closeChoosePigeon();
    void buildGraph();
  };

  useEffect(() => {
    setTimeout(() => {
      setShowGenerateIncoming(false);
    }, 3000);
    void buildGraph();
  }, [searchParams.get("breadcrumbs")]);

  return (
    <VBox height="100%" gap="10px" position="relative">
      <PedigreeInformaion
        isVisible={showPedigreeInfo}
        onConfirm={() => setShowPedigreeInfo(false)}
        onClose={() => setShowPedigreeInfo(false)}
      />
      <ChooseFather
        pigeon={pigeon}
        onClose={closeChoosePigeon}
        onConfirm={setParent}
        isVisible={isChooseFatherVisible}
      />
      <ChooseMother
        pigeon={pigeon}
        onClose={closeChoosePigeon}
        onConfirm={setParent}
        isVisible={isChooseMotherVisible}
      />
      <HBox
        position="absolute"
        Top="-7px"
        Right="-5px"
        zIndex={1}
        BorderRadius="20px"
        overflowX="hidden"
        overflowY="hidden"
      >
        <Button
          onClick={() => setShowPedigreeInfo(true)}
          padding="0 40px 0 10px"
          height="30px"
          isCustom={true}
          buttonType={ButtonType.Primary}
          style={{
            transform: !showGenerateIncoming
              ? "translateX(400px)"
              : "translateX(30px)",
          }}
        >
          <HBox alignItems="center">
            <Text
              size={TextSizeEnum.small}
              weight={TextWeightEnum.regular}
              type={TextTypeEnum.body}
              textWrap="nowrap"
              Margin="0 5px 0 0"
            >
              {getText("Dna.view.PedigreeInformation.KnowMore")}
            </Text>
            <IoChevronForward size={15} />
          </HBox>
        </Button>
        <Button
          onClick={() => setShowPedigreeInfo(true)}
          height="30px"
          isCustom={true}
          buttonType={ButtonType.Primary}
        >
          <HBox gap="5px">
            <IoInformationCircleOutline size={20} />
          </HBox>
        </Button>
      </HBox>
      <ReactFlow
        edges={edges}
        minZoom={0.1}
        maxZoom={1.5}
        zoomOnScroll
        nodes={nodes}
        onNodeClick={onNodeClick}
        nodeTypes={nodeTypes}
        style={{ height: "100%" }}
        onNodesChange={onChangeNodes}
        onPaneClick={() => {
          setNodes((nodes) => nodes.map((n) => ({ ...n, selected: false })));
        }}
      >
        <Background color={theme.palette.Grey50} gap={16} />
        <Controls />
      </ReactFlow>
    </VBox>
  );
};

export default Dna;
