import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import Achievements from "./Achievements";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import BreadcrumbItem from "../../components/Breadcrumb/IBreadcrumb";
import Children from "./Children";
import Dna from "./Dna";
import General from "./General";
import HorizontalMenu from "../../components/HorizontalMenu/HorizontalMenu";
import Parents from "./Parents";
import { PigeonDetailsTabs } from "../../models/enums/PigeonDetailsTabs";
import { ReactFlowProvider } from "reactflow";
import { VBox } from "../../styles/Flexbox.styled";
import { getPigeon } from "../../services/PigeonService";
import { getText } from "../../locales/initI18n";
import { setError } from "../../redux/modules/error";
import { useAppDispatch } from "../../redux/hooks";
import { useQuery } from "@tanstack/react-query";
import { useTheme } from "styled-components";
import withNavbar from "../../hoc/withNavbar/withNavbar";

const getHorizontalMenuItems = () => [
  {
    id: PigeonDetailsTabs.General,
    title: getText("PigeonDetail.menu.simpleInformation"),
  },
  {
    id: PigeonDetailsTabs.Parents,
    title: getText("PigeonDetail.menu.Parents"),
  },
  {
    id: PigeonDetailsTabs.Children,
    title: getText("PigeonDetail.menu.Children"),
  },
  {
    id: PigeonDetailsTabs.Pedigree,
    title: getText("PigeonDetail.menu.Pedigree"),
  },
  {
    id: PigeonDetailsTabs.Achievements,
    title: getText("PigeonDetail.menu.Achievements"),
  },
];

const PigeonDetail = () => {
  const theme = useTheme();
  const { id } = useParams<{ id: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState(PigeonDetailsTabs.General);
  const dispatch = useAppDispatch();

  const fnGetPigeon = () => {
    try {
      return getPigeon(Number(id));
    } catch (e) {
      dispatch(setError(e));
    }
  };

  const { data: pigeon } = useQuery({
    queryKey: [`pigeon`, id],
    queryFn: () => fnGetPigeon(),
  });

  const onChangeTab = (tab: PigeonDetailsTabs) => {
    setTab(tab);
    setSearchParams({
      tab: tab.toString(),
      breadcrumbs: searchParams.get("breadcrumbs") || "[]",
    });
  };

  useEffect(() => {
    const newTab = searchParams.get("tab");
    if (newTab) {
      const enumTab = newTab as unknown as PigeonDetailsTabs;
      if (
        Object.values(PigeonDetailsTabs).includes(Number(enumTab)) &&
        tab !== enumTab
      ) {
        setTab(Number(enumTab));
      }
    }
  }, [searchParams.get("tab")]);

  const setPrevPigeonsBreadcrumb = () => {
    try {
      const breadcrumbs = JSON.parse(
        searchParams.get("breadcrumbs") || "[]"
      ) as [string, string][];
      return breadcrumbs.map(
        (breadcrumb, index) =>
          new BreadcrumbItem(
            breadcrumb[1],
            `/dashboard/pigeons/${
              breadcrumb[0]
            }?breadcrumbs=${encodeURIComponent(
              JSON.stringify(breadcrumbs.slice(0, index))
            )}`
          )
      );
    } catch (e) {
      return [];
    }
  };

  if (!pigeon) return null;

  return (
    <VBox
      height="100%"
      Padding="20px 20px 0 20px"
      gap="10px"
      backgroundColor={theme.palette.Grey5}
    >
      <Breadcrumb
        items={[
          new BreadcrumbItem("Map", ".."),
          new BreadcrumbItem("Pigeons", "/dashboard/pigeons"),
          ...setPrevPigeonsBreadcrumb(),
        ]}
        current={new BreadcrumbItem(pigeon?.ring || "", "")}
      />
      <HorizontalMenu
        items={getHorizontalMenuItems()}
        active={tab}
        onClick={(tab) => onChangeTab(tab.id as PigeonDetailsTabs)}
      />
      <VBox height="80%" visible={tab === PigeonDetailsTabs.General}>
        {tab === PigeonDetailsTabs.General && <General pigeon={pigeon} />}
      </VBox>
      <VBox visible={tab === PigeonDetailsTabs.Parents} overflowY="scroll">
        {tab === PigeonDetailsTabs.Parents && <Parents pigeon={pigeon} />}
      </VBox>
      <VBox visible={tab === PigeonDetailsTabs.Achievements} overflowY="scroll">
        {tab === PigeonDetailsTabs.Achievements && (
          <Achievements pigeon={pigeon} />
        )}
      </VBox>
      <VBox visible={tab === PigeonDetailsTabs.Children} overflowY="scroll">
        {tab === PigeonDetailsTabs.Children && <Children pigeon={pigeon} />}
      </VBox>
      <VBox height="100%" visible={tab === PigeonDetailsTabs.Pedigree}>
        {tab === PigeonDetailsTabs.Pedigree && (
          <ReactFlowProvider>
            <Dna pigeon={pigeon} />
          </ReactFlowProvider>
        )}
      </VBox>
    </VBox>
  );
};

export default withNavbar(PigeonDetail);
