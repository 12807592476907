import "@maptiler/sdk/dist/maptiler-sdk.css";

import { Button, ButtonType } from "../styles/Button.styled";
import { FlightMarker, Marker } from "../models/Marker";
import { HBox, VBox } from "../styles/Flexbox.styled";
import {
  IoAppsOutline,
  IoArrowUp,
  IoRainyOutline,
  IoThermometerOutline,
} from "react-icons/io5";
import { useEffect, useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import DistrictChecker from "../components/modals/DistrictChecker/DistrictChecker";
import { Flight } from "../models/Flight";
import FlightHelper from "../utils/FlightHelper";
import FlightMarkerInformation from "../components/modals/FlightMarkerInformation/ FlightMarkerInformation";
import FlightSummary from "../components/modals/FlightSummary/FlightSummary";
import Layers from "../components/Layers/Layers";
import MapFlightBuilder from "../utils/MapFlightBuilder";
import { MapLayerEnum } from "../models/enums/MapLayerEnum";
import MapLayerLegend from "../components/MapLayerLegend/MapLayerLegend";
import { MapModal } from "../models/enums/MapModal";
import { MapOption } from "../components/modals/MapOptions/IMapOptions";
import MapOptions from "../components/modals/MapOptions/MapOptions";
import MarkerInformation from "../components/modals/MarkerInformation/MarkerInformation";
import { OnConfirmOptionsProps } from "../components/modals/MapOptions/IMapOptionsModals";
import Text from "../components/Text/Text";
import TimeSlider from "../components/TimeSlider";
import { getText } from "../locales/initI18n";
import { getWeather } from "../services/WeatherService";
import useCotes from "../hooks/useCotes";
import { useTheme } from "styled-components";
import withNavbar from "../hoc/withNavbar/withNavbar";

const PigeonMap = () => {
  const mapID = "PigeonMap__animation-map";
  const mapBuilder = useRef<MapFlightBuilder>();
  const theme = useTheme();
  const [mapModal, setMapModal] = useState<MapModal>(MapModal.NONE);
  const [visibleFlight, setVisibleFlight] = useState<Flight | null>(null);
  const [mapMarker, setMapMarker] = useState<Marker | null>(null);
  const [currentLayer, setCurrentLayer] = useState<MapLayerEnum>(
    MapLayerEnum.Wind
  );
  const [flightMarker, setFlightMarker] = useState<FlightMarker | null>(null);
  const mapTime = useRef<Date>(new Date());
  const queryClient = useQueryClient();
  const { data: cotes } = useCotes();

  useEffect(() => {
    mapBuilder.current = new MapFlightBuilder(
      mapID,
      onPressMap,
      getMarkerWeather
    );
  }, []);

  useEffect(() => {
    if (cotes?.data) {
      mapBuilder.current?.removeTags();
      mapBuilder.current?.addTags(cotes.data, onPressMap);
    }
  }, [cotes?.data]);

  const getMarkerWeather = async (marker: Marker) => {
    return queryClient.fetchQuery(
      [
        "weather",
        marker.latitude,
        marker.longitude,
        mapTime.current.toISOString(),
      ],
      () => getWeather(marker, mapTime.current)
    );
  };

  const onPressMap = (marker: Marker | null) => {
    setMapMarker(marker);
  };

  const onFlightMarker = (marker: FlightMarker) => {
    setFlightMarker(marker);
  };

  const onConfirmMapOptions = ({ data, mapOption }: OnConfirmOptionsProps) => {
    if (mapOption === MapOption.FLIGHT) {
      onConfirmShowFlight(data as Flight);
    }
  };

  const onConfirmShowFlight = (flight: Flight) => {
    if (!mapBuilder.current) return;
    setMapModal(MapModal.NONE);
    setVisibleFlight(flight);
    setMapMarker(null);
    mapBuilder.current.showFlight(flight, onFlightMarker);
  };

  const onCloseFlight = () => {
    if (!visibleFlight || !mapBuilder.current) return;
    mapTime.current = new Date();
    mapBuilder.current.unsetActiveMarker();
    mapBuilder.current.removeFlight(visibleFlight.id.toString());
    mapBuilder.current.showCurrentLayer();
    mapBuilder.current.setCurrentLayerTime();
    setVisibleFlight(null);
    onAfterChangeTime(mapTime.current);
  };

  const hideModal = () => {
    if (!mapBuilder.current) return;
    mapBuilder.current.unsetActiveMarker();
    setMapModal(MapModal.NONE);
    setMapMarker(null);
    setFlightMarker(null);
  };

  const changeLayer = (layer: MapLayerEnum) => {
    setCurrentLayer(layer);
    mapBuilder.current?.changeLayer(layer);
  };

  const onAfterChangeTime = (time: Date) => {
    mapTime.current = time;
    const seconds = time.getTime() / 1000;
    mapBuilder.current?.setLayerTime(seconds);
    mapBuilder.current?.refreshPopups();
  };

  const onChangeMapWeatherVisibility = (isVisible: boolean) => {
    if (isVisible) mapBuilder.current?.showPopups();
    else mapBuilder.current?.hidePopups();
  };

  const getShowWeatherOnMap = () => {
    return mapBuilder.current?.getShowWeatherPopups?.() || false;
  };
  return (
    <VBox position="relative" width="100%" height="100%" zIndex={1}>
      <DistrictChecker />
      <MapOptions
        isVisible={mapModal === MapModal.MAP_OPTIONS}
        onClose={hideModal}
        onConfirm={onConfirmMapOptions}
      />
      <FlightSummary
        isVisible={!!visibleFlight}
        onClose={onCloseFlight}
        flight={visibleFlight}
        onConfirm={() => {}}
        onAfterChangeTime={onAfterChangeTime}
        changeTime={(time: Date) => {}}
        isMapWeatherVisible={getShowWeatherOnMap()}
        onChangeMapWeatherVisibility={onChangeMapWeatherVisibility}
      />
      <MarkerInformation
        isVisible={!!mapMarker}
        marker={mapMarker}
        onClose={hideModal}
        onConfirm={() => {}}
        isMapWeatherVisible={getShowWeatherOnMap()}
        onChangeMapWeatherVisibility={onChangeMapWeatherVisibility}
        mapTime={mapTime.current}
      />
      <FlightMarkerInformation
        flight={visibleFlight}
        marker={flightMarker}
        isVisible={!!flightMarker}
        onClose={() => hideModal()}
        onConfirm={() => {}}
        isMapWeatherVisible={getShowWeatherOnMap()}
        onChangeMapWeatherVisibility={onChangeMapWeatherVisibility}
      />
      <div id={mapID} style={{ width: "100%", height: "100%" }} />
      <Layers
        initLayer={currentLayer}
        onChangeLayer={changeLayer}
        visible={FlightHelper.isWeatherLayer(visibleFlight)}
      />
      <MapLayerLegend
        layer={currentLayer}
        visible={FlightHelper.isWeatherLayer(visibleFlight)}
      />
      <VBox
        position="absolute"
        Top="10px"
        Right="0px"
        Padding="10px"
        gap="10px"
        backgroundColor={theme.palette.White}
        BorderRadius="20px 0 0 20px"
        shadow="small"
        visible={false}
      >
        <HBox
          Padding="2px"
          backgroundColor={theme.palette.White}
          BorderRadius="40px"
        >
          <Button
            isCustom
            width="40px"
            height="40px"
            onClick={() => changeLayer(MapLayerEnum.Temperature)}
            buttonType={
              currentLayer === MapLayerEnum.Temperature
                ? ButtonType.Primary
                : ButtonType.Ghost
            }
            padding="5px"
          >
            <IoThermometerOutline
              size={24}
              color={
                currentLayer === MapLayerEnum.Temperature
                  ? theme.palette.White
                  : theme.palette.Grey70
              }
            />
          </Button>
        </HBox>
        <HBox
          Padding="2px"
          backgroundColor={theme.palette.White}
          BorderRadius="40px"
        >
          <Button
            isCustom
            width="40px"
            height="40px"
            onClick={() => changeLayer(MapLayerEnum.Wind)}
            buttonType={
              currentLayer === MapLayerEnum.Wind
                ? ButtonType.Primary
                : ButtonType.Ghost
            }
            padding="5px"
          >
            <IoArrowUp
              size={20}
              color={
                currentLayer === MapLayerEnum.Wind
                  ? theme.palette.White
                  : theme.palette.Grey70
              }
            />
          </Button>
        </HBox>
        <HBox
          Padding="2px"
          backgroundColor={theme.palette.White}
          BorderRadius="40px"
        >
          <Button
            isCustom
            width="40px"
            height="40px"
            onClick={() => changeLayer(MapLayerEnum.Precipitation)}
            buttonType={
              currentLayer === MapLayerEnum.Precipitation
                ? ButtonType.Primary
                : ButtonType.Ghost
            }
            padding="5px"
          >
            <IoRainyOutline
              size={20}
              color={
                currentLayer === MapLayerEnum.Precipitation
                  ? theme.palette.White
                  : theme.palette.Grey70
              }
            />
          </Button>
        </HBox>
      </VBox>
      <HBox
        position="absolute"
        Bottom="60px"
        Right="10px"
        Padding="5px"
        backgroundColor={theme.palette.White}
        justifyContent="center"
        alignItems="center"
        BorderRadius="20px"
        visible={!visibleFlight}
      >
        <Button
          isCustom={true}
          buttonType={ButtonType.Primary}
          height="auto"
          padding="20px"
          onClick={() => setMapModal(MapModal.MAP_OPTIONS)}
        >
          <HBox alignItems="center" gap="10px">
            <IoAppsOutline size={30} color={theme.palette.White} />
            <Text
              type="body"
              size="large"
              weight="bold"
              color={theme.palette.White}
            >
              {getText("Map.Options")}
            </Text>
          </HBox>
        </Button>
      </HBox>
      <VBox
        position="absolute"
        Bottom="0px"
        Right="0px"
        backgroundColor={theme.palette.White}
        justifyContent="center"
        width="100%"
        Padding="5px"
        zIndex={2}
      >
        <TimeSlider
          onAfterChange={onAfterChangeTime}
          time={mapTime.current}
          changeTime={() => {}}
        />
      </VBox>
    </VBox>
  );
};

export default withNavbar(PigeonMap);
