import { Button, ButtonType } from "../../../styles/Button.styled";
import { Field, Form } from "houseform";
import { HBox, VBox } from "../../../styles/Flexbox.styled";

import ChildFilter from "../../../models/Filter/ChildFilter";
import { CloseIconEnum } from "../../HalfModal/CloseIconEnum";
import FilterJSX from "../../Filter/Filter";
import { FiltersProps } from "./IFilters";
import HalfModal from "../../HalfModal/HalfModal";
import { IBaseFilter } from "../../../models/Filter/IBaseFilter";
import IChildFilter from "../../../models/Filter/IChildFilter";
import ParentFilter from "../../../models/Filter/ParentFilter";
import Text from "../../Text/Text";
import TextSizeEnum from "../../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../../models/enums/TextWeightEnum";
import { getText } from "../../../locales/initI18n";

const Filters = (props: FiltersProps) => {
  const createFilter = (filter: IBaseFilter) => {
    if (ParentFilter.is(filter)) {
      return filter.getChilds().map((child) => {
        return (
          <Field<IChildFilter> name={child.Name} initialValue={child}>
            {({ value, setValue }) => (
              <FilterJSX
                filter={value}
                onFilterChange={(filter: IChildFilter) => {
                  setValue(filter);
                }}
              />
            )}
          </Field>
        );
      });
    }
    if (ChildFilter.is(filter)) {
      return (
        <Field<IChildFilter> name={filter.Name} initialValue={filter}>
          {({ value, setValue }) => (
            <FilterJSX
              filter={value}
              onFilterChange={(filter: IChildFilter) => {
                setValue(filter);
              }}
            />
          )}
        </Field>
      );
    }
    return null;
  };

  const onSubmit = (values: { [k: string]: IBaseFilter }) => {
    props.onConfirm(Object.values(values));
  };

  return (
    <HalfModal
      visible={props.isVisible}
      onClose={props.onClose}
      heightDivider={1.1}
      isExpander={false}
      closeIcon={CloseIconEnum.WITHOUT_BUTTON}
    >
      <Text
        type={TextTypeEnum.h4}
        size={TextSizeEnum.large}
        weight={TextWeightEnum.bold}
        Margin="0 0 20px 0"
      >
        {props.title}
      </Text>
      <Form onSubmit={onSubmit}>
        {({ submit, value }) => (
          <VBox gap="10px">
            {props.filters.map((filter) => {
              return <HBox key={filter.Name}>{createFilter(filter)}</HBox>;
            })}
            <Button
              onClick={submit}
              margin="10px 0 0 0"
              buttonType={ButtonType.Success}
            >
              {getText("Filters.Search")}
            </Button>
          </VBox>
        )}
      </Form>
    </HalfModal>
  );
};

export default Filters;
