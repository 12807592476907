import { useTheme } from "styled-components";
import TextSizeEnum from "../models/enums/TextSizeEnum";
import TextTypeEnum from "../models/enums/TextTypeEnum";
import TextWeightEnum from "../models/enums/TextWeightEnum";
import { VBox } from "../styles/Flexbox.styled";
import { getText } from "../locales/initI18n";
import Text from "./Text/Text";

interface ShowMoreProps {
  onClick?: () => void;
  isMore?: boolean;
}

const ShowMore = ({ onClick = () => {}, isMore = false }: ShowMoreProps) => {
  const theme = useTheme();
  return (
    <VBox
      display="inline-block"
      onClick={onClick}
      backgroundColor={theme.palette.Grey30}
      BorderRadius="20px"
      Padding="0 8px"
    >
      <Text
        type={TextTypeEnum.body}
        size={TextSizeEnum.small}
        weight={TextWeightEnum.regular}
        color={theme.palette.White}
      >
        {isMore ? getText("TextInput.ShowLess") : getText("TextInput.ShowMore")}
      </Text>
    </VBox>
  );
};

export default ShowMore;
