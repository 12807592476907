import { PigeonColorEnum } from "../../../models/enums/PigeonColorEnum";
import SelectOption from "../../../models/SelectOption";

const ColorOptions = [
  new SelectOption({
    key: PigeonColorEnum.White,
    title: "Barwa",
    description: "Biały",
  }),
  new SelectOption({
    key: PigeonColorEnum.Black,
    title: "Barwa",
    description: "Czarny",
  }),
  new SelectOption({
    key: PigeonColorEnum.Blue,
    title: "Barwa",
    description: "Niebieski",
  }),
  new SelectOption({
    key: PigeonColorEnum.Dark,
    title: "Barwa",
    description: "Ciemny",
  }),
  new SelectOption({
    key: PigeonColorEnum.Fawn,
    title: "Barwa",
    description: "Płowy",
  }),
  new SelectOption({
    key: PigeonColorEnum.Red,
    title: "Barwa",
    description: "Czerwony",
  }),
  new SelectOption({
    key: PigeonColorEnum.Grey,
    title: "Barwa",
    description: "Szpakowaty",
  }),
  new SelectOption({
    key: PigeonColorEnum.FawnRed,
    title: "Barwa",
    description: "Płowy czerwony",
  }),
  new SelectOption({
    key: PigeonColorEnum.FawnBlack,
    title: "Barwa",
    description: "Płowy czarny",
  }),
  new SelectOption({
    key: PigeonColorEnum.SpottedGrey,
    title: "Barwa",
    description: "Szpakowaty nakrapiany",
  }),
  new SelectOption({
    key: PigeonColorEnum.SpottedBlue,
    title: "Barwa",
    description: "Niebieski nakrapiany",
  }),
  new SelectOption({
    key: PigeonColorEnum.SpottedRed,
    title: "Barwa",
    description: "Czerwony nakrapiany",
  }),
  new SelectOption({
    key: PigeonColorEnum.SpottedDark,
    title: "Barwa",
    description: "Ciemny nakrapiany",
  }),
  new SelectOption({
    key: PigeonColorEnum.VaricolouredBlue,
    title: "Barwa",
    description: "Niebieski pstrokaty",
  }),
  new SelectOption({
    key: PigeonColorEnum.VaricolouredDark,
    title: "Barwa",
    description: "Ciemny pstrokaty",
  }),
  new SelectOption({
    key: PigeonColorEnum.VaricolouredBlack,
    title: "Barwa",
    description: "Czarny pstrokaty",
  }),
  new SelectOption({
    key: PigeonColorEnum.VaricolouredRed,
    title: "Barwa",
    description: "Czerwony pstrokaty",
  }),
  new SelectOption({
    key: PigeonColorEnum.VaricolouredFawn,
    title: "Barwa",
    description: "Płowy pstrokaty",
  }),
  new SelectOption({
    key: PigeonColorEnum.VaricolouredGrey,
    title: "Barwa",
    description: "Szpakowaty pstrokaty",
  }),
  new SelectOption({
    key: PigeonColorEnum.VaricolouredFawnRed,
    title: "Barwa",
    description: "Płowy czerwony pstrokaty",
  }),
  new SelectOption({
    key: PigeonColorEnum.VaricolouredFawnBlack,
    title: "Barwa",
    description: "Płowy czarny pstrokaty",
  }),
  new SelectOption({
    key: PigeonColorEnum.SpottedVaricolouredBlue,
    title: "Barwa",
    description: "Niebieski pstrokaty nakrapiany",
  }),
  new SelectOption({
    key: PigeonColorEnum.SpottedVaricolouredDark,
    title: "Barwa",
    description: "Ciemny pstrokaty nakrapiany",
  }),
  new SelectOption({
    key: PigeonColorEnum.SpottedVaricolouredBlack,
    title: "Barwa",
    description: "Czarny pstrokaty nakrapiany",
  }),
  new SelectOption({
    key: PigeonColorEnum.SpottedVaricolouredRed,
    title: "Barwa",
    description: "Czerwony pstrokaty nakrapiany",
  }),
  new SelectOption({
    key: PigeonColorEnum.SpottedVaricolouredFawn,
    title: "Barwa",
    description: "Płowy pstrokaty nakrapiany",
  }),
];

export const getInitialColor = (current?: PigeonColorEnum) => {
  const option = ColorOptions.find((option) => option.key === current);
  if (option) return option;
  return ColorOptions[0];
};

export default ColorOptions;
