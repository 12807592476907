import { authGet, authPatch, authPost, get, post } from "./BaseService";

import Opinion from "../models/Opinion";

export default class OpinionService {
  static GENERAL_OPINION = "General";
  static PEDIGREE_OPINION = "Pedigree";
  static FLIGHTS_OPINION = "Flights";
  static async get() {
    const result = await authGet<object[]>("opinion");
    return result.data.map((opinion) => new Opinion().fillData(opinion));
  }

  static async getByName(name: string) {
    const result = await authGet<object>(`opinion/name/${name}`);
    if (!result.data) return null;
    return new Opinion().fillData(result.data);
  }

  static async getById(id: number) {
    const result = await authGet<object>(`opinion/${id}`);
    if (!result.data) return null;
    return new Opinion().fillData(result.data);
  }

  static async add(opinion: Opinion) {
    return authPost<object>("opinion", opinion);
  }
}
