export enum FilterRule {
  EQUALS = "==",
  NOT_EQUALS = "!=",
  GREATER_THAN = ">",
  GREATER_THAN_OR_EQUALS = ">=",
  LESS_THAN = "<",
  LESS_THAN_OR_EQUALS = "<=",
  LIKE = "like",
  NOT_LIKE = "nlike",
  IN = "in",
  NOT_IN = "nin",
  IS_NULL = "isnull",
  IS_NOT_NULL = "isnotnull",
}
