import { AddFlightFormModalEnum } from "./AddFlightFormModalEnum";
import { AddFlightFormModalProps } from "./IAddFlightForm";
import Confirm from "../Confirm/Confirm";
import ConfirmModalTypeEnum from "../../../models/enums/ConfirmModalTypeEnum";
import Farmer from "../Farmer/Farmer";
import FlightFinish from "../FlightFinish/FlightFinish";
import { FlightSharePermisionEnum } from "../../../models/enums/FlightSharePermisionEnum";
import Friends from "../Friends/Friends";
import { IFarmer } from "../../../services/IFollowService";
import ReleaseList from "../ReleaseList/ReleaseList";
import { getText } from "../../../locales/initI18n";

const AddFlightFormModal = ({
  flight,
  relatedFlight,
  modal,
  onClose,
  onConfirm,
}: AddFlightFormModalProps) => {
  const getPrivateEventFarmers = () => {
    if (!flight?.relatedFlights) return [];
    return flight?.relatedFlights.map((f) => f.user.id);
  };

  const getPlannerShareFarmers = () => {
    return (
      flight?.sharing?.reduce((acc, curr) => {
        if (curr.permision === FlightSharePermisionEnum.Edit) {
          return [...acc, curr.userId];
        }
        return acc;
      }, [] as number[]) || []
    );
  };

  const getSelectShareFarmers = () => {
    return (
      flight?.sharing?.reduce((acc, curr) => {
        if (curr.permision === FlightSharePermisionEnum.Read) {
          return [...acc, curr.userId];
        }
        return acc;
      }, [] as number[]) || []
    );
  };

  const getSharedFarmers = () => {
    if (!flight) return [];
    if (modal === AddFlightFormModalEnum.PRIVATE_EVENT_SHARE)
      return getPrivateEventFarmers();
    if (modal === AddFlightFormModalEnum.PLANNER_SHARE)
      return getPlannerShareFarmers();
    if (modal === AddFlightFormModalEnum.SELECT_SHARE)
      return getSelectShareFarmers();
    return [];
  };

  if (modal === AddFlightFormModalEnum.NONE) return null;
  if (
    [
      AddFlightFormModalEnum.PRIVATE_EVENT_SHARE,
      AddFlightFormModalEnum.SELECT_SHARE,
      AddFlightFormModalEnum.PLANNER_SHARE,
    ].includes(modal)
  ) {
    const selectedModal = modal as
      | AddFlightFormModalEnum.PRIVATE_EVENT_SHARE
      | AddFlightFormModalEnum.SELECT_SHARE
      | AddFlightFormModalEnum.PLANNER_SHARE;
    return (
      <Friends
        isVisible={true}
        onConfirm={(data) => onConfirm({ modal: selectedModal, data })}
        onClose={onClose}
        shared={getSharedFarmers()}
        onlyPlanners={modal === AddFlightFormModalEnum.PLANNER_SHARE}
      />
    );
  }
  if (
    modal === AddFlightFormModalEnum.COTES ||
    modal === AddFlightFormModalEnum.TAGS
  ) {
    return (
      <ReleaseList
        isSelect
        isVisible
        isEditPossible={false}
        isCote={modal === AddFlightFormModalEnum.COTES}
        onClose={onClose}
        onConfirm={(data) => onConfirm({ modal, data })}
      />
    );
  }
  if (modal === AddFlightFormModalEnum.RELATED_FLIGHTS) {
    return (
      <Farmer
        isVisible
        farmer={relatedFlight?.user as IFarmer}
        flight={relatedFlight}
        onConfirm={() => {}}
        onClose={onClose}
      />
    );
  }
  if (modal === AddFlightFormModalEnum.CHANGE_CONFIRM) {
    return (
      <Confirm
        isVisible
        onClose={onClose}
        onConfirm={() => onConfirm({ modal, data: null })}
        title={getText("AddFlightForm.confirmTitleChange")}
        description={getText("AddFlightForm.confirmDescChange")}
        type={ConfirmModalTypeEnum.Change}
        confirmText={getText("AddFlightForm.confirmConfirmBtnChange")}
        cancelText={getText("AddFlightForm.confirmCancelBtnChange")}
      />
    );
  }
  if (modal === AddFlightFormModalEnum.DELETE_CONFIRM) {
    return (
      <Confirm
        isVisible
        onClose={onClose}
        onConfirm={() => onConfirm({ modal, data: null })}
        title={getText("AddFlightForm.confirmTitleDelete")}
        description={getText("AddFlightForm.confirmDescDelete")}
        type={ConfirmModalTypeEnum.Delete}
        confirmText={getText("AddFlightForm.confirmConfirmBtnDelete")}
        cancelText={getText("AddFlightForm.confirmCancelBtnDelete")}
      />
    );
  }
  if (modal === AddFlightFormModalEnum.REJECT_SHARE) {
    return (
      <Confirm
        isVisible
        onClose={onClose}
        onConfirm={() => onConfirm({ modal, data: null })}
        title="Rezygnacja z udostępnienie"
        description="Czy na pewno chcesz zrezygnować z udostępnionego lotu?"
        type={ConfirmModalTypeEnum.Delete}
        confirmText="Rezygnuje"
        cancelText="Wracam do lotu"
      />
    );
  }
  if (modal === AddFlightFormModalEnum.SUBMIT_CONFIRM) {
    return (
      <Confirm
        isVisible
        onClose={onClose}
        onCancel={() => onConfirm({ modal, data: false })}
        onConfirm={() => onConfirm({ modal, data: true })}
        title="Dodawanie lotu"
        description="Czy chcesz dodać lot czy wyjść bez zapisywania?"
        type={ConfirmModalTypeEnum.Change}
        confirmText="Dodaj lot"
        cancelText="Wyjdź bez zapisywania"
      />
    );
  }
  if (modal === AddFlightFormModalEnum.FINISH_FLIGHT) {
    return (
      <FlightFinish
        isVisible
        title={flight?.name || ""}
        endDate={flight?.endDate || ""}
        onClose={onClose}
        onConfirm={(endDate) =>
          onConfirm({ modal, data: endDate.toISOString() })
        }
      />
    );
  }
  return null;
};

export default AddFlightFormModal;
