import { ButtonOpacity } from "../../styles/ButtonOpacity.styled";
import { ITagButton } from "./ITagButton";
import Text from "../Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { VBox } from "../../styles/Flexbox.styled";
import { memo } from "react";
import { useTheme } from "styled-components";

const TagButton = (props: ITagButton) => {
  const theme = useTheme();

  const getBackgroundColor = () => {
    if (props.error) return theme.palette.Red10;
    if (props.value) return theme.palette.Grey10;
    if (props.disabled) return theme.palette.Grey1;
    if (props.disabled || !props.value) return theme.palette.Grey5;
    return theme.palette.Grey10;
  };

  const getTextColor = () => {
    if (props.error) return theme.palette.Red80;
    if (props.value) return theme.palette.Grey60;
    if (props.disabled) return theme.palette.Grey30;
    if (props.disabled || !props.value) return theme.palette.Grey40;
    return theme.palette.Grey60;
  };

  const getTitleColor = () => {
    if (props.disabled) return theme.palette.Grey60;
    return theme.palette.Grey80;
  };

  const getText = () => {
    if (props.error) return props.error;
    if (props.value) return props.value;
    return props.placeholder;
  };
  return (
    <ButtonOpacity onClick={!props.disabled ? props.onClick : undefined}>
      <VBox
        gap="5px"
        Padding="10px"
        BorderRadius="10px"
        backgroundColor={getBackgroundColor()}
      >
        <Text
          type={TextTypeEnum.body}
          size={TextSizeEnum.large}
          weight={TextWeightEnum.regular}
          color={getTitleColor()}
          textAlign="left"
        >
          {props.title}
        </Text>
        <Text
          type={TextTypeEnum.body}
          size={TextSizeEnum.medium}
          weight={TextWeightEnum.regular}
          color={getTextColor()}
          textAlign="left"
        >
          {getText()}
        </Text>
      </VBox>
    </ButtonOpacity>
  );
};

export default memo(TagButton);
