import { Body } from "./Typography.styled";
import { HBox } from "./Flexbox.styled";
import { devices } from "./Devices";
import styled from "styled-components";

const Bar = styled(HBox)`
  padding: 10px;
  flex-direction: column;
  ${Body("regular", "medium")}
  border-radius: 10px;
  @media ${devices.sm} {
    flex-direction: row;
    padding: 10px 20px;
    gap: 10px;
  }
`;

export const InfoBarStyled = styled(Bar)`
  background-color: ${({ theme }) => theme.palette.Primary10};
  color: ${({ theme }) => theme.palette.Grey70};
  & span::before {
    color: ${({ theme }) => theme.palette.Primary100};
    font-size: 30px;
  }
`;

export const WarnBarStyled = styled(Bar)`
  background-color: ${({ theme }) => theme.palette.Yellow20};
  color: ${({ theme }) => theme.palette.Grey70};
  & span::before {
    color: ${({ theme }) => theme.palette.Yellow100};
    font-size: 30px;
  }
`;

export const CriticalBarStyled = styled(Bar)`
  background-color: ${({ theme }) => theme.palette.Red20};
  color: ${({ theme }) => theme.palette.Grey70};
  & span::before {
    color: ${({ theme }) => theme.palette.Red100};
    font-size: 30px;
  }
`;

export const GreenBarStyled = styled(Bar)`
  background-color: ${({ theme }) => theme.palette.Green20};
  color: ${({ theme }) => theme.palette.Grey70};
  & span::before {
    color: ${({ theme }) => theme.palette.Green100};
    font-size: 30px;
  }
`;
