import { ModalProps } from "../IModalOptions";
import { OnConfirmOptionsProps } from "./IMapOptionsModals";

export enum MapOption {
  NONE,
  FLIGHT,
  RELEASE,
  COTE,
}

export interface MapProps extends ModalProps<OnConfirmOptionsProps> {}
