import {
  AddFlightConfirmType,
  AddFlightFormProps,
  AddFlightSubmitProps,
  FlightTypeOptions,
  OnConfirmAddFlightFormModalProps,
} from "./IAddFlightForm";
import { CreateFlight, Flight, JoinFlight } from "../../../models/Flight";
import { FC, useEffect, useRef, useState } from "react";
import { Field, FieldInstance, Form, FormInstance } from "houseform";
import { HBox, VBox } from "../../../styles/Flexbox.styled";
import {
  IoAddCircleOutline,
  IoDocumentOutline,
  IoEyeOffOutline,
  IoMapOutline,
  IoPencilOutline,
  IoPeopleOutline,
  IoPersonAddOutline,
  IoShareSocialOutline,
  IoShuffleOutline,
  IoTimeOutline,
  IoTrashOutline,
} from "react-icons/io5";
import {
  addPlanners,
  addToPrivateFlight,
  finishFlight,
  getFlight,
  rejectShareFlight,
  shareFlight,
} from "../../../services/FlightService";
import { formatDate, formatDateTime } from "../../../utils/VariableHelper";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  validateDateRequired,
  validateObjectRequired,
  validateRequired,
} from "../../../utils/ValidationHelper";

import AddFlightFormModal from "./AddFlightFormModal";
import { AddFlightFormModalEnum } from "./AddFlightFormModalEnum";
import DateInput from "../../DateInput/DateInput";
import FlightHelper from "../../../utils/FlightHelper";
import FlightHighlightedInfo from "../../FlightHighlightedInfo";
import { FlightType } from "../../../models/enums/FlightType";
import HalfModal from "../../HalfModal/HalfModal";
import { ITag } from "../../../models/Tag";
import ListItem from "../../ListItem/ListItem";
import { ListItemAction } from "../../ListItem/IListItem";
import PDFFlight from "../../PDFFlight/PDFFlight";
import Select from "react-select";
import TagButton from "../../TagButton/TagButton";
import Text from "../../Text/Text";
import TextInput from "../../TextInput";
import { TextInputType } from "../../../styles/ITextInput";
import TextSizeEnum from "../../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../../models/enums/TextWeightEnum";
import { UserType } from "../../../models/enums/UserType";
import { getText } from "../../../locales/initI18n";
import { useTheme } from "styled-components";
import useUser from "../../../hooks/useUser";

const getFlightTypeOptions = (): FlightTypeOptions[] => {
  return [
    { value: FlightType.Single, label: "Lot prywatny" },
    { value: FlightType.Open, label: "Lot konkursowy" },
  ];
};

const AddFlightForm: FC<AddFlightFormProps> = ({
  flightId,
  isVisible,
  closeIcon,
  onClose,
  onConfirm,
  onDelete,
  onShow,
}) => {
  const theme = useTheme();
  const form = useRef<FormInstance<AddFlightSubmitProps>>(null);
  const flightFormData = useRef<CreateFlight>();
  const [modal, setModal] = useState<AddFlightFormModalEnum>(
    AddFlightFormModalEnum.NONE
  );
  const [release, setRelease] = useState<ITag | null>(null);
  const [cote, setCote] = useState<ITag | null>(null);
  const { data: user } = useUser();
  const { data: flight, refetch } = useQuery<Flight | null>({
    queryKey: ["flight", flightId],
    queryFn: () => getFlight(flightId || 0),
  });
  const [flightType, setFlightType] = useState(getFlightTypeOptions()[0]);
  const [relatedFlight, setRelatedFlight] = useState<Flight | null>(null);
  const queryClient = useQueryClient();

  const onSubmit = (data: AddFlightSubmitProps) => {
    const isNotOwnEvent =
      !FlightHelper.isCurrentUserEvent(flight, user?.id) &&
      FlightHelper.isEvent(flight);
    const isEditPerm = FlightHelper.isEventEditPermission(flight, user?.id);
    if (isNotOwnEvent && !isEditPerm && !!flight) {
      joinToFlight({
        flightId: flight?.id,
        end: data.end,
      });
      return;
    }
    const type = flightType.value;
    flightFormData.current = flight
      ? { ...flight, ...data, type }
      : { ...data, type };
    if (!flight?.id) return submitFlight();
    setModal(AddFlightFormModalEnum.CHANGE_CONFIRM);
  };

  const submitFlight = () => {
    if (flightFormData.current)
      onConfirm({
        type: AddFlightConfirmType.Create,
        flight: flightFormData.current,
      });
  };

  const joinToFlight = (joinFlight: JoinFlight) => {
    onConfirm({ type: AddFlightConfirmType.Join, flight: joinFlight });
  };

  const injectRelatedFlightBox = () => {
    if (!flight) return null;
    if (!!flight.parentFlightId) {
      return createRelatedFlightsBox(flight.parentFlight?.relatedFlights);
    }
    if (!!flight.relatedFlights) {
      return createRelatedFlightsBox(flight.relatedFlights);
    }
    return null;
  };

  const createRelatedFlightsBox = (relatedFlights?: Flight[] | null) => {
    if (!relatedFlights) return null;
    const ownFlightIndex = relatedFlights.findIndex(
      (child) => child.id === flight?.id
    );
    if (relatedFlights.length === 0) {
      return <ListItem darkest title="" description="Brak uczestników" />;
    }
    return relatedFlights.map((child, index) => {
      return (
        <ListItem
          key={child.id}
          darkest={index % 2 === 0}
          title={child.user.username}
          description={
            child?.endDate
              ? `End date: ${formatDateTime(child.endDate)}`
              : "Brak daty zakończenia"
          }
          rDescription={formatDate(child.createDate)}
          onClick={() => void goToFarmer(child)}
        />
      );
    });
  };

  const goToFarmer = async (flight: Flight) => {
    setModal(AddFlightFormModalEnum.RELATED_FLIGHTS);
    setRelatedFlight(flight);
  };

  const onRejectShare = async () => {
    if (!flight || !user) return;
    await rejectShareFlight(flight?.id, user?.id);
    queryClient.setQueryData(["flights", { filters: "" }], (old?: Flight[]) => {
      return old?.filter((f) => f.id !== flight.id);
    });
    onClose();
  };

  const isPlanner = () => user?.type === UserType.Planner;

  const onConfirmModals = async (props: OnConfirmAddFlightFormModalProps) => {
    switch (props.modal) {
      case AddFlightFormModalEnum.PRIVATE_EVENT_SHARE: {
        const userIds = props.data.map((farmer) => farmer.id);
        await addToPrivateFlight(flight?.id || 0, userIds);
        refetch();
        break;
      }
      case AddFlightFormModalEnum.SELECT_SHARE: {
        const userIds = props.data.map((farmer) => farmer.id);
        await shareFlight(flight?.id || 0, userIds);
        refetch();
        break;
      }
      case AddFlightFormModalEnum.PLANNER_SHARE: {
        const userIds = props.data.map((farmer) => farmer.id);
        await addPlanners(flight?.id || 0, userIds);
        refetch();
        break;
      }
      case AddFlightFormModalEnum.CHANGE_CONFIRM: {
        submitFlight();
        break;
      }
      case AddFlightFormModalEnum.DELETE_CONFIRM: {
        if (flight) onDelete(flight);
        break;
      }
      case AddFlightFormModalEnum.SUBMIT_CONFIRM: {
        if (props.data) form.current?.submit();
        if (props.data === false) onClose(false);
        break;
      }
      case AddFlightFormModalEnum.REJECT_SHARE: {
        await onRejectShare();
        break;
      }
      case AddFlightFormModalEnum.COTES: {
        setCote(props.data);
        break;
      }
      case AddFlightFormModalEnum.TAGS: {
        onSetRelease(props.data);
        break;
      }
      case AddFlightFormModalEnum.FINISH_FLIGHT: {
        await finishFlight(flight?.id || 0, props.data);
        refetch();
        break;
      }
    }
    setModal(AddFlightFormModalEnum.NONE);
  };

  const onSetRelease = (tag: ITag) => {
    setRelease(tag);
    if (!form.current) return;
    const nameField = form.current.getFieldValue("name") as FieldInstance<
      string,
      AddFlightSubmitProps
    >;
    if (!nameField) return;
    const date = new Date();
    nameField.setValue(`${tag.name} | ${formatDate(date)}`);
    form.current.setIsTouched(true);
  };

  useEffect(() => {
    if (!flight) return;
    const FlightType = getFlightTypeOptions().find(
      (option) => option.value === flight.type
    );
    if (FlightType) setFlightType(FlightType);
    form.current?.reset();
  }, [flight]);

  const close = () => {
    if (form.current?.isTouched && !flight)
      setModal(AddFlightFormModalEnum.SUBMIT_CONFIRM);
    else onClose(false);
  };

  const getDistrictName = () => {
    return flight?.parentFlightId
      ? flight.parentFlight?.user.district?.name
      : flight?.user.district?.name;
  };

  const getBranchName = () => {
    return flight?.parentFlightId
      ? flight.parentFlight?.user.branch?.name
      : flight?.user.branch?.name;
  };

  const isValidateCote = () => {
    return FlightHelper.isCurrentUserEvent(flight, user?.id) ||
      FlightHelper.isEventEditPermission(flight, user?.id) ||
      (!flight && flightType.value === FlightType.Open)
      ? undefined
      : validateObjectRequired();
  };

  return (
    <HalfModal
      visible={isVisible}
      onClose={close}
      heightDivider={1.1}
      Padding="0 5px 0 5px"
      isExpander={false}
      closeIcon={closeIcon}
    >
      <AddFlightFormModal
        flight={flight || undefined}
        relatedFlight={relatedFlight || undefined}
        modal={modal}
        onClose={() => setModal(AddFlightFormModalEnum.NONE)}
        onConfirm={onConfirmModals}
      />
      <VBox Margin="40px 20px 10px 20px">
        <Text
          type={TextTypeEnum.h4}
          size={TextSizeEnum.large}
          weight={TextWeightEnum.bold}
        >
          {!!flight ? flight.name : getText("AddFlightForm.Title")}
        </Text>
        <FlightHighlightedInfo flight={flight} currentUser={user} />
      </VBox>
      <HBox
        Padding="0px 20px 10px 20px"
        width="100%"
        gap="10px"
        zIndex={1}
        visible={isPlanner()}
      >
        <Select
          styles={{
            container: (base) => ({ ...base, width: "100%" }),
          }}
          options={getFlightTypeOptions()}
          value={flightType}
          onChange={(newValue) => setFlightType(newValue as FlightTypeOptions)}
          isDisabled={FlightHelper.isFlight(flight)}
        />
      </HBox>
      <Form onSubmit={onSubmit} ref={form}>
        {({ submit, value: allValue, getFieldValue }) => (
          <>
            <VBox Padding="0px 20px 0 20px" gap="10px">
              <Field<ITag>
                name="start"
                initialValue={flight?.start}
                onSubmitValidate={validateObjectRequired()}
                onChangeValidate={validateObjectRequired()}
              >
                {({ value, setValue, errors }) => {
                  if (release !== null) {
                    setValue(release);
                    setRelease(null);
                  }
                  return (
                    <HBox width="100%" gap="10px">
                      <TagButton
                        title={getText("AddFlightForm.PigeonRelease")}
                        placeholder={getText(
                          "AddFlightForm.PigeonReleasePlaceholder"
                        )}
                        value={value.name}
                        error={errors[0]}
                        onClick={() => {
                          setModal(AddFlightFormModalEnum.TAGS);
                        }}
                        disabled={
                          !FlightHelper.isEditable(flight, user?.id) ||
                          FlightHelper.isEventChild(flight)
                        }
                      />
                    </HBox>
                  );
                }}
              </Field>
              <Field<ITag>
                name="end"
                initialValue={
                  FlightHelper.isDovecoteInitalValue(flight, user?.id)
                    ? flight?.end
                    : undefined
                }
                onSubmitValidate={isValidateCote()}
                onChangeValidate={isValidateCote()}
              >
                {({ value, setValue, errors }) => {
                  if (cote !== null) {
                    setValue(cote);
                    setCote(null);
                  }
                  return (
                    <HBox width="100%" gap="10px">
                      <TagButton
                        disabled={FlightHelper.isDisabledDovecote(
                          flight,
                          user?.id
                        )}
                        title={getText("AddFlightForm.Cote")}
                        placeholder={getText("AddFlightForm.CotePlaceholder")}
                        value={value.name}
                        error={errors[0]}
                        onClick={() => {
                          setModal(AddFlightFormModalEnum.COTES);
                        }}
                      />
                    </HBox>
                  );
                }}
              </Field>
              <Field<Date>
                name="startDate"
                initialValue={
                  flight?.startDate ? new Date(flight.startDate) : new Date()
                }
                onSubmitValidate={validateDateRequired()}
              >
                {({ value, setValue, onBlur, isValid, errors }) => (
                  <HBox width="100%" gap="10px">
                    <DateInput
                      isErrorState={!isValid}
                      value={value}
                      onBlur={onBlur}
                      onChange={(e: Date) => {
                        const nameField = getFieldValue(
                          "name"
                        ) as FieldInstance<string, AddFlightSubmitProps>;
                        const nameValue = nameField.value.split(" | ");
                        nameField.setValue(
                          `${nameValue[0]} | ${formatDate(e)}`
                        );
                        setValue(e);
                      }}
                      placeholder={getText("StartDatePlacehloder")}
                      errors={errors}
                      disabled={FlightHelper.isStartDateDisabled(
                        flight,
                        user?.id
                      )}
                    >
                      {getText("StartDate")}
                    </DateInput>
                  </HBox>
                )}
              </Field>
              <Field<string>
                name="name"
                initialValue={flight?.name}
                onSubmitValidate={validateRequired()}
              >
                {({ value, setValue, onBlur, isValid, errors }) => (
                  <HBox width="100%" gap="10px">
                    <TextInput
                      isErrorState={!isValid}
                      value={value}
                      onBlur={onBlur}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setValue(e.target.value)
                      }
                      placeholder={getText("FlightNamePlaceholder")}
                      errors={errors}
                      disabled={
                        !FlightHelper.isEditable(flight, user?.id) ||
                        FlightHelper.isEventChild(flight)
                      }
                    >
                      {getText("FlightName")}
                    </TextInput>
                  </HBox>
                )}
              </Field>
              <Field<string> name="remarks" initialValue={flight?.remarks}>
                {({ value, setValue, onBlur, isValid, errors }) => (
                  <HBox width="100%" gap="10px">
                    <TextInput
                      isErrorState={!isValid}
                      inputType={TextInputType.TextArea}
                      value={value}
                      onBlur={onBlur}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setValue(e.target.value)
                      }
                      placeholder={getText("FlightNamePlaceholder")}
                      errors={errors}
                      disabled={!FlightHelper.isEditable(flight, user?.id)}
                    >
                      {`${getText("FlightRemarks")}*`}
                    </TextInput>
                  </HBox>
                )}
              </Field>
              <HBox width="100%" gap="10px" visible={!!flight?.endDate}>
                <DateInput value={flight?.endDate} disabled>
                  {getText("AddFlightForm.PredictedEndTime")}
                </DateInput>
              </HBox>
              <HBox width="100%" gap="10px" visible={!!flight?.userEndDate}>
                <DateInput value={flight?.userEndDate} disabled>
                  {getText("AddFlightForm.EndTime")}
                </DateInput>
              </HBox>
            </VBox>
            <VBox Margin="10px 0 0 0" zIndex={1}>
              <Text
                Padding="0 20px"
                type={TextTypeEnum.h5}
                size={TextSizeEnum.large}
                weight={TextWeightEnum.medium}
              >
                {getText("AddFlightForm.Options")}
              </Text>
              <ListItem
                title={getText("AddFlightForm.FinishFlight")}
                description={getText("AddFlightForm.FinishFlightDescription")}
                rIcon={
                  <IoTimeOutline size="16px" color={theme.palette.White} />
                }
                visible={
                  FlightHelper.isCurrentUserSingle(flight, user?.id) &&
                  !flight?.userEndDate
                }
                action={ListItemAction.Success}
                onClick={() => setModal(AddFlightFormModalEnum.FINISH_FLIGHT)}
                darkest
              />
              <ListItem
                title={getText("AddFlightForm.ShowOnMap")}
                description={getText("AddFlightForm.ShowOnMapDescription")}
                rIcon={<IoMapOutline size="16px" color={theme.palette.White} />}
                visible={!!flight?.end}
                action={ListItemAction.Success}
                onClick={() => onShow(flight as Flight)}
              />
              {flight && flight.start && flight.end && (
                <PDFFlight flight={flight}>
                  <ListItem
                    title="Generate Document"
                    description="Generate PDF document with flight details"
                    rIcon={
                      <IoDocumentOutline
                        size="16px"
                        color={theme.palette.White}
                      />
                    }
                    visible={true}
                    action={ListItemAction.Primary}
                    onClick={() => {}}
                    darkest
                  />
                </PDFFlight>
              )}
              <ListItem
                title={
                  flight?.id
                    ? getText("AddFlightForm.SaveChanges")
                    : getText("AddFlightForm.AddFlight")
                }
                description={
                  flight?.id
                    ? getText("AddFlightForm.SaveChanges")
                    : getText("AddFlightForm.SaveChangesDescription")
                }
                rIcon={
                  flight?.id ? (
                    <IoAddCircleOutline
                      size="16px"
                      color={theme.palette.White}
                    />
                  ) : (
                    <IoPencilOutline size="16px" color={theme.palette.White} />
                  )
                }
                visible={
                  !(
                    (FlightHelper.isEvent(flight) &&
                      !FlightHelper.isCurrentUserEvent(flight, user?.id)) ||
                    FlightHelper.isSharing(flight, user?.id)
                  ) && !flight?.userEndDate
                }
                action={ListItemAction.Primary}
                onClick={submit}
              />
              <ListItem
                title={getText("AddFlightForm.RemoveFlight")}
                description={getText("AddFlightForm.RemoveFlightDescription")}
                rIcon={
                  <IoTrashOutline size="16px" color={theme.palette.White} />
                }
                visible={FlightHelper.isCurrentUserFlight(flight, user?.id)}
                action={ListItemAction.Critical}
                onClick={() => setModal(AddFlightFormModalEnum.DELETE_CONFIRM)}
                darkest
              />
              <ListItem
                title={getText("AddFlightForm.JoinFlight")}
                description={getText("AddFlightForm.JoinFlightDescription")}
                rIcon={
                  <IoShuffleOutline size="16px" color={theme.palette.White} />
                }
                visible={FlightHelper.showJoin(flight, user?.id)}
                action={ListItemAction.Success}
                onClick={submit}
              />
              <ListItem
                title={getText("AddFlightForm.ShareWithFriends")}
                description={getText(
                  "AddFlightForm.ShareWithFriendsDescription"
                )}
                darkest
                rIcon={
                  <IoShareSocialOutline
                    size="16px"
                    color={theme.palette.Grey60}
                  />
                }
                visible={FlightHelper.isCurrentUserSingle(flight, user?.id)}
                action={ListItemAction.Secondary}
                onClick={() => setModal(AddFlightFormModalEnum.SELECT_SHARE)}
              />
              <ListItem
                title={getText("AddFlightForm.StopWatchingFlight")}
                description={getText(
                  "AddFlightForm.StopWatchingFlightDescription"
                )}
                rIcon={
                  <IoEyeOffOutline size="16px" color={theme.palette.Grey60} />
                }
                visible={FlightHelper.isSharing(flight, user?.id)}
                darkest
                action={ListItemAction.Secondary}
                onClick={() => setModal(AddFlightFormModalEnum.REJECT_SHARE)}
              />
              <ListItem
                title={getText("AddFlightForm.AddFlightPlanner")}
                description={getText(
                  "AddFlightForm.AddFlightPlannerDescription"
                )}
                rIcon={
                  <IoPersonAddOutline
                    size="16px"
                    color={theme.palette.Grey60}
                  />
                }
                visible={FlightHelper.isCurrentUserEvent(flight, user?.id)}
                action={ListItemAction.Secondary}
                onClick={() => setModal(AddFlightFormModalEnum.PLANNER_SHARE)}
              />
              <ListItem
                title={getText("AddFlightForm.CreatePrivateEvent")}
                description={getText(
                  "AddFlightForm.CreatePrivateEventDescription"
                )}
                rIcon={
                  <IoPeopleOutline size="16px" color={theme.palette.Grey60} />
                }
                visible={
                  FlightHelper.isCurrentUserSingle(flight, user?.id) &&
                  !FlightHelper.isPrivateEventChild(flight) &&
                  !FlightHelper.isEventChild(flight)
                }
                action={ListItemAction.Secondary}
                onClick={() =>
                  setModal(AddFlightFormModalEnum.PRIVATE_EVENT_SHARE)
                }
                darkest
              />
            </VBox>
          </>
        )}
      </Form>
      <VBox
        Margin="10px 0 0 0"
        visible={
          FlightHelper.isEvent(flight) ||
          FlightHelper.isPrivateEvent(flight) ||
          FlightHelper.isChild(flight)
        }
      >
        <Text
          Padding="0 20px"
          type={TextTypeEnum.h5}
          size={TextSizeEnum.large}
          weight={TextWeightEnum.medium}
        >
          {getText("AddFlightForm.AdditionalInformation")}
        </Text>
        <VBox>
          <ListItem
            title={getText("AddFlightForm.Founder")}
            description={
              flight?.parentFlightId
                ? flight.parentFlight?.user.username
                : flight?.user.username
            }
          />
          <ListItem
            title={getText("AddFlightForm.District")}
            darkest
            description={getDistrictName()}
          />
          <ListItem
            title={getText("AddFlightForm.Branch")}
            description={getBranchName()}
          />
          <ListItem
            darkest
            title={getText("AddFlightForm.ParticipantsCount")}
            description={
              flight?.parentFlightId
                ? flight.parentFlight?.relatedFlights?.length
                : flight?.relatedFlights?.length
            }
          />
        </VBox>
      </VBox>
      <VBox
        Margin="10px 0 40px 0"
        visible={
          flight?.type === FlightType.Open ||
          !!flight?.parentFlightId ||
          FlightHelper.isPrivateEvent(flight)
        }
      >
        <Text
          Padding="0 20px"
          type={TextTypeEnum.h5}
          size={TextSizeEnum.large}
          weight={TextWeightEnum.medium}
        >
          {getText("AddFlightForm.Participants")}
        </Text>
        <VBox>{injectRelatedFlightBox()}</VBox>
      </VBox>
    </HalfModal>
  );
};

export default AddFlightForm;
