import SelectOption from "../../../models/SelectOption";
import { PigeonStateEnum } from "../../../models/enums/PigeonStateEnum";

const StateOptions = [
  new SelectOption({
    key: PigeonStateEnum.Active,
    title: "Stan",
    description: "Active",
  }),
  new SelectOption({
    key: PigeonStateEnum.Borrowed,
    title: "Stan",
    description: "Borrowed",
  }),
  new SelectOption({
    key: PigeonStateEnum.Breeding,
    title: "Stan",
    description: "Breeding",
  }),
  new SelectOption({
    key: PigeonStateEnum.Dead,
    title: "Stan",
    description: "Dead",
  }),
  new SelectOption({
    key: PigeonStateEnum.Lost,
    title: "Stan",
    description: "Lost",
  }),
  new SelectOption({
    key: PigeonStateEnum.Sold,
    title: "Stan",
    description: "Sold",
  }),
  new SelectOption({
    key: PigeonStateEnum.Widower,
    title: "Stan",
    description: "Widower",
  }),
  new SelectOption({
    key: PigeonStateEnum.Unknown,
    title: "Stan",
    description: "Other",
  }),
];

export const getInitialState = (current?: PigeonStateEnum) => {
  const option = StateOptions.find((state) => state.key === current);
  if (option) return option;
  return (
    StateOptions.find((state) => state.key === PigeonStateEnum.Active) ||
    StateOptions[0]
  );
};

export default StateOptions;
