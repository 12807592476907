import { CreateFlight, Flight, JoinFlight } from "../models/Flight";
import {
  authDelete,
  authGet,
  authPatch,
  authPost,
  authPut,
} from "./BaseService";

import { FlightMarker } from "../models/Marker";
import BaseStatistics from "../models/BaseStatistics";

export const getUserFlights = async (rawFilters?: string) => {
  if (!rawFilters) {
    const data = await authGet<Flight[]>(`flight/user`);
    return data.data;
  }
  const data = await authGet<Flight[]>(`flight/user/?filters=${rawFilters}`);
  return data.data;
};

export const getEventsFlights = async (rawFilters?: string) => {
  if (!rawFilters) {
    const data = await authGet<Flight[]>(`flight/event`);
    return data.data;
  }
  const data = await authGet<Flight[]>(`flight/event/?filters=${rawFilters}`);
  return data.data;
};

export const getFlight = async (flightId: number) => {
  if (!flightId) return null;
  const data = await authGet<Flight>(`flight/id/${flightId}`);
  return data.data;
};

export const addFlight = async (flight: CreateFlight) => {
  const data = await authPost<unknown>(`flight`, flight);
  return data.data as Flight;
};

export const joinToFlight = async (joinFlight: JoinFlight) => {
  const data = await authPost<Flight>(
    `flight/${joinFlight.flightId}/join`,
    joinFlight
  );
  return data.data;
};

export const updateFlight = async (flight: Partial<CreateFlight>) => {
  const data = await authPut<unknown>(`flight/${flight.id}`, flight);
  return flight as Flight;
};

export const updateFlightMarker = async (
  flightId?: number,
  id?: number,
  remarks?: string,
  realDate?: Date,
  userDate?: Date
) => {
  if (!id || !flightId) return;
  const data = await authPatch<unknown>(`flight/${flightId}/coordinate/${id}`, {
    realDate: realDate?.toISOString(),
    userDate: userDate?.toISOString(),
    remarks,
  });
  return data.data as FlightMarker;
};

export const removeFlight = async (flightId: number) => {
  const data = await authDelete<unknown>(`flight/${flightId}`);
  return data.data as Flight;
};

export const addToPrivateFlight = async (
  flightId: number,
  userIds: number[]
) => {
  const data = await authPost<Flight>(
    `flight/create-privateEvent/${flightId}`,
    {
      userIds,
    }
  );
  return data;
};

export const addPlanners = async (flightId: number, userIds: number[]) => {
  const data = await authPost<Flight>(`flight/planner/${flightId}`, {
    userIds,
  });
  return data;
};

export const shareFlight = async (flightId: number, userIds: number[]) => {
  const data = await authPost<Flight>(`flight/share/${flightId}`, { userIds });
  return data;
};

export const rejectShareFlight = async (flightId: number, userId: number) => {
  const data = await authDelete<unknown>(`flight/share/${flightId}/${userId}`);
  return data;
};

export const finishFlight = async (flightId: number, endDate: string) => {
  const data = await authPost(`flight/finish/${flightId}`, {
    endDate,
  });
  return data;
};

export const getFlightsStatistics = async () => {
  const data = await authGet<object>(`flight/statistics`);
  return BaseStatistics.create(data.data);
};

export const getUserFlightsStatistics = async (userId: number) => {
  const data = await authGet<object>(`flight/statistics/${userId}`);
  return BaseStatistics.create(data.data);
};
