import { Button, ButtonType } from "../../../styles/Button.styled";
import { Field, Form } from "houseform";
import { HBox, VBox } from "../../../styles/Flexbox.styled";

import Bar from "../../Bar/Bar";
import { BarType } from "../../Bar/IBar";
import { EditProfileProps } from "./IEditProfile";
import HalfModal from "../../HalfModal/HalfModal";
import { IEditProfile } from "../../../services/IUserService";
import Text from "../../Text/Text";
import TextInput from "../../TextInput";
import { getText } from "../../../locales/initI18n";
import { useTheme } from "styled-components";

const EditProfile: React.FC<EditProfileProps> = ({
  isVisible,
  phoneIsRequired,
  onConfirm,
  onClose,
}) => {
  const theme = useTheme();
  const onSubmit = (data: IEditProfile) => {
    onConfirm?.(data);
  };
  return (
    <HalfModal
      visible={isVisible}
      onClose={onClose}
      heightDivider={1.5}
      isExpander={false}
    >
      <Form onSubmit={onSubmit}>
        {({ submit, value }) => (
          <VBox Padding="20px" gap="10px">
            <Text
              type="h4"
              size="large"
              weight="bold"
              Margin="0 0 10px 0"
              color={theme.palette.Grey100}
            >
              {getText("EditProfile")}
            </Text>
            <Bar type={BarType.Critical} visible={phoneIsRequired}>
              Jeśli chcesz zostać lotowym musisz podac numer telefonu. Numer
              telefonu służy do weryfikacji tożsamości.
            </Bar>
            <Field<string> name="firstName">
              {({ value, setValue, onBlur, isValid, errors }) => (
                <HBox width="100%" gap="10px">
                  <TextInput
                    isErrorState={!isValid}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setValue(e.target.value)
                    }
                    placeholder="Jan"
                    errors={errors}
                  >
                    {getText("FirstName")}
                  </TextInput>
                </HBox>
              )}
            </Field>
            <Field<string> name="lastName">
              {({ value, setValue, onBlur, isValid, errors }) => (
                <HBox width="100%" gap="10px">
                  <TextInput
                    isErrorState={!isValid}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setValue(e.target.value)
                    }
                    placeholder="Kowalski"
                    errors={errors}
                  >
                    {getText("LastName")}
                  </TextInput>
                </HBox>
              )}
            </Field>
            <Field<string> name="phoneNumber">
              {({ value, setValue, onBlur, isValid, errors }) => (
                <HBox width="100%" gap="10px">
                  <TextInput
                    isErrorState={!isValid}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setValue(e.target.value)
                    }
                    placeholder="+48517775332"
                    errors={errors}
                  >
                    {getText("PhoneNumber")}
                  </TextInput>
                </HBox>
              )}
            </Field>
            <Bar type={BarType.Warn}>{getText("EditProfileInfo")}</Bar>
            <Button
              buttonType={ButtonType.Primary}
              padding="0 20px"
              onClick={submit}
            >
              {getText("EditProfile")}
            </Button>
          </VBox>
        )}
      </Form>
    </HalfModal>
  );
};

export default EditProfile;
