import IStyledText, {
  bodySize,
  bodyWeight,
  captionSize,
  captionWeight,
  footerWeight,
  headingWeight,
} from "./ITypographyStyled";
import styled, { css } from "styled-components";

import TextWeightEnum from "../models/enums/TextWeightEnum";

// UTILS

const setFooterWeight = (weight: footerWeight) => {
  if (weight === "bold") return "500";
  if (weight === "medium") return "400";
  return "300";
};

const setCaptionSize = (size: captionSize) => {
  if (size === "large") return "13px";
  if (size === "medium") return "12px";
  return "10px";
};

const setCaptionWeight = (weight: captionWeight) => {
  if (weight === "bold") return "600";
  if (weight === "medium") return "500";
  return "400";
};

const setBodyWeight = (weight: bodyWeight) => {
  if (weight === "bold") return "600";
  if (weight === "medium") return "500";
  return "400";
};

const setBodySize = (size: bodySize) => {
  if (size === "large") return "16px";
  if (size === "medium") return "14px";
  return "12px";
};

const setBodyLineHeight = (size: bodySize) => {
  if (size === "large") return "25px";
  if (size === "medium") return "22px";
  return "20px";
};

const setHeadingWeight = (weight: headingWeight) => {
  if (weight === "bold") return "700";
  return "500";
};

const setWeight = (weight: TextWeightEnum) => {
  if (weight === "bold") return "700";
  if (weight === "medium") return "500";
  return "400";
};

const getFontStyle = (
  type: IStyledText["type"],
  weight: IStyledText["weight"],
  size: IStyledText["size"]
) => {
  if (type === "body") return Body(weight, size);
  if (type === "caption") return Caption(weight, size);
  if (type === "footer") return Footer(weight);

  if (type === "h1" && size === "large")
    return Heading1Large(weight === "regular" ? undefined : weight);
  if (type === "h1" && size === "small")
    return Heading1Small(weight === "regular" ? undefined : weight);

  if (type === "h2" && size === "large")
    return Heading2Large(weight === "regular" ? undefined : weight);
  if (type === "h2" && size === "small")
    return Heading2Small(weight === "regular" ? undefined : weight);

  if (type === "h3" && size === "large")
    return Heading3Large(weight === "regular" ? undefined : weight);
  if (type === "h3" && size === "small")
    return Heading3Small(weight === "regular" ? undefined : weight);

  if (type === "h4" && size === "large")
    return Heading4Large(weight === "regular" ? undefined : weight);
  if (type === "h4" && size === "small")
    return Heading4Small(weight === "regular" ? undefined : weight);

  if (type === "h5" && size === "large")
    return Heading5Large(weight === "regular" ? undefined : weight);
  if (type === "h5" && size === "small")
    return Heading5Small(weight === "regular" ? undefined : weight);

  if (type === "h6" && size === "large")
    return Heading6Large(weight === "regular" ? undefined : weight);
  if (type === "h6" && size === "small")
    return Heading6Small(weight === "regular" ? undefined : weight);

  if (
    [
      TextWeightEnum.bold,
      TextWeightEnum.medium,
      TextWeightEnum.regular,
    ].includes(weight as TextWeightEnum)
  ) {
    return css`
      font-weight: ${setWeight(weight as TextWeightEnum)};
    `;
  }

  return "";
};

// HEADING LARGE

export const Heading1Large = (weight: headingWeight = "bold") => css`
  font-size: 48px;
  font-weight: ${setHeadingWeight(weight)};
  line-height: 56px;
  letter-spacing: 0.3px;
`;

export const Heading2Large = (weight: headingWeight = "bold") => css`
  font-size: 40px;
  font-weight: ${setHeadingWeight(weight)};
  line-height: 48px;
  letter-spacing: 0.3px;
`;

export const Heading3Large = (weight: headingWeight = "bold") => css`
  font-size: 32px;
  font-weight: ${setHeadingWeight(weight)};
  line-height: 40px;
  letter-spacing: 0.3px;
`;

export const Heading4Large = (weight: headingWeight = "bold") => css`
  font-size: 24px;
  font-weight: ${setHeadingWeight(weight)};
  line-height: 32px;
  letter-spacing: 0.3px;
`;

export const Heading5Large = (weight: headingWeight = "bold") => css`
  font-size: 20px;
  font-weight: ${setHeadingWeight(weight)};
  line-height: 36px;
  letter-spacing: 0.3px;
`;

export const Heading6Large = (weight: headingWeight = "bold") => css`
  font-size: 16px;
  font-weight: ${setHeadingWeight(weight)};
  line-height: 32px;
  letter-spacing: 0.3px;
`;

// HEADING SMALL

export const Heading1Small = (weight: headingWeight = "bold") => css`
  font-size: 40px;
  line-height: 48px;
  font-weight: ${setHeadingWeight(weight)};
  letter-spacing: 0.3px;
`;

export const Heading2Small = (weight: headingWeight = "bold") => css`
  font-size: 32px;
  line-height: 40px;
  font-weight: ${setHeadingWeight(weight)};
  letter-spacing: 0.3px;
`;

export const Heading3Small = (weight: headingWeight = "bold") => css`
  font-size: 24px;
  line-height: 32px;
  font-weight: ${setHeadingWeight(weight)};
  letter-spacing: 0.3px;
`;

export const Heading4Small = (weight: headingWeight = "bold") => css`
  font-size: 20px;
  line-height: 24px;
  font-weight: ${setHeadingWeight(weight)};
  letter-spacing: 0.3px;
`;

export const Heading5Small = (weight: headingWeight = "bold") => css`
  font-size: 16px;
  line-height: 25px;
  font-weight: ${setHeadingWeight(weight)};
  letter-spacing: 0.3px;
`;

export const Heading6Small = (weight: headingWeight = "bold") => css`
  font-size: 14px;
  line-height: 22px;
  font-weight: ${setHeadingWeight(weight)};
  letter-spacing: 0.3px;
`;

// BODY

export const Body = (
  weight: bodyWeight = "bold",
  size: bodySize = "large"
) => css`
  font-size: ${setBodySize(size)};
  line-height: ${setBodyLineHeight(size)};
  font-weight: ${setBodyWeight(weight)};
  letter-spacing: 0.3px;
`;

// CAPTION

export const Caption = (
  weight: captionWeight = "bold",
  size: captionSize = "large"
) => css`
  font-size: ${setCaptionSize(size)};
  line-height: ${size === "large" ? "18px" : "16px"};
  font-weight: ${setCaptionWeight(weight)};
  letter-spacing: 0.3px;
`;

// FOOTER

export const Footer = (weight: footerWeight = "bold") => css`
  font-size: 8px;
  line-height: 14px;
  font-weight: ${setFooterWeight(weight)};
  letter-spacing: 0.3px;
`;

// TEXT COMPONENT

export const StyledText = styled.p<IStyledText>`
  ${({ type, weight, size }) => getFontStyle(type, weight, size)}
  display: ${({ visible = true }) => (visible ? "inline-block" : "none")};
  text-transform: ${({ textTransform }) => textTransform || "none"};
  margin: ${({ Margin }) => Margin || "0px"};
  padding: ${({ Padding }) => Padding || "0px"};
  width: ${({ width }) => width || "auto"};
  color: ${({ color }) => color || "inherit"};
  text-decoration: ${({ textDecoration }) => textDecoration || "none"};
  text-align: ${({ textAlign }) => textAlign || "inherit"};
  z-index: ${({ zIndex }) => zIndex || 0};
  font-style: ${({ fontStyle }) => fontStyle || "inherit"};
  overflow: ${({ overflow }) => overflow || "unset"};
  text-overflow: ${({ textOverflow }) => textOverflow || "inherit"};
  text-wrap: ${({ textWrap }) => textWrap || "inherit"};
`;
