import { Button, ButtonType } from "../../styles/Button.styled";

import { HBox } from "../../styles/Flexbox.styled";
import { IoFilter } from "react-icons/io5";
import { memo } from "react";
import { useTheme } from "styled-components";

export interface FilterButtonProps {
  onClick: () => void;
  isActive: boolean;
}

const FilterButton = ({ onClick, isActive }: FilterButtonProps) => {
  const theme = useTheme();
  return (
    <Button
      isCustom
      width="50px"
      buttonType={ButtonType.Ghost}
      onClick={onClick}
    >
      <HBox
        position="absolute"
        visible={isActive}
        width="10px"
        height="10px"
        backgroundColor={theme.palette.Primary100}
        BorderRadius="10px"
        Top="2px"
        Right="2px"
      />
      <IoFilter size="20px" color={theme.palette.Grey70} />
    </Button>
  );
};

export default memo(FilterButton);
