import { ITag } from "./Tag";
import User from "./User";
import { TagType } from "./enums/TagType";

export default class UserStatistics extends User {
  totalFlights: number = 0;
  totalOpinions: number = 0;
  totalRelease: number = 0;
  totalCotes: number = 0;
  public setTags(tags: ITag[] = []) {
    this.totalRelease = tags.filter((tag) => !tag.isCote).length;
    this.totalCotes = tags.filter((tag) => tag.isCote).length;
  }
}
