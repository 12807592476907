import { Button, ButtonType } from "../../../styles/Button.styled";
import { HBox, VBox } from "../../../styles/Flexbox.styled";
import { useEffect, useState } from "react";

import DateInput from "../../DateInput/DateInput";
import { FinishFlightProps } from "./IFlightFinish";
import HalfModal from "../../HalfModal/HalfModal";
import { getText } from "../../../locales/initI18n";

const FlightFinish = ({
  title,
  isVisible,
  endDate,
  onConfirm,
  onClose,
}: FinishFlightProps) => {
  const [userEndDate, setUserEndDate] = useState<Date>(new Date());
  useEffect(() => {
    setUserEndDate(new Date(endDate));
  }, [endDate]);
  return (
    <HalfModal
      Padding="20px 10px"
      isExpander={false}
      heightDivider={2.5}
      title={title}
      visible={isVisible}
      onClose={onClose}
    >
      <VBox gap="10px">
        <HBox>
          <DateInput
            value={userEndDate}
            onChange={(e: Date) => setUserEndDate(e)}
            type="date"
          >
            {getText("FlightFinish.EndTime")}
          </DateInput>
        </HBox>
        <HBox width="100%">
          <Button
            width="100%"
            buttonType={ButtonType.Success}
            onClick={() => onConfirm(userEndDate)}
          >
            {getText("FlightFinish.FinishFlight")}
          </Button>
        </HBox>
      </VBox>
    </HalfModal>
  );
};

export default FlightFinish;
