import HalfModal from "../../HalfModal/HalfModal";
import ListItem from "../../ListItem/ListItem";
import { SectionListProps } from "./ISectionList";
import { VBox } from "../../../styles/Flexbox.styled";
import { getSections } from "../../../services/DistrictService";
import { getText } from "../../../locales/initI18n";
import { useQuery } from "@tanstack/react-query";

const SectionList: React.FC<SectionListProps> = ({
  isVisible,
  onClose,
  onConfirm,
  districtId,
  branchId,
}) => {
  const queryFn = () => {
    return getSections(districtId, branchId);
  };

  const { data: sections, isLoading } = useQuery({
    queryKey: ["sections", { districtId, branchId }],
    queryFn,
    enabled: isVisible,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return (
    <HalfModal
      isExpander={false}
      heightDivider={1.1}
      visible={isVisible}
      busy={isLoading}
      title={getText("General.Section")}
      onClose={onClose}
      Padding="0"
      zIndex={2}
    >
      <VBox>
        {sections?.map((section, index) => (
          <ListItem
            key={`Section-${section.id}`}
            title={section.name}
            description=""
            darkest={index % 2 === 0}
            onClick={() => onConfirm(section)}
          />
        ))}
      </VBox>
    </HalfModal>
  );
};

export default SectionList;
