import { CreateFlight, Flight, JoinFlight } from "../../../models/Flight";
import { CreateFlightTag, ITag } from "../../../models/Tag";

import { AddFlightFormModalEnum } from "./AddFlightFormModalEnum";
import { CloseIconEnum } from "../../HalfModal/CloseIconEnum";
import { FlightType } from "../../../models/enums/FlightType";
import { IFarmer } from "../../../services/IFollowService";
import { ModalProps } from "../IModalOptions";

export enum AddFlightConfirmType {
  Create,
  Join,
}

export type AddFlightConfirmProps =
  | {
      type: AddFlightConfirmType.Create;
      flight: CreateFlight;
    }
  | {
      type: AddFlightConfirmType.Join;
      flight: JoinFlight;
    };

export interface AddFlightFormProps
  extends ModalProps<AddFlightConfirmProps, boolean> {
  flightId?: number;
  onDelete: (flightId: Flight) => void;
  onShow: (flight?: Flight) => void;
  closeIcon?: CloseIconEnum;
}

export interface AddFlightFormModalProps {
  flight?: Flight;
  relatedFlight?: Flight;
  modal: AddFlightFormModalEnum;
  onClose: () => void;
  onConfirm: (props: OnConfirmAddFlightFormModalProps) => void;
}

export type OnConfirmAddFlightFormModalProps =
  | {
      modal: AddFlightFormModalEnum.TAGS;
      data: ITag;
    }
  | {
      modal: AddFlightFormModalEnum.COTES;
      data: ITag;
    }
  | {
      modal: AddFlightFormModalEnum.SELECT_SHARE;
      data: IFarmer[];
    }
  | {
      modal: AddFlightFormModalEnum.PLANNER_SHARE;
      data: IFarmer[];
    }
  | {
      modal: AddFlightFormModalEnum.PRIVATE_EVENT_SHARE;
      data: IFarmer[];
    }
  | {
      modal: AddFlightFormModalEnum.DELETE_CONFIRM;
      data: null;
    }
  | {
      modal: AddFlightFormModalEnum.CHANGE_CONFIRM;
      data: null;
    }
  | {
      modal: AddFlightFormModalEnum.SUBMIT_CONFIRM;
      data: boolean;
    }
  | {
      modal: AddFlightFormModalEnum.REJECT_SHARE;
      data: null;
    }
  | {
      modal: AddFlightFormModalEnum.RELATED_FLIGHTS;
      data: null;
    }
  | {
      modal: AddFlightFormModalEnum.FINISH_FLIGHT;
      data: string;
    };

export interface FlightTypeOptions {
  value: FlightType;
  label: string;
}

export interface AddFlightSubmitProps {
  id?: number;
  name: string;
  remarks: string;
  startDate: Date | string;
  start: CreateFlightTag;
  end: CreateFlightTag;
}

export enum ReleaseType {
  COTE,
  RELEASE,
  NONE,
}
