import { Button, ButtonType } from "../../../styles/Button.styled";
import { FriendsProps, IFriend } from "./IFriends";
import { HBox, VBox } from "../../../styles/Flexbox.styled";

import HalfModal from "../../HalfModal/HalfModal";
import ListItem from "../../ListItem/ListItem";
import { ListItemAction } from "../../ListItem/IListItem";
import { UserType } from "../../../models/enums/UserType";
import { getFriends } from "../../../services/FollowerService";
import { getText } from "../../../locales/initI18n";
import { useQuery } from "@tanstack/react-query";

const Friends = ({
  shared = [],
  multiSelect = true,
  ...props
}: FriendsProps) => {
  const queryFn = async () => {
    const friends = (await getFriends()) as IFriend[];
    if (shared) {
      shared.forEach((sh) => {
        const friend = friends.find((fr) => fr.id === sh);
        if (friend) friend.state = true;
      });
    }
    return friends;
  };

  const shareFlight = async () => {
    const farmers = data?.filter((f) => f.state);
    props.onConfirm(farmers || []);
  };

  const { data } = useQuery({
    queryKey: [`friends`],
    queryFn: queryFn,
    enabled: props.isVisible,
    cacheTime: 0,
  });

  const onSelect = (friend: IFriend) => {
    if (multiSelect) {
      friend.state = !friend.state;
      return;
    }
    data?.forEach((f) => {
      f.state = false;
    });
    friend.state = true;
  };

  return (
    <HalfModal
      onClose={props.onClose}
      visible={props.isVisible}
      title={
        multiSelect
          ? getText("Friends.ModalTitleFriends")
          : getText("Friends.ModalTitleFriend")
      }
      Padding="0"
    >
      <VBox Padding="20px 0 0 0" overflowY="scroll">
        {data?.map((friend, index) => (
          <ListItem
            visible={
              props.onlyPlanners ? friend.type === UserType.Planner : true
            }
            key={`friend-${friend.id}`}
            title={friend.username}
            description=""
            action={ListItemAction.Checkbox}
            isChecked={friend.state}
            onClick={() => onSelect(friend)}
            darkest={index % 2 === 0}
          />
        ))}
      </VBox>
      <HBox Margin="20px 20px">
        <Button
          buttonType={ButtonType.Success}
          height="56px"
          width="100%"
          onClick={shareFlight}
        >
          {multiSelect
            ? getText("Friends.ShareWithFriends")
            : getText("Friends.ChooseFriend")}
        </Button>
      </HBox>
    </HalfModal>
  );
};

export default Friends;
