import { IBaseFilter } from "../models/Filter/IBaseFilter";
import ParentFilter from "../models/Filter/ParentFilter";
import { ParentFilterOperator } from "../models/Filter/ParentFilterOperator";
import { useState } from "react";

type setFilterFn = (
  filter?: IBaseFilter[] | IBaseFilter,
  operator?: ParentFilterOperator
) => void;

const useFilter = (): [string, setFilterFn, IBaseFilter[]] => {
  const [filter, setFilterInternal] = useState<IBaseFilter[]>([]);
  const [raw, setRaw] = useState<string>("");

  const setFilter = (
    filter?: IBaseFilter[] | IBaseFilter,
    operator = ParentFilterOperator.Or
  ) => {
    if (!filter) {
      setFilterInternal([]);
      setRaw("");
      return;
    }
    if (Array.isArray(filter)) {
      const parent = new ParentFilter(filter, operator);
      setRaw(parent.transform());
    } else {
      setRaw(filter.transform());
    }

    if (!Array.isArray(filter)) filter = [filter];
    setFilterInternal(filter);
  };

  return [raw, setFilter, filter];
};

export default useFilter;
