type DateOrString = Date | string;

export default class DateHelper {
  static toDate(date: DateOrString | undefined): Date {
    if (!date) return new Date();
    if (typeof date === "string") {
      return new Date(date);
    }
    return date;
  }
  static getDiff(start: DateOrString, end: DateOrString): number {
    const diff =
      DateHelper.toDate(end).getTime() - DateHelper.toDate(start).getTime();
    return diff;
  }
}
