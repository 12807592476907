const Primary = {
  light: {
    Primary100: "#009fbe",
    Primary90: "#00b3d5",
    Primary80: "#00c7ed",
    Primary70: "#1acdef",
    Primary60: "#33d2f1",
    Primary50: "#4dd8f2",
    Primary40: "#66ddf4",
    Primary30: "#80e3f6",
    Primary20: "#99e9f8",
    Primary10: "#b3eefa",
    Primary5: "#cceffa",
    Primary1: "#e6f4fc",
  },
  dark: {
    Primary100: "#009fbe",
    Primary90: "#00b3d5",
    Primary80: "#00c7ed",
    Primary70: "#1acdef",
    Primary60: "#33d2f1",
    Primary50: "#4dd8f2",
    Primary40: "#66ddf4",
    Primary30: "#80e3f6",
    Primary20: "#99e9f8",
    Primary10: "#b3eefa",
    Primary5: "#cceffa",
    Primary1: "#e6f4fc",
  },
};

const Grey = {
  light: {
    Grey100: "#19191D",
    Grey90: "#282727",
    Grey80: "#47474A",
    Grey70: "#5E5E61",
    Grey60: "#757577",
    Grey50: "#8C8C8E",
    Grey40: "#A3A3A5",
    Grey30: "#BABABB",
    Grey20: "#D1D1D2",
    Grey15: "#D8D8D8",
    Grey10: "#E8E8E8",
    Grey5: "#F8F8F8",
    Grey1: "#FCFCFC",
  },
  dark: {
    Grey100: "#F8F8F8",
    Grey90: "#E8E8E8",
    Grey80: "#D8D8D8",
    Grey70: "#D1D1D2",
    Grey60: "#BABABB",
    Grey50: "#A3A3A5",
    Grey40: "#8C8C8E",
    Grey30: "#757577",
    Grey20: "#5E5E61",
    Grey15: "#47474A",
    Grey10: "#282727",
    Grey5: "#19191D",
    Grey1: "#141416",
  },
};

const White = {
  light: {
    White: "#FFF",
  },
  dark: {
    White: "#0D0D0D",
  },
};

const Red = {
  light: {
    Red100: "#DC3C31",
    Red90: "#F44336",
    Red80: "#F5564A",
    Red70: "#F6695E",
    Red60: "#F77B72",
    Red50: "#F88E86",
    Red40: "#FAA19B",
    Red30: "#FBB4AF",
    Red20: "#FCC7C3",
    Red10: "#FDD9D7",
  },
  dark: {
    Red100: "#FAA19B",
    Red90: "#F88E86",
    Red80: "#F77B72",
    Red70: "#F77F76",
    Red60: "#F66C62",
    Red50: "#F5594E",
    Red40: "#E05147",
    Red30: "#680C05",
    Red20: "#540A04",
    Red10: "#400603",
  },
};

const Green = {
  light: {
    Green100: "#5B9F5E",
    Green90: "#65B168",
    Green80: "#74B977",
    Green70: "#84C186",
    Green60: "#93C895",
    Green50: "#A3D0A4",
    Green40: "#B2D8B4",
    Green30: "#C1E0C3",
    Green20: "#D1E8D2",
    Green10: "#E0EFE1",
  },
  dark: {
    Green100: "#B6DAB7",
    Green90: "#B6DAB7",
    Green80: "#B6DAB7",
    Green70: "#ADD6AE",
    Green60: "#A4D1A5",
    Green50: "#94BC95",
    Green40: "#83A784",
    Green30: "#739273",
    Green20: "#627D63",
    Green10: "#526953",
  },
};

const Yellow = {
  light: {
    Yellow100: "#EED202",
    Yellow90: "#F0D71B",
    Yellow80: "#F1DB35",
    Yellow70: "#F3E04E",
    Yellow60: "#F5E467",
    Yellow50: "#F7E981",
    Yellow40: "#F8ED9A",
    Yellow30: "#FAF2B3",
    Yellow20: "#FCF6CC",
    Yellow10: "#FDFBE6",
  },
  dark: {
    Yellow100: "#FDE10D",
    Yellow90: "#F2DB33",
    Yellow80: "#F3DF4D",
    Yellow70: "#F7E97F",
    Yellow60: "#F7E97F",
    Yellow50: "#F7E981",
    Yellow40: "#F8ED9A",
    Yellow30: "#645907",
    Yellow20: "#4B4304",
    Yellow10: "#1A1A1A",
  },
};

const Colors = {
  light: {
    ...Primary.light,
    ...Grey.light,
    ...White.light,
    ...Red.light,
    ...Yellow.light,
    ...Green.light,
  },
  dark: {
    ...Primary.dark,
    ...Grey.dark,
    ...White.dark,
    ...Red.dark,
    ...Yellow.dark,
    ...Green.dark,
  },
};

export type IColors = typeof Colors.light;

export default Colors;
