import { HBox, VBox } from "../../styles/Flexbox.styled";
import { useEffect, useRef, useState } from "react";

import { IoMaleSharp } from "react-icons/io5";
import ListItem from "../ListItem/ListItem";
import SelectOption from "../../models/SelectOption";
import { SelectProps } from "./ISelect";
import { useTheme } from "styled-components";

const Select = ({ options, value, onSelect, fixedHeight }: SelectProps) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);

  const onChangeOption = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const onSelectItem = (option: SelectOption) => {
    onSelect(option);
    setIsDropdownVisible(false);
  };

  const onOutsideClick = (e: MouseEvent) => {
    if (!containerRef.current) return;
    if (containerRef.current.contains(e.target as Node)) return;
    setIsDropdownVisible(false);
  };

  useEffect(() => {
    window.addEventListener("click", onOutsideClick);
    return () => {
      window.removeEventListener("click", onOutsideClick);
    };
  }, []);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <VBox ref={containerRef} position="relative" flexGrow="1">
      <HBox overflowX="hidden" overflowY="hidden" BorderRadius="20px">
        <ListItem
          darkest
          key={selectedValue.key}
          title={selectedValue.title}
          description={selectedValue.description}
          rTitle={selectedValue.rTitle}
          rDescription={selectedValue.rDescription}
          icon={selectedValue.icon}
          onClick={onChangeOption}
        />
      </HBox>
      <VBox
        height={fixedHeight ? fixedHeight : undefined}
        width="100%"
        visible={isDropdownVisible}
        position="absolute"
        Top="50px"
        Margin="5px 0 0 0"
        BorderRadius="20px"
        border={`2px solid ${theme.palette.Grey20}`}
        overflowY="auto"
        backgroundColor={theme.palette.White}
      >
        {options.map((option, index) => (
          <ListItem
            darkest={index % 2 === 1}
            key={option.key}
            title={option.title}
            description={option.description}
            rTitle={option.rTitle}
            rDescription={option.rDescription}
            onClick={() => onSelectItem(option)}
            icon={option.icon}
          />
        ))}
      </VBox>
    </VBox>
  );
};

export default Select;
