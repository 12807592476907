import Confirm from "./modals/Confirm/Confirm";
import ConfirmModalTypeEnum from "../models/enums/ConfirmModalTypeEnum";
import { HBox } from "../styles/Flexbox.styled";
import { ModalProps } from "./modals/IModalOptions";
import OpinionService from "../services/OpinionService";
import Rate from "./Rate/Rate";
import Text from "./Text/Text";
import TextSizeEnum from "../models/enums/TextSizeEnum";
import TextTypeEnum from "../models/enums/TextTypeEnum";
import TextWeightEnum from "../models/enums/TextWeightEnum";
import { getText } from "../locales/initI18n";
import { memo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTheme } from "styled-components";

const FlightListInfo = (props: ModalProps<undefined>) => {
  const theme = useTheme();
  const { data: opinion } = useQuery({
    queryKey: [`opinion`, OpinionService.FLIGHTS_OPINION],
    queryFn: () => OpinionService.getByName(OpinionService.FLIGHTS_OPINION),
  });
  return (
    <Confirm
      type={ConfirmModalTypeEnum.Change}
      onConfirm={() => props.onConfirm(undefined)}
      confirmText={getText("FlightListInfo.modal.confirm")}
      onClose={() => props.onConfirm(undefined)}
      title={getText("FlightListInfo.modal.title")}
      isCancel={false}
      isVisible={props.isVisible}
    >
      <Text
        type={TextTypeEnum.body}
        weight={TextWeightEnum.regular}
        size={TextSizeEnum.medium}
      >
        <Text weight={TextWeightEnum.medium} type="h5">
          {getText("FlightListInfo.title")}
        </Text>
        <Text>{getText("FlightListInfo.subtitle")}</Text>
        <br />
        <br />
        <HBox justifyContent="center">
          <img
            width="300px"
            src="/assets/FlightList_info.png"
            alt="Flight list information"
          />
        </HBox>
        <br />
        {getText("FlightListInfo.description")}
        <br />
        <br />
        <Text
          visible={!opinion}
          weight={TextWeightEnum.medium}
          size={TextSizeEnum.large}
        >
          {getText("FlightListInfo.rate.title")}
        </Text>
        <br />
        <Text visible={!opinion}>
          {getText("FlightListInfo.rate.description")}
        </Text>
        <br />
        <br />
        <Rate name={OpinionService.FLIGHTS_OPINION} />
      </Text>
    </Confirm>
  );
};

export default memo((props: ModalProps<undefined>) =>
  props.isVisible ? <FlightListInfo {...props} /> : null
);
