import { IconType } from "react-icons/lib";
import { ReactNode } from "react";

interface SelectOptionProps {
  key: string | number;
  title: string;
  description: string;
  rTitle?: string;
  rDescription?: string;
  icon?: ReactNode;
}

export default class SelectOption {
  public key: string | number;
  public description: string;
  public rDescription?: string;

  public title: string;
  public rTitle?: string;

  public icon?: ReactNode;

  constructor(props: SelectOptionProps) {
    this.key = props.key;
    this.title = props.title;
    this.description = props.description;
    this.rTitle = props.rTitle;
    this.rDescription = props.rDescription;
    this.icon = props.icon;
  }
}
