import { Country } from "../models/Country";
import { getI18n } from "react-i18next";

export default class RegionDetector {
  private static detector: Intl.DisplayNames;
  private static countries: Country[] = [];

  private static getDetector() {
    const country = getI18n().language;
    if (!RegionDetector.detector) {
      RegionDetector.detector = new Intl.DisplayNames([country], {
        type: "region",
      });
    }
    return RegionDetector.detector;
  }

  public static GetRegionName(countryCode: string) {
    if (!countryCode) return countryCode;
    return RegionDetector.getDetector().of(countryCode.toUpperCase());
  }

  public static GetUserCountry() {
    const language = getI18n().language;
    const countryCode = language?.split("-")[1] || language;
    const countryName = RegionDetector.GetRegionName(countryCode.toUpperCase());
    if (!countryName) return new Country(countryCode, countryCode);
    return new Country(countryCode, countryName);
  }

  public static GetCountries(search?: string) {
    const lang = getI18n().language;
    const A = 65;
    const Z = 90;
    const countryName = new Intl.DisplayNames([lang], { type: "region" });
    if (RegionDetector.countries.length > 0) {
      return RegionDetector.SearchCountries(search);
    }
    for (let i = A; i <= Z; ++i) {
      for (let j = A; j <= Z; ++j) {
        let code = String.fromCharCode(i) + String.fromCharCode(j);
        let name = countryName.of(code);
        if (name && code !== name) {
          RegionDetector.countries.push(new Country(code, name));
        }
      }
    }
    return RegionDetector.SearchCountries(search);
  }

  private static SearchCountries(search?: string) {
    if (!search) return RegionDetector.countries;
    return RegionDetector.countries.filter((country) =>
      country.Contains(search)
    );
  }
}
