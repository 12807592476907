import { Button, ButtonType } from "../styles/Button.styled";
import { useEffect, useState } from "react";

import { HBox } from "../styles/Flexbox.styled";
import Text from "./Text/Text";
import TextSizeEnum from "../models/enums/TextSizeEnum";
import TextTypeEnum from "../models/enums/TextTypeEnum";
import TextWeightEnum from "../models/enums/TextWeightEnum";
import { TimeRange } from "../styles/TimeRange.styled";
import { formatDateTime } from "../utils/VariableHelper";
import { getText } from "../locales/initI18n";
import { useTheme } from "styled-components";

interface TimeSliderProps {
  changeTime: (time: Date) => void;
  onAfterChange?: (time: Date) => void;
  time?: Date;
}

const TimeSlider = (props: TimeSliderProps) => {
  const theme = useTheme();
  const [startDate, setStartDate] = useState<number>(0);
  const [endDate, setEndDate] = useState<number>(0);
  const [value, setValue] = useState<number>(new Date().getTime());

  const changeTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(Number(value));

    const time = new Date();
    time.setTime(Number(value));
    props.changeTime(time);
  };

  const shiftTime = (shift?: number) => {
    const time = !!shift ? new Date(value) : new Date();
    time.setMinutes(time.getMinutes() + (shift || 0));
    if (time.getTime() < startDate) {
      time.setTime(startDate);
    }
    if (time.getTime() > endDate) {
      time.setTime(endDate);
    }
    setValue(time.getTime());
    props.changeTime(time);
    props.onAfterChange?.(time);
  };

  useEffect(() => {
    const now = new Date();

    const startDate = new Date();
    startDate.setHours(now.getHours() - 10);
    setStartDate(startDate.getTime());

    const endDate = new Date();
    endDate.setDate(now.getDate() + 5);
    setEndDate(endDate.getTime());
  }, []);

  useEffect(() => {
    if (props.time && props.time.getTime() !== value) {
      setValue(props.time.getTime());
    }
  }, [props.time]);

  return (
    <>
      <Text
        Margin="0 0 0 5px"
        type="caption"
        size="small"
        weight="regular"
        color={theme.palette.Grey80}
      >
        {`${getText("TimeSlider.MapData")}: ${formatDateTime(new Date(value))}`}
      </Text>
      <HBox>
        <Button
          isCustom
          height="25px"
          padding="0 3px"
          onClick={() => shiftTime(-30)}
          buttonType={ButtonType.Border}
        >
          <Text
            type={TextTypeEnum.caption}
            size={TextSizeEnum.small}
            weight={TextWeightEnum.bold}
            color={theme.palette.Grey70}
          >
            -30'
          </Text>
        </Button>
        <Button
          isCustom
          margin="0 0 0 1px"
          height="25px"
          padding="0 3px"
          onClick={() => shiftTime(0)}
          buttonType={ButtonType.Border}
        >
          <Text
            type={TextTypeEnum.caption}
            size={TextSizeEnum.small}
            weight={TextWeightEnum.bold}
            color={theme.palette.Grey70}
          >
            {getText("TimeSlider.Now")}
          </Text>
        </Button>
        <TimeRange
          onChange={changeTime}
          onMouseUp={() => props.onAfterChange?.(new Date(value))}
          onTouchEnd={() => props.onAfterChange?.(new Date(value))}
          min={startDate}
          max={endDate}
          value={value}
          color={"red"}
          step="1"
          style={{ width: "100%" }}
        />
        <Button
          isCustom
          height="25px"
          padding="0 3px"
          onClick={() => shiftTime(30)}
          buttonType={ButtonType.Border}
        >
          <Text
            type={TextTypeEnum.caption}
            size={TextSizeEnum.small}
            weight={TextWeightEnum.bold}
            color={theme.palette.Grey60}
          >
            +30'
          </Text>
        </Button>
      </HBox>
    </>
  );
};

export default TimeSlider;
