import FlightList from "../FlightList/FlightList";
import { MapOption } from "./IMapOptions";
import { MapOptionsModalsProps } from "./IMapOptionsModals";
import ReleaseList from "../ReleaseList/ReleaseList";

const MapOptionsModals = ({
  mapOption,
  onClose,
  onConfirm,
}: MapOptionsModalsProps) => {
  switch (mapOption) {
    case MapOption.FLIGHT: {
      return (
        <FlightList
          isVisible={true}
          onClose={() => onClose(MapOption.FLIGHT)}
          onConfirm={(data) => onConfirm({ mapOption: MapOption.FLIGHT, data })}
        />
      );
    }
    case MapOption.RELEASE: {
      return (
        <ReleaseList
          isVisible={true}
          isCote={false}
          isSelect={false}
          onClose={() => onClose(MapOption.RELEASE)}
          onConfirm={(data) =>
            onConfirm({ mapOption: MapOption.RELEASE, data })
          }
        />
      );
    }
    case MapOption.COTE: {
      return (
        <ReleaseList
          isVisible={true}
          isCote={true}
          isSelect={false}
          onClose={() => onClose(MapOption.RELEASE)}
          onConfirm={(data) =>
            onConfirm({ mapOption: MapOption.RELEASE, data })
          }
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default MapOptionsModals;
