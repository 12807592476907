import { Button, ButtonType } from "../../../styles/Button.styled";
import {
  IoArrowUp,
  IoEllipseOutline,
  IoEyeOutline,
  IoRainyOutline,
  IoThermometerOutline,
} from "react-icons/io5";
import { useEffect, useState } from "react";

import Formatter from "../../../utils/Formatter";
import HalfModal from "../../HalfModal/HalfModal";
import { ITag } from "../../../models/Tag";
import ListItem from "../../ListItem/ListItem";
import { MarkerInformationProps } from "./IMarkerInformation";
import Text from "../../Text/Text";
import TextSizeEnum from "../../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../../models/enums/TextWeightEnum";
import { VBox } from "../../../styles/Flexbox.styled";
import { getText } from "../../../locales/initI18n";
import { getWeather } from "../../../services/WeatherService";
import { useQuery } from "@tanstack/react-query";
import { useTheme } from "styled-components";

const MarkerInformation = ({
  isVisible,
  marker,
  onClose,
  isMapWeatherVisible,
  onChangeMapWeatherVisibility,
  mapTime,
}: MarkerInformationProps) => {
  const theme = useTheme();
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const [isWeatherVisible, setIsWeatherVisible] = useState<boolean>(true);

  const getMarkerWeather = async () => {
    if (!marker) return;
    return getWeather(marker, mapTime);
  };

  const { data: weather, isLoading } = useQuery({
    queryKey: [
      "weather",
      marker?.latitude,
      marker?.longitude,
      mapTime.toISOString(),
    ],
    queryFn: getMarkerWeather,
    enabled: isVisible && !!marker,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const getTitle = () => {
    if (!marker) return "";
    const tag = marker as ITag;
    if (tag.name) return tag.name;
    return getText("MarkerInformation_title");
  };

  const getDescription = () => {
    if (!marker) return "";
    const tag = marker as ITag;
    if (tag.remarks) {
      return tag.remarks;
    }
    return `${Number(marker.latitude).toFixed(6)}, ${Number(
      marker.longitude
    ).toFixed(6)}`;
  };

  useEffect(() => {
    if (isMapWeatherVisible !== isWeatherVisible)
      setIsWeatherVisible(isMapWeatherVisible);
  }, [isMapWeatherVisible]);

  if (!isVisible || !marker) return null;
  return (
    <HalfModal
      visible
      isOutsideClickable
      heightDivider={3.0}
      onClose={onClose}
      busy={isLoading}
      customButton={
        <Button
          isCustom={true}
          buttonType={ButtonType.Primary}
          height="50px"
          width="130px"
          padding="0 10px 0 10px"
          onClick={() => {
            onChangeMapWeatherVisibility(!isWeatherVisible);
            setIsWeatherVisible(!isWeatherVisible);
          }}
        >
          <Text
            type={TextTypeEnum.caption}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.bold}
            color={theme.palette.White}
            textAlign="center"
            textWrap="wrap"
          >
            {isWeatherVisible
              ? getText("FlightMarkerInformation.HideWeather")
              : getText("FlightMarkerInformation.ShowWeather")}
          </Text>
        </Button>
      }
      onExpand={(expand: boolean) => setIsExpand(expand)}
      Padding="0"
    >
      <VBox Margin="40px 0 0 20px">
        <Text
          type="body"
          size="large"
          weight="bold"
          color={theme.palette.Grey90}
        >
          {getTitle()}
        </Text>
        <Text
          type={TextTypeEnum.body}
          size={TextSizeEnum.medium}
          weight={TextWeightEnum.regular}
          color={theme.palette.Grey70}
        >
          {getDescription()}
        </Text>
      </VBox>
      <VBox overflowY="auto" visible={!isExpand}>
        <ListItem
          busy={isLoading}
          padding="5px 20px"
          title={getText("FlightMarkerInformation.WindSpeed")}
          rTitle={getText("FlightMarkerInformation.Temperature")}
          description={Formatter.WindSpeedAndDirection(
            weather?.windSurface,
            weather?.WindDirectionSurface
          )}
          rDescription={Formatter.Temperature(weather?.tempSurface)}
        />
        <ListItem
          darkest
          busy={isLoading}
          padding="5px 20px"
          title={getText("Pressure")}
          rTitle={getText("Liquid")}
          description={
            weather?.pressure ? `${weather.pressure.toFixed(2)}mb` : "-"
          }
          rDescription={Formatter.Liquid(weather?.liquid)}
        />
      </VBox>
      <VBox overflowY="auto" visible={isExpand}>
        <ListItem
          title={getText("Temperature")}
          description={Formatter.Temperature(weather?.tempSurface)}
          icon={<IoThermometerOutline size={24} color={theme.palette.Grey50} />}
          darkest={true}
        />
        <ListItem
          title={getText("Temperature100m")}
          description={Formatter.Temperature(weather?.temp100m)}
          icon={<IoThermometerOutline size={24} color={theme.palette.Grey50} />}
          darkest={true}
        />
        <ListItem
          title={getText("WindSpeed")}
          description={Formatter.WindSpeedAndDirection(
            weather?.windSurface,
            weather?.WindDirectionSurface
          )}
          icon={
            <IoArrowUp
              style={{
                transform: `rotate(${weather?.WindDirectionSurface || 0}deg)`,
              }}
              size={24}
              color={theme.palette.Grey50}
            />
          }
          darkest={false}
        />
        <ListItem
          title={getText("WindSpeed100m")}
          description={Formatter.WindSpeedAndDirection(
            weather?.wind100m,
            weather?.WindDirection100m
          )}
          icon={
            <IoArrowUp
              style={{
                transform: `rotate(${weather?.WindDirection100m || 0}deg)`,
              }}
              size={24}
              color={theme.palette.Grey50}
            />
          }
          darkest={false}
        />
        <ListItem
          title={getText("Liquid")}
          description={Formatter.Liquid(weather?.liquid)}
          icon={<IoRainyOutline size={24} color={theme.palette.Grey50} />}
          darkest={true}
        />
        <ListItem
          title={getText("Pressure")}
          description={
            weather?.pressure ? `${weather.pressure.toFixed(2)}mb` : "-"
          }
          icon={<IoEllipseOutline size={24} color={theme.palette.Grey50} />}
          darkest={false}
        />
        <ListItem
          title={getText("Humidity")}
          description={
            weather?.humidity ? `${weather.humidity.toFixed(2)}%` : "-"
          }
          icon={<IoEllipseOutline size={24} color={theme.palette.Grey50} />}
          darkest={true}
        />
        <ListItem
          title={getText("Visibility")}
          description={
            weather?.visibility ? `${weather.visibility.toFixed(2)} km` : "-"
          }
          icon={<IoEyeOutline size={24} color={theme.palette.Grey50} />}
          darkest={false}
        />
      </VBox>
    </HalfModal>
  );
};

export default MarkerInformation;
