import {
  AddPigeonFormData,
  AddPigeonFormModalEnum,
  AddPigeonFormProps,
} from "./IAddPigeonForm";
import { Button, ButtonType } from "../../../styles/Button.styled";
import ColorOptions, { getInitialColor } from "./ColorOptions";
import { Field, Form, FormInstance } from "houseform";
import { HBox, VBox } from "../../../styles/Flexbox.styled";
import SexOptions, { getInitialSex } from "./SexOption";
import StateOptions, { getInitialState } from "./StateOptions";
import { addPigeon, updatePigeon } from "../../../services/PigeonService";
import { useRef, useState } from "react";
import {
  validateObjectRequired,
  validateRequired,
} from "../../../utils/ValidationHelper";

import { AxiosResponse } from "axios";
import Checkbox from "../../Checkbox";
import DateInput from "../../DateInput/DateInput";
import Friends from "../Friends/Friends";
import HalfModal from "../../HalfModal/HalfModal";
import { IFarmer } from "../../../services/IFollowService";
import { IUser } from "../../../services/IUserService";
import { IoInformationCircleOutline } from "react-icons/io5";
import ListItem from "../../ListItem/ListItem";
import OtherBreederHelpModal from "./OtherBreederHelpModal";
import { OtherOwnerEnum } from "./OtherOwnerEnum";
import Pigeon from "../../../models/Pigeon";
import { PigeonColorEnum } from "../../../models/enums/PigeonColorEnum";
import { PigeonSexEnum } from "../../../models/enums/PigeonSexEnum";
import { PigeonStateEnum } from "../../../models/enums/PigeonStateEnum";
import Select from "../../Select/Select";
import SelectOption from "../../../models/SelectOption";
import TextFormInput from "../../Form/TextFormInput";
import { getText } from "../../../locales/initI18n";
import { useQuery } from "@tanstack/react-query";
import { useTheme } from "styled-components";
import useUser from "../../../hooks/useUser";

const AddPigeonForm = ({ pigeon, ...props }: AddPigeonFormProps) => {
  const form = useRef<FormInstance<AddPigeonFormData>>(null);
  const theme = useTheme();
  const [otherOwner, setOtherOwner] = useState(OtherOwnerEnum.None);
  const [modal, setModal] = useState(AddPigeonFormModalEnum.None);
  const [owner, setOwner] = useState<IFarmer | undefined>(undefined);
  const { data: user } = useUser();

  const onSubmit = async (data: AddPigeonFormData) => {
    const addedPigoen = new Pigeon().fillData(data);
    addedPigoen.name = addedPigoen.name || data.ring;
    addedPigoen.color = data.color.key as PigeonColorEnum;
    addedPigoen.sex = data.sex.key as PigeonSexEnum;
    addedPigoen.state = data.state.key as PigeonStateEnum;
    if (otherOwner === OtherOwnerEnum.Friend && owner) {
      addedPigoen.ownerId = owner.id;
    }
    if (!data.ownerName) {
      addedPigoen.ownerName = user?.username || "";
    }
    addedPigoen.prepareForSave();
    if (props.isEdit) {
      addedPigoen.id = pigeon?.id || 0;
      await updatePigeon(addedPigoen);
    } else await addPigeon(addedPigoen);
    props.onConfirm(addedPigoen);
  };

  const isEdit = () => !!pigeon;

  return (
    <HalfModal
      visible={props.isVisible}
      onClose={props.onClose}
      title={
        pigeon
          ? getText("AddPigeonForm.modal.title.edit")
          : getText("AddPigeonForm.modal.title")
      }
      description={getText("AddPigeonForm.modal.description")}
      heightDivider={1.5}
    >
      <Friends
        isVisible={modal === AddPigeonFormModalEnum.Friends}
        onConfirm={(data) => {
          setOwner(data[0]);
          setModal(AddPigeonFormModalEnum.None);
        }}
        onClose={() => setModal(AddPigeonFormModalEnum.None)}
        multiSelect={false}
      />
      <OtherBreederHelpModal
        isVisible={modal === AddPigeonFormModalEnum.OtherBreederHelp}
        onClose={() => {}}
        onConfirm={() => setModal(AddPigeonFormModalEnum.None)}
      />
      <VBox>
        <Form onSubmit={onSubmit} ref={form}>
          {({ submit, value: allValue }) => (
            <>
              <VBox Padding="0px 20px 0 20px" gap="10px">
                <Field<string> name="name" initialValue={pigeon?.name || ""}>
                  {(props) => {
                    return (
                      <TextFormInput
                        title={getText("AddPigeonForm.form.name.title")}
                        placeholder={getText(
                          "AddPigeonForm.form.name.placeholder"
                        )}
                        {...props}
                      />
                    );
                  }}
                </Field>
                <Field<SelectOption>
                  name="sex"
                  initialValue={getInitialSex(pigeon?.sex)}
                  onSubmitValidate={validateObjectRequired()}
                >
                  {({ value, setValue, errors, isValid, onBlur }) => {
                    return (
                      <HBox width="100%" gap="10px" zIndex={2}>
                        <Select
                          value={value}
                          onSelect={setValue}
                          options={SexOptions}
                          errors={errors}
                        />
                      </HBox>
                    );
                  }}
                </Field>
                <Field<string>
                  name="ring"
                  initialValue={pigeon?.ring || ""}
                  onSubmitValidate={validateRequired()}
                >
                  {(props) => {
                    return (
                      <TextFormInput
                        title={getText("AddPigeonForm.form.ring.title")}
                        placeholder={getText(
                          "AddPigeonForm.form.ring.placeholder"
                        )}
                        {...props}
                      />
                    );
                  }}
                </Field>
                <Field<SelectOption>
                  name="color"
                  initialValue={getInitialColor(pigeon?.color)}
                  onSubmitValidate={validateObjectRequired()}
                >
                  {({ value, setValue, errors, isValid, onBlur }) => {
                    return (
                      <HBox width="100%" gap="10px" zIndex={1}>
                        <Select
                          fixedHeight="250px"
                          value={value}
                          onSelect={setValue}
                          options={ColorOptions}
                          errors={errors}
                        />
                      </HBox>
                    );
                  }}
                </Field>
                <Field<SelectOption>
                  name="state"
                  initialValue={getInitialState(pigeon?.state)}
                >
                  {({ value, setValue, errors, isValid, onBlur }) => {
                    return (
                      <HBox
                        visible={isEdit()}
                        width="100%"
                        gap="10px"
                        zIndex={1}
                      >
                        <Select
                          fixedHeight="250px"
                          value={value}
                          onSelect={setValue}
                          options={StateOptions}
                          errors={errors}
                        />
                      </HBox>
                    );
                  }}
                </Field>
                <Field<string> name="breed" initialValue={pigeon?.breed || ""}>
                  {(props) => {
                    return (
                      <HBox visible={isEdit()}>
                        <TextFormInput
                          title={getText("AddPigeonForm.form.breed.title")}
                          placeholder={getText(
                            "AddPigeonForm.form.breed.placeholder"
                          )}
                          {...props}
                        />
                      </HBox>
                    );
                  }}
                </Field>
                <Field<string>
                  name="eyeColor"
                  initialValue={pigeon?.eyeColor || ""}
                >
                  {(props) => {
                    return (
                      <HBox visible={isEdit()}>
                        <TextFormInput
                          title={getText("AddPigeonForm.form.eyeColor.title")}
                          placeholder={getText(
                            "AddPigeonForm.form.eyeColor.placeholder"
                          )}
                          {...props}
                        />
                      </HBox>
                    );
                  }}
                </Field>
                <Field<string>
                  name="year"
                  initialValue={pigeon?.year?.toString?.() || ""}
                >
                  {(props) => {
                    return (
                      <HBox visible={isEdit()}>
                        <TextFormInput
                          type="number"
                          title={getText("AddPigeonForm.form.year.title")}
                          placeholder={getText(
                            "AddPigeonForm.form.year.placeholder"
                          )}
                          {...props}
                        />
                      </HBox>
                    );
                  }}
                </Field>
                <Field<string | Date>
                  name="hatchingDate"
                  initialValue={
                    pigeon?.hatchingDate || new Date().toISOString()
                  }
                >
                  {({ value, setValue, onBlur, isValid, errors }) => (
                    <HBox width="100%" gap="10px" visible={isEdit()}>
                      <DateInput
                        isErrorState={!isValid}
                        value={value}
                        onBlur={onBlur}
                        onChange={(e: Date) => setValue(e)}
                        placeholder={getText(
                          "AddPigeonForm.form.hatchingDate.placeholder"
                        )}
                        type="date"
                        errors={errors}
                      >
                        {getText("AddPigeonForm.form.hatchingDate.title")}
                      </DateInput>
                    </HBox>
                  )}
                </Field>
                <VBox
                  BorderRadius="20px"
                  Padding="10px"
                  gap="5px"
                  border={`1px solid ${theme.palette.Grey10}`}
                  position="relative"
                >
                  <HBox position="absolute" Top="-15px" Right="-15px">
                    <Button
                      height="30px"
                      isCustom={true}
                      buttonType={ButtonType.Primary}
                      onClick={() =>
                        setModal(AddPigeonFormModalEnum.OtherBreederHelp)
                      }
                    >
                      <IoInformationCircleOutline size={20} />
                    </Button>
                  </HBox>
                  <Checkbox
                    onClick={() =>
                      setOtherOwner(
                        otherOwner === OtherOwnerEnum.Friend
                          ? OtherOwnerEnum.None
                          : OtherOwnerEnum.Friend
                      )
                    }
                    state={otherOwner === OtherOwnerEnum.Friend}
                    title={getText("AddPigeonForm.form.otherOwner.title")}
                  />
                  <VBox
                    gap="10px"
                    visible={otherOwner === OtherOwnerEnum.Friend}
                  >
                    <Field<string> name="ownerName" initialValue="">
                      {(props) => {
                        return (
                          <TextFormInput
                            title={getText(
                              "AddPigeonForm.form.otherOwnerSimple.title"
                            )}
                            placeholder={getText(
                              "AddPigeonForm.form.otherOwnerSimple.placeholder"
                            )}
                            {...props}
                          />
                        );
                      }}
                    </Field>
                    <ListItem
                      darkest
                      borderRadius="20px"
                      title={getText("AddPigeonForm.form.otherOwnerUser.title")}
                      description={
                        owner?.username ||
                        getText("AddPigeonForm.form.otherOwnerUser.placeholder")
                      }
                      onClick={() => setModal(AddPigeonFormModalEnum.Friends)}
                    />
                  </VBox>
                </VBox>
                <Button
                  onClick={submit}
                  buttonType={ButtonType.Primary}
                  width="100%"
                >
                  {pigeon
                    ? getText("AddPigeonForm.form.editButton.title")
                    : getText("AddPigeonForm.form.addButton.title")}
                </Button>
              </VBox>
            </>
          )}
        </Form>
      </VBox>
    </HalfModal>
  );
};

export default AddPigeonForm;
