import { Button, ButtonType } from "../../styles/Button.styled";
import { HBox, VBox } from "../../styles/Flexbox.styled";

import AddPigeonForm from "../../components/modals/AddPigeonForm/AddPigeonForm";
import { IoCreate } from "react-icons/io5";
import ListItem from "../../components/ListItem/ListItem";
import ObjectIdentifier from "../../components/ObjectIdentifier";
import Pigeon from "../../models/Pigeon";
import Text from "../../components/Text/Text";
import { formatDate } from "../../utils/VariableHelper";
import { getText } from "../../locales/initI18n";
import { useState } from "react";

interface GeneralProps {
  pigeon: Pigeon;
}
const General = ({ pigeon }: GeneralProps) => {
  const [isAddForm, setIsAddForm] = useState(false);
  const getPigeonData = (data?: string | number) => {
    return data?.toString() || "-";
  };

  const onUpdatePigeon = (updatedPigeon: Pigeon) => {
    pigeon.update(updatedPigeon);
    setIsAddForm(false);
  };
  return (
    <VBox height="100%" gap="10px">
      {isAddForm && (
        <AddPigeonForm
          isVisible={true}
          isEdit={true}
          onClose={() => setIsAddForm(false)}
          onConfirm={onUpdatePigeon}
          pigeon={pigeon}
        />
      )}
      <HBox Padding="0 10px" justifyContent="space-between" alignItems="center">
        <VBox>
          <ObjectIdentifier
            title={getPigeonData(pigeon?.ring)}
            text={getPigeonData(pigeon?.name)}
          />
        </VBox>
        <Button
          height="auto"
          padding="10px"
          buttonType={ButtonType.Primary}
          onClick={() => setIsAddForm(true)}
          isCustom
        >
          <HBox gap="5px">
            <IoCreate />
            <Text>{getText("Pigeon.general.edit.title")}</Text>
          </HBox>
        </Button>
      </HBox>
      <VBox Padding="0 0 20px 0" gap="5px" overflowY="scroll" flexGrow="1">
        <ListItem
          borderRadius="10px"
          title={getText("General.Sex")}
          description={getPigeonData(pigeon?.ReadableSex)}
        />
        <ListItem
          borderRadius="10px"
          title={getText("General.State")}
          description={pigeon?.ReadableState}
        />
        <ListItem
          borderRadius="10px"
          title={getText("General.Color")}
          description={pigeon?.ReadableColor}
        />
        <ListItem
          borderRadius="10px"
          title={getText("General.Owner")}
          description={pigeon?.OwnerName}
        />
        <ListItem
          borderRadius="10px"
          title={getText("General.Breed")}
          description={getPigeonData(pigeon?.breed)}
        />
        <ListItem
          borderRadius="10px"
          title={getText("General.EyeColor")}
          description={getPigeonData(pigeon?.eyeColor)}
        />
        <ListItem
          borderRadius="10px"
          title={getText("General.Year")}
          description={getPigeonData(pigeon?.year)}
        />
        <ListItem
          borderRadius="10px"
          title={getText("General.HatchingDate")}
          description={getPigeonData(formatDate(pigeon?.hatchingDate))}
        />
      </VBox>
    </VBox>
  );
};

export default General;
