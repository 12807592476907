import { authDelete, authGet, authPost, get } from "./BaseService";

import { IFarmer } from "./IFollowService";
import Page from "../models/Page";
import PageOptions from "../models/PageOptions";

export const getFollowers = () => {
  return get(`follower/followers`);
};

export const getFollowing = () => {
  return get(`follower/following`);
};

export const follow = (id: number) => {
  return authPost(`follower/follow/${id}`);
};

export const unfollow = (id: number) => {
  return authDelete(`follower/follow/${id}`);
};

export const getAllFarmers = async (options: PageOptions, filters = "") => {
  const result = await authGet<object>(
    `follower/allUsers?page=${options.page}&take=${options.take}&filters=${filters}`
  );

  return new Page<IFarmer>().fillData(result.data);
};

export const getFriends = async () => {
  const data = await authGet<IFarmer[]>(
    `follower/allUsers?filters=onlyFriends||eq||true`
  );
  return data.data;
};

export const getFarmer = async (userId: number) => {
  const farmer = await authGet<IFarmer>(`follower/farmer/${userId}`);
  return farmer.data;
};
