import { memo, useEffect, useState } from "react";

import ChildFilter from "../../../models/Filter/ChildFilter";
import { DistrictListProps } from "./IDistrictList";
import { FilterName } from "../../../models/enums/FilterName";
import HalfModal from "../../HalfModal/HalfModal";
import ListItem from "../../ListItem/ListItem";
import RegionDetector from "../../../locales/RegionDetector";
import { VBox } from "../../../styles/Flexbox.styled";
import { getDistricts } from "../../../services/DistrictService";
import { getText } from "../../../locales/initI18n";
import { useQuery } from "@tanstack/react-query";

const DistrictList: React.FC<DistrictListProps> = ({
  isVisible,
  onClose,
  onConfirm,
  country,
  distinctCount,
}) => {
  const [filters, setFilters] = useState<string>("");

  const queryFn = async () => {
    if (!filters) return [];
    const data = await getDistricts(filters);
    distinctCount?.(data.length);
    return data;
  };

  const {
    data: districts,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["district", { filters }],
    queryFn,
  });

  useEffect(() => {
    if (!country) return;
    const filter = new ChildFilter(FilterName.Country, country);
    setFilters(filter.transform());
    // refetch();
  }, [country]);

  return (
    <HalfModal
      isExpander={false}
      heightDivider={1.1}
      visible={isVisible}
      busy={isLoading}
      title={getText("General.District")}
      onClose={onClose}
      Padding="0"
      zIndex={2}
    >
      <VBox>
        {districts?.map((district, index) => (
          <ListItem
            key={`District-${district.id}`}
            title={district.name}
            darkest={index % 2 === 0}
            description={RegionDetector.GetRegionName(district.country)}
            onClick={() => onConfirm(district)}
          />
        ))}
      </VBox>
    </HalfModal>
  );
};

export default memo((props: DistrictListProps) => <DistrictList {...props} />);
