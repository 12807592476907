import styled from "styled-components";

export const TimeRange = styled.input.attrs({ type: "range" })`
  -webkit-appearance: none;
  &::-webkit-slider-runnable-track {
    height: 5px;
    width: 100%;
    background: ${({ theme }) => theme.palette.Grey10};
    border-radius: 5px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 20px;
    width: 20px;
    border-radius: 5px;
    margin-top: -7px;
    background: ${({ theme }) => theme.palette.Primary80};
  }

  &::-moz-range-track {
    width: 100%;
    height: 5px;
    background: ${({ theme }) => theme.palette.Grey10};
    border-radius: 5px;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    border: none;
    height: 20px;
    width: 20px;
    border-radius: 5px;
    margin-top: -7px;
    background: ${({ theme }) => theme.palette.Primary80};
  }
`;
