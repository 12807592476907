import Pigeon from "./Pigeon";
import PigeonNoteBase from "./PigeonNoteBase";

export default class PigeonAchievement extends PigeonNoteBase {
  pigeon!: Pigeon;
  pigeonId!: number;
  useInPedigree!: boolean;
  year!: number;

  fillData(data: object): this {
    super.fillData(data);
    this.year = Number(this.year);
    if (this.pigeon) {
      this.pigeon = new Pigeon().fillData(this.pigeon);
    }
    return this;
  }
}
