import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";
import reducers from "./reducers";

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: { ...reducers },
});

// Action creators are generated for each case reducer function
export const { setError } = errorSlice.actions;

export default errorSlice.reducer;
