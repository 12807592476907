import { HBox, VBox } from "../../styles/Flexbox.styled";
import {
  IoDocumentTextOutline,
  IoMapOutline,
  IoPeopleOutline,
  IoPersonOutline,
} from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";

import { ComponentType } from "react";
import Text from "../../components/Text/Text";
import { getText } from "../../locales/initI18n";
import { useTheme } from "styled-components";

function withNavbar<T extends {}>(Component: ComponentType<T>) {
  return (props: T) => {
    const theme = useTheme();
    const location = useLocation();
    const isCurrentTab = (tab: string) => location.pathname.includes(tab);
    const getTextColor = (tab: string) => {
      if (isCurrentTab(tab)) return theme.palette.Primary100;
      return theme.palette.Grey80;
    };
    const getIndicatorColor = (tab: string) => {
      if (isCurrentTab(tab)) return theme.palette.Primary40;
      return theme.palette.White;
    };

    return (
      <VBox
        Padding="0 0 80px 0"
        position="relative"
        height="100%"
        width="100%"
        overflowY="hidden"
      >
        <Component {...props} />
        <HBox
          position="absolute"
          Bottom="0"
          width="100%"
          height="80px"
          justifyContent="space-around"
          alignItems="center"
          Padding="0 0px"
          backgroundColor={theme.palette.White}
          shadow="small"
          zIndex={1}
        >
          <Link
            to="/dashboard/map"
            style={{ height: "100%", width: "25%", textDecoration: "none" }}
          >
            <VBox
              position="relative"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <IoMapOutline size={30} color={getTextColor("map")} />
              <Text
                type="body"
                size="medium"
                weight="regular"
                color={getTextColor("map")}
              >
                {getText("Map")}
              </Text>
              <HBox
                position="absolute"
                Bottom="-5px"
                height="20px"
                width="40px"
                BorderRadius="10px 10px 0 0"
                backgroundColor={getIndicatorColor("map")}
              />
            </VBox>
          </Link>
          <Link
            to="/dashboard/pigeons"
            style={{ height: "100%", width: "25%", textDecoration: "none" }}
          >
            <VBox
              position="relative"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <IoDocumentTextOutline
                size={30}
                color={getTextColor("pigeons")}
              />
              <Text
                type="body"
                size="medium"
                weight="regular"
                color={getTextColor("pigeons")}
              >
                {getText("Notes")}
              </Text>
              <HBox
                position="absolute"
                Bottom="-5px"
                height="20px"
                width="40px"
                BorderRadius="10px 10px 0 0"
                backgroundColor={getIndicatorColor("pigeons")}
              />
            </VBox>
          </Link>
          <Link
            to="/dashboard/farmers"
            style={{ height: "100%", width: "25%", textDecoration: "none" }}
          >
            <VBox
              position="relative"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <IoPeopleOutline size={30} color={getTextColor("farmers")} />
              <Text
                type="body"
                size="medium"
                weight="regular"
                color={getTextColor("farmers")}
              >
                {getText("General.Breeders")}
              </Text>
              <HBox
                position="absolute"
                Bottom="-5px"
                height="20px"
                width="40px"
                BorderRadius="10px 10px 0 0"
                backgroundColor={getIndicatorColor("farmers")}
              />
            </VBox>
          </Link>
          <Link
            to="/dashboard/profile"
            style={{ height: "100%", width: "25%", textDecoration: "none" }}
          >
            <VBox
              position="relative"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <IoPersonOutline size={30} color={getTextColor("profile")} />
              <Text
                type="body"
                size="medium"
                weight="regular"
                color={getTextColor("profile")}
              >
                {getText("Profile")}
              </Text>
              <HBox
                position="absolute"
                Bottom="-5px"
                height="20px"
                width="40px"
                BorderRadius="10px 10px 0 0"
                backgroundColor={getIndicatorColor("profile")}
              />
            </VBox>
          </Link>
        </HBox>
      </VBox>
    );
  };
}

export default withNavbar;
