import { Button, ButtonType } from "../../styles/Button.styled";
import {
  IoChevronBackOutline,
  IoChevronDownOutline,
  IoCloseOutline,
} from "react-icons/io5";
import { ModalContent, StyledModal } from "../../styles/HalfModal.styled";
import { useEffect, useState } from "react";

import { CloseIconEnum } from "./CloseIconEnum";
import { HalfModalProps } from "./IHalfModal";
import Loader from "../Loader/loader";
import Text from "../Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { VBox } from "../../styles/Flexbox.styled";
import { getText } from "../../locales/initI18n";
import { useTheme } from "styled-components";

/**
 * HalfModal Component
 *
 * @component
 * @description The HalfModal component is a customizable modal component that can be expanded or collapsed. It is designed to cover half of the screen, but the height can be adjusted. The modal can contain any child components and has a title. It also has a close button with customizable icons.
 * @param {boolean} props.visible - Determines if the modal is visible or not.
 * @param {ReactNode} props.children - The content of the modal.
 * @param {number} props.heightDivider - The value by which the window height is divided to calculate the modal height. Default is 2.
 * @param {ButtonType} props.closeType - The type of the close button. Default is `ButtonType.Primary`.
 * @param {CloseIconEnum} props.closeIcon - The icon of the close button. Default is `CloseIconEnum.CLOSE`.
 * @param {string} props.Padding - The padding of the modal content. Default is "40px 20px 20px 20px".
 * @param {boolean} props.busy - If true, a loader is displayed in the modal. Default is false.
 * @param {boolean} props.isExpander - If true, the modal can be expanded or collapsed. Default is true.
 * @param {boolean} props.isOutsideClickable - If true, clicking outside the modal will not close it. Default is false.
 * @param {number} props.zIndex - The z-index of the modal.
 * @param {string} props.title - The title of the modal.
 * @param {function} props.onClose - The function to be called when the close button is clicked.
 * @param {function} props.onExpand - The function to be called when the modal is expanded or collapsed.
 *
 * @example common example
 * <HalfModal
 *  visible={true}
 *  onClose={() => console.log('Modal closed')}
 * >
 *  <p>Modal content goes here</p>
 * </HalfModal>
 *
 * @example
 * <HalfModal
 *   visible={true}
 *   heightDivider={2}
 *   closeType={ButtonType.Primary}
 *   closeIcon={CloseIconEnum.CLOSE}
 *   Padding="40px 20px 20px 20px"
 *   busy={false}
 *   isExpander={true}
 *   isOutsideClickable={false}
 *   zIndex={1000}
 *   title="Modal Title"
 *   onClose={() => console.log('Modal closed')}
 *   onExpand={(isExpanded) => console.log(`Modal is ${isExpanded ? 'expanded' : 'collapsed'}`)}
 * >
 *   <p>Modal content goes here</p>
 * </HalfModal>
 *
 */
const HalfModal = ({
  visible,
  children,
  customButton,
  heightDivider = 2,
  closeType = ButtonType.Primary,
  closeIcon = CloseIconEnum.CLOSE,
  Padding = "20px 20px 20px 20px",
  busy = false,
  isExpander = true,
  isOutsideClickable = false,
  zIndex,
  title,
  description,
  onClose,
  onExpand,
}: HalfModalProps) => {
  const EXPANDED_VALUE = 1.1;
  const height = window.innerHeight;
  const theme = useTheme();
  const [insideHeightDivider, setInsideHeightDivider] = useState(heightDivider);
  const [isExpand, setIsExpand] = useState(heightDivider === EXPANDED_VALUE);
  const expandModal = () => {
    setInsideHeightDivider(EXPANDED_VALUE);
    setIsExpand(true);
    onExpand?.(true);
  };
  const collapseModal = () => {
    const divide = heightDivider === EXPANDED_VALUE ? 2 : heightDivider;
    setInsideHeightDivider(divide);
    setIsExpand(false);
    onExpand?.(false);
  };
  const manageModalHeight = () => {
    if (insideHeightDivider !== EXPANDED_VALUE) expandModal();
    else collapseModal();
  };

  const getCloseIcon = () => {
    switch (closeIcon) {
      case CloseIconEnum.CLOSE:
        return <IoCloseOutline size="24px" />;
      case CloseIconEnum.RETURN:
        return <IoChevronBackOutline size="24px" />;
      case CloseIconEnum.COLLAPSE:
        return <IoChevronDownOutline size="24px" />;
    }
  };

  useEffect(() => {
    setInsideHeightDivider(heightDivider);
    setIsExpand(heightDivider === EXPANDED_VALUE);
  }, [heightDivider]);

  return (
    <StyledModal
      style={{
        overlay: {
          zIndex,
          position: isOutsideClickable ? "static" : "fixed",
          backgroundColor: "rgb(94, 94, 97, 0.3)",
        },
      }}
      ariaHideApp={false}
      appElement={document.body}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={!isOutsideClickable}
      isOpen={visible}
    >
      <VBox justifyContent="flex-end">
        <ModalContent height={`${height / insideHeightDivider}px`}>
          {!!title && (
            <Text
              Padding="40px 20px 0 20px"
              type={TextTypeEnum.h5}
              size={TextSizeEnum.large}
              weight={TextWeightEnum.medium}
              color={theme.palette.Grey90}
            >
              {title}
            </Text>
          )}
          {!!description && (
            <Text
              Padding="0 20px 5px 20px"
              type={TextTypeEnum.body}
              size={TextSizeEnum.medium}
              weight={TextWeightEnum.regular}
              color={theme.palette.Grey40}
            >
              {description}
            </Text>
          )}
          <VBox overflowY="auto" Padding={Padding} height="100%">
            {!busy && children}
          </VBox>
          <VBox
            alignItems="center"
            justifyContent="center"
            height="100%"
            visible={busy}
          >
            <Loader />
          </VBox>
          {customButton && (
            <VBox
              position="absolute"
              className="halfModal__custom-button"
              // visible={isExpander}
              Top="-30px"
              Left="20px"
              Padding="0 5px 0 5px"
              height="60px"
              backgroundColor={theme.palette.White}
              justifyContent="center"
              alignItems="center"
              BorderRadius="20px"
            >
              {customButton}
            </VBox>
          )}
          <VBox
            position="absolute"
            className="halfModal__expand-button"
            visible={isExpander}
            Top="-30px"
            Right="110px"
            Padding="0 5px 0 5px"
            height="60px"
            backgroundColor={theme.palette.White}
            justifyContent="center"
            alignItems="center"
            BorderRadius="20px"
          >
            <Button
              isCustom={true}
              buttonType={ButtonType.Primary}
              height="50px"
              padding="0 10px 0 10px"
              onClick={manageModalHeight}
            >
              {isExpand ? (
                <Text
                  type="caption"
                  size="large"
                  weight="bold"
                  color={theme.palette.White}
                >
                  {getText("HalfModal.HideAction")}
                </Text>
              ) : (
                <Text
                  type="body"
                  size="small"
                  weight="bold"
                  color={theme.palette.White}
                >
                  {getText("HalfModal.Enlarge")}
                </Text>
              )}
            </Button>
          </VBox>
          <VBox
            position="absolute"
            Top="-30px"
            Right="20px"
            width="70px"
            height="70px"
            backgroundColor={theme.palette.White}
            justifyContent="center"
            alignItems="center"
            BorderRadius="20px"
            visible={closeIcon !== CloseIconEnum.WITHOUT_BUTTON}
          >
            <Button
              isCustom={true}
              buttonType={closeType}
              height="60px"
              width="60px"
              onClick={onClose}
            >
              {getCloseIcon()}
            </Button>
          </VBox>
        </ModalContent>
      </VBox>
    </StyledModal>
  );
};

export default HalfModal;
