import {
  IoCreateOutline,
  IoEllipsisHorizontal,
  IoLogOutOutline,
  IoTrashBinOutline,
} from "react-icons/io5";

import HalfModal from "../../HalfModal/HalfModal";
import ListItem from "../../ListItem/ListItem";
import { ListItemAction } from "../../ListItem/IListItem";
import { MoreProfileOptionsProps } from "./IMoreProfileOptions";
import { ProfileModal } from "../../../models/enums/ProfileModal";
import { VBox } from "../../../styles/Flexbox.styled";
import { getText } from "../../../locales/initI18n";
import { useTheme } from "styled-components";

const MoreProfileOptions = ({
  isVisible,
  onClose,
  onConfirm,
}: MoreProfileOptionsProps) => {
  const theme = useTheme();
  return (
    <HalfModal
      heightDivider={1.1}
      Padding="0px 0 20px 0"
      isExpander={false}
      onClose={onClose}
      visible={isVisible}
      title={getText("Profile.View.MoreProfileOptionsTitle")}
    >
      <VBox>
        <ListItem
          rIcon={<IoCreateOutline color={theme.palette.White} size={20} />}
          title={getText("Profile.EditProfile")}
          description={getText("Profile.View.EditProfile.Description")}
          action={ListItemAction.Primary}
          onClick={() => onConfirm(ProfileModal.EDIT)}
        />
        <ListItem
          darkest
          rIcon={<IoEllipsisHorizontal color={theme.palette.Grey80} />}
          title={getText("Profile.ChangePassword")}
          description={getText("Profile.View.ChangePassword.Description")}
          onClick={() => onConfirm(ProfileModal.CHANGE_PASSWORD)}
          action={ListItemAction.Secondary}
        />
        <ListItem
          rIcon={<IoLogOutOutline color={theme.palette.White} size={20} />}
          title={getText("Logout")}
          description={getText("Profile.View.LogoutDescription")}
          action={ListItemAction.Critical}
          onClick={() => onConfirm(ProfileModal.LOGOUT)}
        />
        <ListItem
          darkest
          rIcon={<IoTrashBinOutline color={theme.palette.White} size={20} />}
          title={getText("Profile.View.DeleteAccountTitle")}
          description={getText("Profile.View.DeleteAccountDescription")}
          action={ListItemAction.Critical}
          onClick={() => onConfirm(ProfileModal.DELETE_ACCOUNT)}
        />
      </VBox>
    </HalfModal>
  );
};

export default MoreProfileOptions;
