import { Button, ButtonType } from "../../../styles/Button.styled";

import Bar from "../../Bar/Bar";
import { BarType } from "../../Bar/IBar";
import ConfirmModalTypeEnum from "../../../models/enums/ConfirmModalTypeEnum";
import ConfirmProps from "./IConfirm";
import HalfModal from "../../HalfModal/HalfModal";
import Text from "../../Text/Text";
import TextSizeEnum from "../../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../../models/enums/TextWeightEnum";
import { VBox } from "../../../styles/Flexbox.styled";
import { getText } from "../../../locales/initI18n";
import { memo } from "react";

/**
 * Confirm Component
 *
 * @component
 * @description A component that serves as a confirmation dialog.
 * @param {boolean} props.isVisible - Determines if the confirm modal is visible.
 * @param {() => void} props.onCancel - The function to be called when the cancel button is clicked. If not provided, the onClose function will be called instead.
 * @param {() => void} props.onClose - The function to be called when the close button is clicked.
 * @param {() => void} props.onConfirm - The function to be called when the confirm button is clicked.
 * @param {ConfirmModalTypeEnum} props.type - The type of the confirm modal.
 * @param {string} props.title - The title of the confirm modal.
 * @param {string} props.description - The description of the confirm modal.
 * @param {string} props.confirmText - The text of the confirm button.
 * @param {string} props.cancelText - The text of the cancel button.
 * @param {boolean} props.isCancel - Determines if the cancel button is visible. Default is true.
 *
 * @returns {JSX.Element} A HalfModal component that serves as a confirmation dialog.
 *
 * @example
 * <Confirm
 *   isVisible={true}
 *   type={ConfirmModalTypeEnum.Change}
 *   title="Confirmation Title"
 *   description="Confirmation Description"
 *   onConfirm={() => console.log('Confirmed')}
 *   confirmText="Yes"
 *   onCancel={() => console.log('Cancelled')}
 *   cancelText="No"
 *   onClose={() => console.log('Closed')}
 *   isCancel={true}
 * />
 */
const Confirm = ({ isCancel = true, ...props }: ConfirmProps) => {
  const setConfirmButtonType = () => {
    switch (props.type) {
      case ConfirmModalTypeEnum.Change:
        return ButtonType.Success;
      case ConfirmModalTypeEnum.Delete:
        return ButtonType.Critical;
      case ConfirmModalTypeEnum.Warn:
        return ButtonType.Critical;
    }
  };
  if (!props.isVisible) return null;
  return (
    <HalfModal
      isExpander={false}
      visible={props.isVisible}
      onClose={props.onClose}
      heightDivider={1.7}
    >
      <VBox gap="10px">
        <Text
          type={TextTypeEnum.h4}
          size={TextSizeEnum.large}
          weight={TextWeightEnum.bold}
        >
          {props.title}
        </Text>
        {props.description && (
          <Bar
            type={
              props.type === ConfirmModalTypeEnum.Change ||
              props.type === ConfirmModalTypeEnum.Warn
                ? BarType.Warn
                : BarType.Critical
            }
            title={getText("Bar_Warning")}
            visible={!!props.description}
          >
            {props.description}
          </Bar>
        )}
        {props.children}
        <VBox gap="20px" Margin="20px 0 0 0">
          <Button
            buttonType={setConfirmButtonType()}
            height="56px"
            width="100%"
            onClick={props.onConfirm}
          >
            {props.confirmText ? props.confirmText : getText("General.Yes")}
          </Button>
          <Button
            visible={isCancel}
            buttonType={ButtonType.Primary}
            height="56px"
            width="100%"
            onClick={() => {
              if (!!props.onCancel) props.onCancel();
              if (!!props.onClose) props.onClose();
            }}
          >
            {props.cancelText ? props.cancelText : getText("General.No")}
          </Button>
        </VBox>
      </VBox>
    </HalfModal>
  );
};

export default memo((props: ConfirmProps) =>
  props.isVisible ? <Confirm {...props} /> : null
);
