import { AddTagFormModalEnum } from "./AddTagFormModalEnum";
import { AddTagFormModalProps } from "./IAddTagForm";
import Confirm from "../Confirm/Confirm";
import ConfirmModalTypeEnum from "../../../models/enums/ConfirmModalTypeEnum";
import { getText } from "../../../locales/initI18n";

const AddTagFormModal = ({
  modal,
  isCote,
  onClose,
  onConfirm,
}: AddTagFormModalProps) => {
  if (modal === AddTagFormModalEnum.None) return null;
  if (modal === AddTagFormModalEnum.Delete) {
    return (
      <Confirm
        isVisible={modal === AddTagFormModalEnum.Delete}
        onClose={onClose}
        onConfirm={() => onConfirm({ modal, data: null })}
        title={
          isCote
            ? getText("AddTagForm.confirmTitleDeleteCote")
            : getText("AddTagForm.confirmTitleDeleteRelease")
        }
        description={
          isCote
            ? getText("AddTagForm.confirmCoteDescDelete")
            : getText("AddTagForm.confirmReleaseDescDelete")
        }
        type={ConfirmModalTypeEnum.Delete}
        confirmText={
          isCote
            ? getText("AddTagForm.confirmCoteConfirmBtnDelete")
            : getText("AddTagForm.confirmRelesaseConfirmBtnDelete")
        }
        cancelText={getText("AddTagForm.confirmCancelBtnDelete")}
      />
    );
  }
  if (modal === AddTagFormModalEnum.Change) {
    return (
      <Confirm
        isVisible={modal === AddTagFormModalEnum.Change}
        onClose={onClose}
        onConfirm={() => onConfirm({ modal, data: null })}
        title={
          isCote
            ? getText("AddTagForm.confirmTitleChangeCote")
            : getText("AddTagForm.confirmTitleChangeRelease")
        }
        description={
          isCote
            ? getText("AddTagForm.confirmCoteDescChange")
            : getText("AddTagForm.confirmReleaseDescChange")
        }
        type={ConfirmModalTypeEnum.Change}
        confirmText={
          isCote
            ? getText("AddTagForm.confirmCoteConfirmBtnChange")
            : getText("AddTagForm.confirmReleaseConfirmBtnChange")
        }
        cancelText={getText("AddTagForm.confirmCancelBtnChange")}
      />
    );
  }
  return null;
};

export default AddTagFormModal;
