import { Flight } from "../models/Flight";
import FlightHelper from "../utils/FlightHelper";
import { HBox } from "../styles/Flexbox.styled";
import HighligtedText from "./HighlightedText/HighlightedText";
import { IUser } from "../services/IUserService";
import { getText } from "../locales/initI18n";
import { useTheme } from "styled-components";

interface FlightHighlightedInfoProps {
  flight?: Flight | null;
  currentUser?: IUser;
}

const FlightHighlightedInfo = ({
  flight,
  currentUser,
}: FlightHighlightedInfoProps) => {
  const theme = useTheme();

  if (!flight || !currentUser) return null;

  return (
    <HBox gap="5px" flexWrap="wrap">
      <HighligtedText
        visible={!FlightHelper.isCurrentUserFlight(flight, currentUser.id)}
        text={flight?.user.username || ""}
        backgroundColor={theme.palette.Yellow100}
      />
      <HighligtedText
        visible={
          FlightHelper.isChild(flight) &&
          !FlightHelper.isCurrentUserFlight(flight, currentUser.id) &&
          !FlightHelper.isParentCurrentUser(flight, currentUser.id)
        }
        text={flight?.parentFlight?.user?.username || ""}
        backgroundColor={theme.palette.Yellow100}
      />
      <HighligtedText
        visible={
          FlightHelper.isCurrentUserPrivateEventChild(flight, currentUser.id) ||
          FlightHelper.isCurrentUserEventChild(flight, currentUser.id)
        }
        text={getText("FlightHighlightedInfo.YouParticipate")}
        backgroundColor={theme.palette.Green80}
      />
      <HighligtedText
        visible={
          !FlightHelper.isCurrentUserFlight(flight, currentUser.id) &&
          FlightHelper.isEventEditPermission(flight, currentUser.id)
        }
        text={getText("FlightHighlightedInfo.AuthorizedToEdit")}
      />
      <HighligtedText
        visible={FlightHelper.isPrivateEventChild(flight)}
        text={getText("FlightHighlightedInfo.PrivateCompetition")}
      />
      <HighligtedText
        visible={FlightHelper.isShared(flight, currentUser.id)}
        text={getText("FlightHighlightedInfo.Shared")}
      />
    </HBox>
  );
};

export default FlightHighlightedInfo;
