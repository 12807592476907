import { Button, ButtonType } from "../styles/Button.styled";
import {
  CredentialResponse,
  GoogleLogin,
  useGoogleLogin,
} from "@react-oauth/google";
import { Field, Form } from "houseform";
import { HBox, ResponsiveVBox, VBox } from "../styles/Flexbox.styled";
import { LoginData, LoginProps } from "../services/IAuthService";
import {
  googleSignIn,
  rememberPassword,
  signIn,
} from "../services/AuthService";
import { resetAuth, setAuth } from "../redux/modules/auth";
import { useNavigate, useParams } from "react-router-dom";
import { validatePassword, validateUsername } from "../utils/ValidationHelper";

import Bar from "../components/Bar/Bar";
import { BarType } from "../components/Bar/IBar";
import Busy from "../components/Busy";
import Confirm from "../components/modals/Confirm/Confirm";
import ConfirmModalTypeEnum from "../models/enums/ConfirmModalTypeEnum";
import EmailRemember from "../components/modals/EmailRemember/EmailRemember";
import LoginActionEnum from "../models/enums/LoginActionEnum";
import Logo from "../components/Logo/Logo";
import RegionDetector from "../locales/RegionDetector";
import Text from "../components/Text/Text";
import TextInput from "../components/TextInput";
import TextSizeEnum from "../models/enums/TextSizeEnum";
import TextTypeEnum from "../models/enums/TextTypeEnum";
import TextWeightEnum from "../models/enums/TextWeightEnum";
import { acceptPlanner } from "../services/UserService";
import { breakpoints } from "../styles/Devices";
import { getText } from "../locales/initI18n";
import { setError } from "../redux/modules/error";
import { useAppDispatch } from "../redux/hooks";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useTheme } from "styled-components";

const Home = () => {
  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [isRemindPasswordVisible, setIsRemindPasswordVisible] = useState(false);
  const [isAcceptPlanner, setIsAcceptPlanner] = useState(false);
  const [busy, setBusy] = useState(false);

  const width = window.innerWidth;

  const SignIn = useMutation({
    mutationFn: signIn,
    onSuccess: async ({ data }, variables) => {
      login(data);
    },
    onError: (error) => {
      dispatch(setError(error));
      setBusy(false);
    },
  });

  const GoogleSignIn = useMutation({
    mutationFn: googleSignIn,
    onSuccess: async ({ data }, variables) => {
      setTimeout(() => login(data), 2000);
    },
    onError: (error) => {
      dispatch(setError(error));
      setBusy(false);
    },
  });

  const login = async (data: LoginData) => {
    dispatch(setAuth({ token: data.access_token }));
    if (params.action === LoginActionEnum.plannerAccept) {
      if (params.token) await acceptPlanner(params.token);
      dispatch(resetAuth());
      setIsAcceptPlanner(true);
      return;
    } else navigate("/dashboard");
    setBusy(false);
  };

  const onConfirmPlanner = () => {
    setIsAcceptPlanner(false);
    navigate("/dashboard");
  };

  const onLogin = async (variables: LoginProps) => {
    setBusy(true);
    variables.username = variables.username.trim();
    SignIn.mutate(variables);
  };

  const onGoogleLogin = async (variables: CredentialResponse) => {
    setBusy(true);
    if (variables.credential) {
      const data = {
        token: variables.credential,
        country: RegionDetector.GetUserCountry().code,
      };
      GoogleSignIn.mutate(data);
    }
  };

  const remindPassword = async (data: { email: string }) => {
    try {
      await rememberPassword(data.email);
      setIsRemindPasswordVisible(false);
    } catch (e) {
      setIsRemindPasswordVisible(false);
      dispatch(setError(e));
    }
  };

  return (
    <VBox
      width="100%"
      alignItems="center"
      justifyContent="center"
      backgroundColor={theme.palette.White}
      overflowY="scroll"
      overflowX="hidden"
      Padding="20px 0"
    >
      <Busy visible={busy} />
      <EmailRemember
        isVisible={isRemindPasswordVisible}
        onConfirm={remindPassword}
        onClose={() => setIsRemindPasswordVisible(false)}
      />
      <Confirm
        isVisible={isAcceptPlanner}
        onClose={onConfirmPlanner}
        isCancel={false}
        onConfirm={onConfirmPlanner}
        type={ConfirmModalTypeEnum.Change}
        title={getText("Home.View.PlannerConfirmTitle")}
        description={getText("Home.View.PlannerConfirmDescription")}
      />
      <Logo />
      <ResponsiveVBox
        backgroundColor={theme.palette.White}
        shadow="small"
        RMinWidthSM="400px"
        minWidth="100%"
        RIsShadowSM={false}
        BorderRadius="20px"
        alignItems="center"
        Padding="20px"
        gap="10px"
        maxWidth="480px"
        Margin="0 5px"
      >
        <Text
          type={TextTypeEnum.h3}
          weight={TextWeightEnum.medium}
          size={TextSizeEnum.small}
          color={theme.palette.Grey80}
          textAlign="center"
        >
          {getText("Home.View.Title")}
        </Text>
        <Bar
          visible={width >= breakpoints.sm && !params.token}
          type={BarType.Critical}
        >
          {getText("Home.View.AppForDesktopIsNotAvailable")}
        </Bar>
        <Form onSubmit={(values: LoginProps) => onLogin(values)}>
          {({ submit, value }) => (
            <>
              <Field<string>
                name="username"
                onSubmitValidate={validateUsername()}
              >
                {({ value, setValue, onBlur, isValid, errors }) => (
                  <HBox width="100%" gap="10px">
                    <TextInput
                      width="400px"
                      isErrorState={!isValid}
                      value={value}
                      onBlur={onBlur}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setValue(e.target.value)
                      }
                      placeholder="Wpisz nazwę użytkownika lub email"
                      errors={errors}
                    >
                      {getText("Home.View.UsernameInput")}
                    </TextInput>
                  </HBox>
                )}
              </Field>
              <Field<string>
                name="password"
                onSubmitValidate={validatePassword()}
              >
                {({ value, setValue, onBlur, isValid, errors }) => (
                  <HBox width="100%">
                    <TextInput
                      type="password"
                      width="400px"
                      isErrorState={!isValid}
                      value={value}
                      onBlur={onBlur}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setValue(e.target.value)
                      }
                      placeholder="Wpisz swoje hasło"
                      errors={errors}
                    >
                      {getText("Password")}
                    </TextInput>
                  </HBox>
                )}
              </Field>
              <HBox gap="5px" width="100%">
                <Button
                  onClick={submit}
                  buttonType={ButtonType.Primary}
                  padding="0 20px"
                  width="100%"
                  disabled={width >= breakpoints.sm && !params.token}
                >
                  {getText("Login")}
                </Button>
                <HBox
                  visible={false}
                  justifyContent="center"
                  alignItems="center"
                  disabled={width >= breakpoints.sm && !params.token}
                >
                  {/* <GoogleLogin type="icon" onSuccess={onGoogleLogin} /> */}
                </HBox>
              </HBox>
              <Button
                onClick={() => setIsRemindPasswordVisible(true)}
                buttonType={ButtonType.Ghost}
                width="100%"
                disabled={width >= breakpoints.sm}
              >
                {getText("Home.View.RemindPassword")}
              </Button>
              <HBox
                width="100%"
                BorderRadius="20px"
                height="3px"
                backgroundColor={theme.palette.Grey10}
              />
              <Button
                buttonType={ButtonType.Ghost}
                width="100%"
                onClick={() => navigate("/register")}
                disabled={width >= breakpoints.sm}
              >
                {getText("Register")}
              </Button>
            </>
          )}
        </Form>
      </ResponsiveVBox>
    </VBox>
  );
};

export default Home;
