import { HBox, VBox } from "../styles/Flexbox.styled";

import { ButtonOpacity } from "../styles/ButtonOpacity.styled";
import Text from "./Text/Text";
import TextSizeEnum from "../models/enums/TextSizeEnum";
import TextTypeEnum from "../models/enums/TextTypeEnum";
import TextWeightEnum from "../models/enums/TextWeightEnum";
import { useTheme } from "styled-components";

interface ICheckbox {
  state: boolean;
  title: string;
  visible?: boolean;
  onClick?: () => void;
}

const Checkbox = (props: ICheckbox) => {
  const theme = useTheme();
  if (props.visible !== undefined ? !props.visible : false) return null;
  return (
    <ButtonOpacity width="auto" onClick={props.onClick}>
      <HBox
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        border={`1px solid ${theme.palette.Grey20}`}
        Padding="10px"
        BorderRadius="10px"
      >
        <Text
          color={theme.palette.Grey80}
          type={TextTypeEnum.body}
          weight={TextWeightEnum.medium}
          size={TextSizeEnum.small}
        >
          {props.title}
        </Text>
        <VBox
          backgroundColor={
            props.state ? theme.palette.Primary80 : theme.palette.Grey10
          }
          width="20px"
          height="20px"
          justifyContent="center"
          alignItems="center"
          BorderRadius="50px"
        >
          <VBox
            backgroundColor={
              props.state ? theme.palette.Primary40 : theme.palette.White
            }
            width="15px"
            height="15px"
            BorderRadius="50px"
          ></VBox>
        </VBox>
      </HBox>
    </ButtonOpacity>
  );
};

export default Checkbox;
