import { distance } from "./utils";

export default class Formatter {
  static UNITS = {
    Temperature: "°C",
    Pressure: "hPa",
    Humidity: "%",
    WindSpeed: "km/h",
    WindDirections: ["N", "NE", "E", "SE", "S", "SW", "W", "NW"],
    Distance: "km",
    Liquid: "mm",
    Visibility: "km",
  } as const;
  //#region publics
  public static Liquid(liquid?: number | null) {
    const unit = Formatter.UNITS.Liquid;
    if (liquid === undefined || liquid === null) return "-";
    return `${liquid.toFixed(3)}${unit}`;
  }
  public static WindSpeed(windSpeed?: number | null, kmh = true) {
    const unit = Formatter.UNITS.WindSpeed;
    if (windSpeed === undefined || windSpeed === null) return "-";
    if (!kmh) return `${Formatter.msToKmh(windSpeed).toFixed(2)}${unit}`;
    return `${windSpeed.toFixed(2)}${unit}`;
  }
  public static WindDirection(windDirection?: number | null) {
    return Formatter.degToCompass(windDirection);
  }
  public static WindSpeedAndDirection(
    windSpeed?: number | null,
    windDirection?: number | null
  ) {
    return `${Formatter.WindDirection(windDirection)}, ${Formatter.WindSpeed(
      windSpeed
    )}`;
  }
  public static Temperature(temperature?: number | null) {
    const unit = Formatter.UNITS.Temperature;
    return temperature ? `${temperature.toFixed(2)}${unit}` : "-";
  }
  public static Distance = (distance?: number | string | null) => {
    const unit = this.UNITS.Distance;
    if (!distance) return "-";
    return `${distance}${unit}`;
  };
  public static Pressure = (pressure?: number | null) => {
    const unit = this.UNITS.Pressure;
    if (!pressure) return "-";
    return `${pressure.toFixed(2)}${unit}`;
  };
  public static Humidity = (humidity?: number | null) => {
    const unit = this.UNITS.Humidity;
    if (!humidity) return "-";
    return `${humidity.toFixed(2)}${unit}`;
  };
  public static Visibility = (visibility?: number | null) => {
    const unit = this.UNITS.Visibility;
    if (!visibility) return "-";
    return `${visibility.toFixed(2)}${unit}`;
  };
  public static Date(date?: Date | string | null) {
    if (!date) return "-";
    return new Date(date).toLocaleDateString();
  }
  public static DateTime(date?: Date | string | null) {
    if (!date) return "-";
    return new Date(date).toLocaleString();
  }
  //#endregion
  //#region privates
  private static msToKmh(ms: number) {
    return ms * 3.6;
  }
  private static degToCompass(degrees: number | null | undefined) {
    if (!degrees) return "-";
    const directions = Formatter.UNITS.WindDirections;

    const directionIndex = Math.round((degrees % 360) / 45);

    return directions[directionIndex - 1] || "-";
  }
  //#endregion
}
