import { Button, ButtonType } from "../styles/Button.styled";
import { Field, Form } from "houseform";
import { HBox, ResponsiveVBox, VBox } from "../styles/Flexbox.styled";
import { useNavigate, useParams } from "react-router-dom";
import {
  validateConfirmPassword,
  validatePassword,
} from "../utils/ValidationHelper";

import Bar from "../components/Bar/Bar";
import { BarType } from "../components/Bar/IBar";
import Logo from "../components/Logo/Logo";
import { StyledText } from "../styles/Typography.styled";
import TextInput from "../components/TextInput";
import { TextInputState } from "../styles/ITextInput";
import { getText } from "../locales/initI18n";
import { resetPassword } from "../services/ResetPasswordService";
import { useState } from "react";
import { useTheme } from "styled-components";

const PasswordReset = () => {
  const { token } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();

  const [isConfirmed, setIsConfirmed] = useState<boolean | null>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const onResetPassword = async (password: string) => {
    setIsDisabled(true);
    const confirmation = token ? await resetPassword(token, password) : null;
    setIsConfirmed(confirmation);
    setIsDisabled(false);
  };

  return (
    <VBox
      width="100%"
      justifyContent="center"
      alignItems="center"
      backgroundColor={theme.palette.White}
      Padding="10px"
      overflowY="scroll"
    >
      <Logo />
      <ResponsiveVBox
        backgroundColor={theme.palette.White}
        shadow="small"
        RIsShadowSM={false}
        BorderRadius="20px"
        alignItems="center"
        Padding="20px"
        gap="20px"
        maxWidth="480px"
        Margin="0 5px"
      >
        <StyledText
          type="h3"
          weight="medium"
          size="small"
          color={theme.palette.Grey80}
          textAlign="center"
        >
          {getText("PasswordReset")}
        </StyledText>
        <Bar type={BarType.Info} visible={isDisabled}>
          {getText("PleaseWaitAMoment")}
        </Bar>
        <Bar type={BarType.Green} visible={!!isConfirmed}>
          {getText("SuccessResetPasswordText")}
        </Bar>
        <VBox visible={!isConfirmed} gap="10px">
          <Bar type={BarType.Info}>
            {getText("InformationAboutResetPassword")}
          </Bar>
          <Form
            onSubmit={(values: { password: string }) =>
              onResetPassword(values.password)
            }
          >
            {({ submit, value }) => (
              <>
                <Field<string>
                  name="password"
                  onSubmitValidate={validatePassword()}
                >
                  {({ value, setValue, onBlur, isValid, errors }) => (
                    <HBox width="100%" gap="10px">
                      <TextInput
                        type="password"
                        width="400px"
                        state={
                          !isValid
                            ? TextInputState.Error
                            : TextInputState.Default
                        }
                        value={value}
                        onBlur={onBlur}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setValue(e.target.value)
                        }
                        placeholder="********"
                        errors={errors}
                      >
                        {getText("Password")}
                      </TextInput>
                    </HBox>
                  )}
                </Field>
                <Field<string>
                  name="repeatPassword"
                  onSubmitValidate={validateConfirmPassword(value.password)}
                >
                  {({ value, setValue, onBlur, isValid, errors }) => (
                    <HBox width="100%" gap="10px">
                      <TextInput
                        type="password"
                        width="400px"
                        state={
                          !isValid
                            ? TextInputState.Error
                            : TextInputState.Default
                        }
                        value={value}
                        onBlur={onBlur}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setValue(e.target.value)
                        }
                        placeholder="********"
                        errors={errors}
                      >
                        {getText("PasswordRepeat")}
                      </TextInput>
                    </HBox>
                  )}
                </Field>
                <Button
                  onClick={submit}
                  buttonType={ButtonType.Primary}
                  padding="0 20px"
                  disabled={isDisabled}
                >
                  {getText("ChangePassword")}
                </Button>
                <Button
                  onClick={() => navigate("/")}
                  buttonType={ButtonType.Ghost}
                  padding="0 20px"
                  disabled={isDisabled}
                >
                  {getText("GoToStart")}
                </Button>
              </>
            )}
          </Form>
        </VBox>
      </ResponsiveVBox>
    </VBox>
  );
};

export default PasswordReset;
