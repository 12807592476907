import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import DeleteAccount from "./views/DeleteAccount";
import EmailConfirmation from "./views/EmailConfirmation";
import FarmersView from "./views/Farmers.view";
import Home from "./views/Home.view";
import News from "./views/News";
import PasswordReset from "./views/PasswordReset";
import PigeonDetail from "./views/PigeonDetails/PigeonDetail.view";
import PigeonMap from "./views/Map.view";
import PigeonView from "./views/Pigeons.view";
import PolicyPrivacy from "./views/PolicyPrivacy";
import ProfileView from "./views/Profile.view";
import Register from "./views/Register.view";
import { breakpoints } from "./styles/Devices";
import { resetAuth } from "./redux/modules/auth";
import { setError } from "./redux/modules/error";
import { useAppDispatch } from "./redux/hooks";
import { useEffect } from "react";
import useUser from "./hooks/useUser";
import withError from "./hoc/withError/withError";

const AppRoutes = () => {
  const protectedRoutes = ["dashboard"];
  const dispatch = useAppDispatch();
  const { data, isSuccess, error, isError } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const width = window.innerWidth;

  const isInProtectedRoute = protectedRoutes.some((route) => {
    return location.pathname.includes(route);
  });

  useEffect(() => {
    if (isError) {
      dispatch(setError(error));
      if (isInProtectedRoute) navigate("/");
    }
  }, [isError]);

  useEffect(() => {
    if (
      location.pathname.includes("login") &&
      location.pathname.split("/").length === 4
    ) {
      dispatch(resetAuth());
      return;
    }
    if (width >= breakpoints.sm && isInProtectedRoute) {
      dispatch(resetAuth());
      navigate("/");
      return;
    }
    if (data && !data?.newsIsReadedAt) {
      navigate("/news");
      return;
    }
    if (!isInProtectedRoute && data && data) navigate("/dashboard");
  }, [isSuccess, data]);

  return (
    <Routes>
      <Route path="dashboard">
        <Route path="" element={<Navigate to="map" />} />
        <Route path="map" element={<PigeonMap />} />
        <Route path={"farmers/:username"} element={<FarmersView />} />
        <Route path={"farmers"} element={<FarmersView />} />
        <Route path={"pigeons"} element={<PigeonView />} />
        <Route path={"pigeons/:id"} element={<PigeonDetail />} />
        <Route path={"profile"} element={<ProfileView />} />
      </Route>
      <Route path={"/"} element={<Home />} />
      <Route path={"/login/:action?/:token?"} element={<Home />} />
      <Route path={"/register"} element={<Register />} />
      <Route path="news" element={<News />} />
      <Route
        path={"/email-confirmation/:token"}
        element={<EmailConfirmation />}
      />
      <Route path={"/password-reset/:token"} element={<PasswordReset />} />
      <Route path={"delete-account/:token?"} element={<DeleteAccount />} />
      <Route path={"policy-privacy"} element={<PolicyPrivacy />} />
    </Routes>
  );
};

export default withError(AppRoutes);
