import { Button, ButtonType } from "../../../styles/Button.styled";
import { HBox, VBox } from "../../../styles/Flexbox.styled";
import { IoAddOutline, IoArrowUpOutline, IoSearch } from "react-icons/io5";
import {
  createTag,
  getCotes,
  getTags,
  removeTag,
  updateTag,
} from "../../../services/TagService";
import { useEffect, useRef, useState } from "react";
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import ActiveFilters from "../Filters/ActiveFilters";
import AddTagForm from "../AddTagForm/AddTagForm";
import { AxiosResponse } from "axios";
import ChildFilter from "../../../models/Filter/ChildFilter";
import { CloseIconEnum } from "../../HalfModal/CloseIconEnum";
import { FilterName } from "../../../models/enums/FilterName";
import Filters from "../Filters/Filters";
import HalfModal from "../../HalfModal/HalfModal";
import HighligtedText from "../../HighlightedText/HighlightedText";
import { IBaseFilter } from "../../../models/Filter/IBaseFilter";
import { ITag } from "../../../models/Tag";
import { IUser } from "../../../services/IUserService";
import InfiniteScroll from "react-infinite-scroll-component";
import ListItem from "../../ListItem/ListItem";
import { ListItemAction } from "../../ListItem/IListItem";
import Loader from "../../Loader/loader";
import PageOptions from "../../../models/PageOptions";
import ParentFilter from "../../../models/Filter/ParentFilter";
import { ParentFilterOperator } from "../../../models/Filter/ParentFilterOperator";
import { ReleaseListProps } from "./IReleaseList";
import { TagType } from "../../../models/enums/TagType";
import Text from "../../Text/Text";
import { getText } from "../../../locales/initI18n";
import { setError } from "../../../redux/modules/error";
import { useAppDispatch } from "../../../redux/hooks";
import useCotes from "../../../hooks/useCotes";
import { useTheme } from "styled-components";
import { useUpdateNodeInternals } from "reactflow";
import useUser from "../../../hooks/useUser";
import Busy from "../../Busy";

const ReleaseList: React.FC<ReleaseListProps> = ({
  isVisible,
  isCote,
  isSelect,
  isEditPossible = true,
  closeIcon,
  onClose,
  onConfirm,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [isFiltersModal, setIsFiltersModal] = useState(false);
  const [filters, setFilters] = useState<IBaseFilter[]>([]);
  const [rawFilters, setRawFilters] = useState<string>("");
  const titleRef = useRef<HTMLDivElement>(null);
  const [isAddTagFormVisible, setIsAddTagFormVisible] =
    useState<boolean>(false);
  const [pressedTag, setPressedTag] = useState<ITag | null>(null);
  const { data: user } = useUser();

  const getReleaseCotes = async (options: PageOptions) => {
    if (isCote) return await getCotes(options, rawFilters);
    else return await getTags(options, rawFilters);
  };
  const queryClient = useQueryClient();
  const { refetch: refetchCotes } = useCotes();
  const { data, isLoading, refetch, fetchNextPage } = useInfiniteQuery({
    queryKey: [`${isCote ? "cotes" : "tags"}`, { filters: rawFilters }],
    queryFn: ({ pageParam = new PageOptions() }) => getReleaseCotes(pageParam),
    enabled: isVisible && rawFilters !== "",
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.meta.isNext) {
        return new PageOptions(lastPage.meta.page + 1);
      }
    },
  });

  const addRelease = async (data: ITag) => {
    data.isCote = isCote;
    closeAddTagForm();
    try {
      !data.id ? await createTag(data) : await updateTag(data);
      refetch();
      if (isCote) refetchCotes();
    } catch (e) {
      onClose();
      dispatch(setError(e));
    }
  };

  const deleteTag = async (tagId: number) => {
    try {
      closeAddTagForm();
      await removeTag(tagId);
      queryClient.setQueryData(
        [`${isCote ? "cotes" : "tags"}`, { filters: "" }],
        (old: AxiosResponse<ITag[], any> | undefined) => {
          if (!old) return;
          const newData = old.data.filter((tag) => tag.id !== tagId);
          return { ...old, data: newData };
        }
      );
    } catch (e) {
      onClose();
      dispatch(setError(e));
    }
  };

  const closeAddTagForm = () => {
    setPressedTag(null);
    setIsAddTagFormVisible(false);
  };

  const onTagPress = (tag: ITag) => {
    setPressedTag(tag);
    setIsAddTagFormVisible(true);
  };

  const onAddTagPress = () => {
    setPressedTag(null);
    setIsAddTagFormVisible(true);
  };

  const onSelectOnAdding = (tag: ITag) => {
    setIsAddTagFormVisible(false);
    onConfirm?.(tag);
  };

  const onScrollToTop = () => {
    titleRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const getStaticFilters = (filters: IBaseFilter[]) => {
    return new ParentFilter([
      new ParentFilter(
        [
          new ChildFilter(FilterName.UserIdNull),
          new ChildFilter(FilterName.IsCote, isCote),
          ...filters,
        ],
        ParentFilterOperator.And
      ),
      new ParentFilter(
        [
          new ChildFilter(FilterName.UserId, user?.id),
          new ChildFilter(FilterName.IsCote, isCote),
          ...filters,
        ],
        ParentFilterOperator.And
      ),
    ]);
  };

  const onSetFilters = (filters: IBaseFilter[]) => {
    setIsFiltersModal(false);

    const parent = getStaticFilters(filters);
    setRawFilters(parent.transform());
    setFilters(filters);
  };

  useEffect(() => {
    if (isVisible) {
      onSetFilters([]);
    }
  }, [isVisible]);

  const tags = data?.pages.flatMap((page) => page.data);
  const lastMeta = data?.pages[data.pages.length - 1].meta;

  if (!tags) return <Busy visible={true} />;

  return (
    <>
      <HalfModal
        visible={isVisible}
        onClose={onClose}
        heightDivider={1.1}
        busy={isLoading}
        Padding="0"
        isExpander={false}
        closeIcon={closeIcon}
      >
        <Filters
          isVisible={isFiltersModal}
          onClose={() => setIsFiltersModal(false)}
          onConfirm={onSetFilters}
          title={
            isCote
              ? getText("ReleaseList.SearchCotes")
              : getText("ReleaseList.SearchReleases")
          }
          filters={[
            new ChildFilter(FilterName.Name, "", filters),
            new ChildFilter(FilterName.Country, "", filters),
            new ChildFilter(FilterName.City, "", filters),
            new ChildFilter(FilterName.CreatedByYou, false, filters),
            new ChildFilter(FilterName.Predefined, false, filters),
          ]}
        />
        <AddTagForm
          title={
            isCote
              ? getText("ReleaseList.AddFlightForm.TitleCote")
              : getText("ReleaseList.AddFlightForm.TitleRelease")
          }
          isCote={isCote}
          isSelect={!!pressedTag && isSelect}
          tag={pressedTag}
          onSelect={onSelectOnAdding}
          isVisible={isAddTagFormVisible}
          onClose={() => setIsAddTagFormVisible(false)}
          onConfirm={addRelease}
          onDelete={deleteTag}
          closeIcon={CloseIconEnum.RETURN}
        />
        <VBox
          position="relative"
          id="ReleaseList__container"
          overflowY="scroll"
        >
          <VBox Margin="40px 20px 0 20px" ref={titleRef}>
            <Text type="h4" size="large" weight="bold">
              {isCote
                ? getText("ReleaseList.TitleCotes")
                : getText("ReleaseList.TitleReleases")}
            </Text>
          </VBox>
          <VBox Padding="20px 0 0 0">
            <ListItem
              title={
                isCote
                  ? getText("ReleaseList.AddCote")
                  : getText("ReleaseList.AddRelease")
              }
              description={
                isCote
                  ? getText("ReleaseList.AddCoteDescription")
                  : getText("ReleaseList.AddReleaseDescription")
              }
              onClick={onAddTagPress}
              action={ListItemAction.Primary}
              rIcon={<IoAddOutline color={theme.palette.White} />}
            />
            <ListItem
              darkest
              title={getText("ReleaseList.inputSearchTitle")}
              description={
                isCote
                  ? getText("ReleaseList.CoteSearchDescription")
                  : getText("ReleaseList.ReleaseSearchDescription")
              }
              onClick={() => setIsFiltersModal(true)}
              action={ListItemAction.Secondary}
              rIcon={<IoSearch size="12px" color={theme.palette.Grey80} />}
            >
              <ActiveFilters filters={filters} />
            </ListItem>
            <Text Margin="0 0 0 20px" type="h5" size="large" weight="medium">
              {isCote
                ? getText("ReleaseList.CoteList")
                : getText("ReleaseList.ReleaseList")}
            </Text>
            <InfiniteScroll
              dataLength={tags?.length || 0}
              hasMore={lastMeta?.isNext || false}
              next={fetchNextPage}
              scrollThreshold={0.95}
              loader={
                <HBox justifyContent="center">
                  <Loader />
                </HBox>
              }
              scrollableTarget="ReleaseList__container"
            >
              {tags.map((tag, index) => {
                return (
                  <ListItem
                    key={tag.id}
                    title={tag.name}
                    description={tag.latitude + ", " + tag.longitude}
                    rDescription={tag.country + ", " + tag.city}
                    darkest={index % 2 === 0}
                    onClick={() =>
                      isEditPossible ? onTagPress(tag) : onSelectOnAdding(tag)
                    }
                  >
                    <HighligtedText
                      visible={tag.type === TagType.Private}
                      text={getText("ReleaseList.CreatedByYou")}
                      backgroundColor={theme.palette.Green60}
                    />
                    <HighligtedText
                      visible={tag.type === TagType.Predefined}
                      text={getText("ReleaseList.CreatedBySystem")}
                      backgroundColor={theme.palette.Grey30}
                    />
                  </ListItem>
                );
              })}
            </InfiniteScroll>
            <VBox position="fixed" Bottom="20px" Right="20px">
              <Button
                width="50px"
                height="50px"
                buttonType={ButtonType.Ghost}
                onClick={onScrollToTop}
                isCustom={true}
              >
                <IoArrowUpOutline size={24} color={theme.palette.Grey90} />
              </Button>
            </VBox>
          </VBox>
        </VBox>
      </HalfModal>
    </>
  );
};

export default ReleaseList;
