export enum FilterName {
  Id = "id",
  NotId = "notId",
  Name = "name",
  OpenFlights = "openFlights",
  PrivateFlights = "privateFlights",
  SharedFlights = "sharedFlights",
  Type = "type",
  ParentFlightId = "parentFlightId",
  StartDate = "startDate",
  EndDate = "endDate",
  City = "city",
  Country = "country",
  CreatedByYou = "createdByYou",
  Predefined = "predefined",
  CreatedUserId = "createdUserId",
  OwnerId = "ownerId",
  Ring = "ring",
  Sex = "sex",
  PigeonId = "pigeonId",
  UseInPedigree = "useInPedigree",
  MotherId = "motherId",
  FatherId = "fatherId",
  MotherIdNull = "motherIdNull",
  FatherIdNull = "fatherIdNull",
  UserIdNull = "userIdNull",
  DistrictId = "districtId",
  UserId = "userId",
  IsCote = "isCote",
  Username = "username",
}
