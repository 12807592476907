import { Caption } from "./Typography.styled";
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  body {
    margin: 0;
    background-color: #F8F8F8;
    height: 100svh;
    font-family: Roboto;
  }
  #root {
    width: 100%;
    height: 100%;
  }
  :focus-visible {
    outline-color: #e7b99b;
  }
  .active-marker::before {
    content: "";
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    opacity: 0.3;
    background-color: #e7b99b;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .pigeonmap-map-popup-container > .maplibregl-popup-content {
    padding: 0;
  }
  .pigeonmap-map-popup-container > .maplibregl-popup-tip {
    display: none;
  }
  .pigeonmap-map-popup-text {
    ${Caption("medium", "small")};
    margin: 0;
    padding: 2px;
  }
`;
